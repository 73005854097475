<template>
     <div class="row pt-4">
            <h6 class="looktitle sta text-black">A quick look back on your journey so far :</h6>
            <div class="col-md-4 py-2 col-sm-6" v-for="(item, index) in data" :key="index">
                <lookbackCard :item="item" />
            </div>
                                    
        </div>
</template>
<script>
  import lookbackCard from "@/components/Authenticated/Home/LookbackCard.vue"
  import AOS from 'aos'
  export default {
    components: {
      lookbackCard
    },
    props: {
    data: {
      required: true,
    },
  },
    mounted() {
      AOS.init()  
    },
  };
  </script>
  <style scoped src="@/assets/css/application.css" ></style>