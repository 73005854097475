import { createApp } from 'vue'
import 'vue-croppa/dist/vue-croppa.css'
import App from './App.vue'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import 'aos/dist/aos.css'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css'
import store from './store'
import Croppa from 'vue-croppa'

createApp(App)
.use(router)
.use(Croppa)
.use(VueSweetalert2)
.use(store)
.mount('#app')
