<template>
    <body data-aos-easing="slide" data-aos-duration="800" data-aos-delay="0" class="responsivewhite">
          <div class="d-flex flex-wrap">
              <SideNav :active="active" :show="show" />
              <div class="col-lg-9 col-xl-10 col-12 p-0">
                  <div class="d-flex flex-wrap ">
                      <MainNav  :active="active"  :show="show"  @nav-show="sidenav" />
                      <div class="col-lg-9 col-xl-10  col-12 p-0">
                        <main class="p-0 p-lg-3">
                            <div class="mainbody pt-5 mt-4 mt-lg-0 pt-lg-0">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="joined-tab" data-bs-toggle="tab" data-bs-target="#joined" 
                                        type="button" role="tab" aria-controls="joined" aria-selected="true">Quick Search</button>
                                    </li>
                                </ul>
                                <div class="p-2">
                                    <div class="tab-content" id="myTabContent">
                                        <!-- Just Joined Table -->
                                        <div class="tab-pane fade show active" id="joined" role="tabpanel"
                                            aria-labelledby="joined-tab">
                                  
                                            <Spinner v-if="loading"  />

                                            <div v-else class="row table">
                                                <div  class="col-12 col-xl-4 col-lg-6 col-md-6 tr  p-1" v-for="(item) in QuickSearchData"   >
                                                      <Card :item="item" :quick="true"/>
                                                </div>
                                            </div>
                                            <button v-if="QuickSearch?.next_page_url != null && !loading" class="btn btn-primary mt-3 showmore mb-4 mb-lg-0"
                                               @click="More()" >Show More</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                    <div class="col-lg-3 col-xl-2  d-none d-lg-block p-0">
                      <!-- Quick Search -->
                       <QuickSearch :martial_status="martial_status" :religion="religion" :onpage="onpage" @submit="submit"/>
                       <!-- Happy Couples -->
                       <HappyCouple  :successStory="successStory" />
                    </div>
                      
                  </div>
              </div>
          </div>
          <MobileNav :active="active"/>
    
  </body>
</template>
  <script>
    import AOS from 'aos'
    import { Icon } from "@iconify/vue";
    import MainNav from "@/components/Authenticated/UserMainNav.vue";
    import SideNav from "@/components/Authenticated/UserSideNav.vue";
    import MobileNav from  "@/components/Authenticated/MobileNav.vue"
    import Card from "@/components/Authenticated/UserCard.vue"
    import QuickSearch from "@/components/Authenticated/Matches/QuickSearch.vue"
    import HappyCouple from "@/components/Authenticated/Matches/HappyCouples.vue"
    import { martial_status,religion } from "@/util/Services/home";
    import { QuickSearchPag, successStory } from "@/util/Services/Authenticated/matches"
    import Spinner from "@/components/Authenticated/Spinner.vue"
    import { ref } from 'vue';
    export default {
      name: "AcceptedView",
      components: {
        MainNav,
        SideNav,
        Icon,
        MobileNav,
        Card,
        QuickSearch,
        HappyCouple,
        Spinner
      },
      setup() {
          
          const active              = "QuickSearch";
          const show                = ref(false);
          const martial_status      = ref([]);
          const religion            = ref([]);
          const QuickSearch         = ref([]);
          const QuickSearchData     = ref([]);
          const page                = ref(1);
          const onpage              = ref(false);
          const loading             = ref(false);
         
          return {
            active,show,martial_status,religion,QuickSearch,QuickSearchData,page,onpage,loading
          }
      },
      
      methods: {
        sidenav() {
          this.show = !this.show;
        },
        async Martial_Status(){
          await martial_status().then(r=>{this.martial_status = r?.data?.data});
        },
        async Religion(){
          await religion().then(r=>{this.religion = r?.data?.data});
        },
        async successStoryAPI(){
           await successStory().then(r=>{this.successStory = r?.data?.data});
        },
        async QuickSearchAPI(){
          if (this.page == 1) this.loading = true;
            
            if (this.onpage == true) {
                this.onpage = false;
                this.QuickSearchData = [];
            }
            await QuickSearchPag(this.page)
                  .then(r=>{this.QuickSearch = r?.data?.data,r?.data?.data?.data ? this.QuickSearchData.push(...r?.data?.data?.data): []})
                 
            if (this.page == 1) this.spinner();
            
        },
        async More(){
            this.page++;
            this.QuickSearchAPI();
        },
        async submit(){
            this.page = 1;
            this.onpage = true;
            this.QuickSearchAPI();
        },
        spinner(){
              var overlayLoader = document.querySelector('.spinner-border');
              if (overlayLoader) setTimeout(() => overlayLoader.classList.add('fade-out'), 5);
              setTimeout(() =>  this.loading = false, 10);
        },
      },
      mounted() {
        AOS.init()  
        this.successStoryAPI();
        this.Martial_Status();
        this.Religion();
        this.QuickSearchAPI();
      },
    };
  </script>
  <style scoped src="@/assets/css/application.css" />
  <style scoped src="@/assets/css/components/Authenticated/tab_card.css"/>
  <style scoped src="@/assets/css/components/Authenticated/spinner.css"/>
  <style scoped>
  .card {
    min-height: 10rem;
  }
  img {
    height: 132px !important;
    width: 112px !important
  }</style>
 
   
    