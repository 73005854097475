<template>
    <div class="ps-1 ps-lg-0 p-lg-3">
                            <div class="extrabar p-3">
                                <h5>Quick search</h5>
                                <div class="form-search-wrap p-3 pt-0 aos-init aos-animate" data-aos="fade-up"
                                    data-aos-delay="200">
                                    <form @submit.prevent="submitForm">
                                        <div class="d-grid gap-lg-2 justify-lg-content-evenly align-items-center text-start">
                                            <div class="w-auto">
                                                <label for="I’m looking for">I’m looking for</label>
                                                <div class="select-wrap">
                                                    <Icon class="icon" icon="iconamoon:arrow-down-2-light"/>
                                                    <select disabled v-model="form.gender"  class="form-control " >
                                                        <option value="Female">Female</option>
                                                        <option value="Male">Male</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="w-auto">
                                                <label for="Martial Status">Martial Status</label>
                                                <div class="select-wrap">
                                                    <Icon class="icon" icon="iconamoon:arrow-down-2-light"/>
                                                    <select  v-model="form.martial_status" class="form-control" >
                                                        <option value="">Select any</option>
                                                        <option v-for="item in martial_status" :value="item?.title">{{ item?.title }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="w-auto">
                                                <label for="I’m looking for">Religion</label>

                                                <div class="select-wrap">
                                                    <Icon class="icon" icon="iconamoon:arrow-down-2-light"/>
                                                    <select v-model="form.religion" @change="Casts" class="form-control " name="" id="">
                                                        <option value="">Select Religion</option>
                                                        <option v-for="item in religion" :value="item?.title">{{ item?.title }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="w-auto">
                                                <label for="Caste">Caste</label>

                                                <div class="select-wrap">
                                                    <Icon class="icon" icon="iconamoon:arrow-down-2-light"/>

                                                    <select v-model="form.cast"class="form-control" name="" id="">
                                                        <option value="">Select Caste</option>
                                                        <option v-for="item in cast" :value="item?.title">{{ item?.title }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="w-auto mt-2">
                                                <input type="submit" class="btn btn-primary btn-block rounded"
                                                    value="Search">
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
</template>
<script>
   
    import { Icon } from "@iconify/vue";
    import { cast } from "@/util/Services/home";
    export default {
      name: "AcceptedView",
      components: {
        Icon,
      },
      props:{
        martial_status:{},
        religion:{},
        onpage:{}
      },
      data(){
        return{
            cast:[],
            gender:'',
            form:{
                 gender:this.$store.getters.gender == "Male" ? "Female":"Male",
                 martial_status:this.$store.getters.quick_martial_status,
                 religion:this.$store.getters.quick_religion,
                 cast:this.$store.getters.quick_cast,
            }
        }
      },
      methods:{
       async Casts(){
             this.form.cast = '';
            await cast(this.form?.religion).then(r=>{this.cast = r?.data?.data})
        },
        submitForm(){
            this.$store.dispatch('SET_QUICK_FORM',this.form );
            if (this.onpage != true) {
                this.$router.push('/user/quick-search/')
            }else{
                this.$emit('submit',true);
            }
          },
      },
      mounted(){
        // console.log(this.$store.state);
        if (this.$store.getters.quick_religion) {
            this.Casts();
        }

      }
    };
    </script>
     <style scoped src="@/assets/css/application.css" />
     <style scoped src="@/assets/css/components/Authenticated/match-QuickRegister.css" />