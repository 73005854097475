<template>
    <!-- Navigation bar   -->
    <Nav :navactive="navactive"  />
    <!-- Main -->
    <main class="site-wrap">
        <Cover />
        <Service />
        <Benefits />
    </main>
    <Footer />
  </template>
  <script>
  // @ is an alias to /src
  import Nav from "@/components/General/Navbar.vue";
  import Cover from "@/components/Service/Cover.vue";
  import Service from "@/components/Service/PaidService.vue";
  import Benefits from "@/components/Service/PaidBenefits.vue";
  import Footer from "@/components/General/Footer.vue";
  import Login from "@/components/General/Login.vue";
  export default {
    name: "Free Membership",
    components: {
      Nav,
      Cover,
      Footer,
      Login,
      Service,
      Benefits
    },
    data() {
      return {
        navactive:"paid membership",
        
      };
    },
    
  };
  </script>
  <style>
  @media (min-width: 1600px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
      max-width: 1520px;
    }
  }
  </style>
  