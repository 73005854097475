<template>
     <expansion-panel>
        <label for="panel8" class="expansion-panel-header"><h6 class="fs-title">Family Details</h6></label>
            <div class="expansion-panel-content">
                <fieldset>
                <form @submit.prevent="submit">
                    <div class="form-card">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="fieldlabels">Father's Name:</label>
                                <input v-model="form.father_name" type="text" name="name" placeholder="Full Name" />
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">Father's Occupation:</label>
                                <input v-model="form.father_occupation" type="text" name="Occupation" placeholder="Occupation" />
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">Mother's Name:</label>
                                <input v-model="form.mother_name" type="text" name="name" placeholder="Full Name" />
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">Mother's Occupation:</label>
                                <input v-model="form.mother_occupation" type="text" name="Occupation" placeholder="Occupation" />
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">Brother:</label>
                                <input v-model="form.no_brother" type="number" name="number" placeholder="eg:1, 2" />
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">No Brother's Married:</label>
                                <input v-model="form.married_no_brother" type="number" name="number" placeholder="eg:1, 2" />
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">Sister:</label>
                                <input v-model="form.no_sisters" type="number" name="number" placeholder="eg:1, 2" />
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">No Sister's Married:</label>
                                <input v-model="form.married_no_sisters" type="number" name="number" placeholder="eg:1, 2" />
                            </div>
                        </div>
                        <div class="col-md-12">
                            <label class="fieldlabels">About Family:</label>
                            <textarea v-model="form.about" name="Detail" form="usrform">Enter text here...</textarea>
                        </div>
                    </div>
                    <input type="submit" name="next" class="next action-button" value="Save" />
                </form>                    
                </fieldset>
            </div>
        </expansion-panel>
        <ErrorToast :key="error"  :message="error" @update="errorup" />
        <SuccesToast :key="success"  :message="success" @update="errorup" />
</template>
<script>
import ErrorToast from "@/components/General/ErrorToast.vue"
import SuccesToast from "@/components/General/SuccessToast.vue"
import { Panel3 } from "@/util/Services/Authenticated/edit";
export default{
    components:{
        ErrorToast,
        SuccesToast
    },
    data(){
        return{
           
            form:{
                father_name        : this.data?.family?.father_name,
                father_occupation  : this.data?.family?.father_occupation,
                mother_name        : this.data?.family?.mother_name,
                mother_occupation  : this.data?.family?.mother_occupation,
                no_sisters         : this.data?.family?.no_sisters,
                married_no_sisters : this.data?.family?.married_no_sisters,
                no_brother         : this.data?.family?.no_brother,
                married_no_brother : this.data?.family?.married_no_brother,
                about              : this.data?.family?.about,
            },
            error   : null,
            success : null,
        }
    },
    props:{
      data:{required:true}
    },
    methods:{
        submit(){
          
            
                Panel3(this.form).then(r=>{this.error = r?.response?.data?.message,this.success = r?.data?.message})
                // console.log(this.form,'validated');
                setTimeout(() => { this.$emit('submit',true);}, 3000)
            
        }, 
        errorup(){
            this.error =null;
            this.success = null;
        },
    },
}
</script>
<style scoped src="@/assets/css/application.css" />
<style scoped src="@/assets/css/components/Authenticated/edit.css"></style>
