<template>

            <div class="lookbackcard sta">
                <Icon class="lookbackcard-icon" :icon="item?.icon"/>
                <h2>{{ item?.number }}</h2>
                <div class="d-grid">
                    <small class="text-black">{{ item?.head }}</small>
                    <p>{{ item?.p }}</p>
                </div>
            </div>
    
</template>
<script>
  import AOS from 'aos'
  import { Icon } from "@iconify/vue";
  export default {
    components: {
      Icon,
    },
    props: {
    item: {
      required: true,
    },
  },
    mounted() {
      AOS.init()  
    },
  };
  </script>
  <style scoped src="@/assets/css/application.css" ></style>