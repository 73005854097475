<template>
   <Nav />
   <main class="site-wrap bg-grey pt-5">
    <div class="container">
      <div class="row">
        <div class="registerpage d-flex justify-content-center pt-2 pt-md-5 mt-3">

          <div class="col-11 col-sm-10 col-md-10 col-lg-5 col-xl-5 text-center p-5 mt-3 mb-2">
            <div class="card col-12 p-3 p-lg-5 mb-4">
              <!-- <h2 id="heading">Register for free on Goodlife Matrimony</h2> -->
              <form id="msform">
                <!-- progressbar -->
                
                    <FieldSet1 @form-data="form1"  :loading="loading1"  v-if="current == 1" />
                    <FieldSet2  :loading="loading2" @form-data="form2"  v-if="current == 2" />
                    <FieldSet3  @form-data="form3" :loading="loading3" v-if="current == 3" />
                    <FieldSet4  @message-updated="login" v-if="current == 4" />
                
                
              </form>
            </div>
          </div>

        </div>
      </div>
    </div>

  </main> 
  <Footer />
  <ErrorToast :key="error"  :message="error" @update="errorup" />
  <SuccesToast :key="success"  :message="success" @update="errorup" />
  <Login :key="show" :show="show" @message-updated="login" />
</template>
<script>
import Nav from '@/components/Register/Navbar.vue'
import Footer from "@/components/General/Footer.vue";
import FieldSet1 from '@/components/Forgot/FieldSet1.vue';
import FieldSet2 from '@/components/Forgot/FieldSet2.vue';
import FieldSet3 from '@/components/Forgot/FieldSet3.vue';
import FieldSet4 from '@/components/Forgot/FieldSet4.vue';
import ErrorToast from "@/components/General/ErrorToast.vue"
import SuccesToast from "@/components/General/SuccessToast.vue"
import { forgotPassword,OtpCheck,UpadtePassword } from '@/util/Services/forgot';
import Login from "@/components/General/Login.vue";
export default{
    name: "RegisterView",
    components: {
        Nav,
        Footer,
        FieldSet1,
        FieldSet2,
        FieldSet3,
        FieldSet4,
        ErrorToast,
        SuccesToast,
        Login,
    },
    data() {
        return {
            current:1,
            steps:4,
            error:null,
            success:null,
            failed:false,
            data:[],
            formData:null,
            formData1:null,
            loading3:false,
            loading2:false,
            loading1:false,
            show:false
        }
    },
    methods:{
   
      async form1(form){
        this.loading1  =true;
        if ( this.current == 1) {
          await forgotPassword(form).then(r=>{this.error = r?.response?.data?.message,this.success = r?.data?.message,r?.data?.message ?  this.current = 2 : console.log({r},'mail')});
          this.formData = form;
          this.loading1  =false;
        }
      },
      async form2(form){
        this.loading2  =true;
        if (this.current == 2 && form?.numbers) {
        await OtpCheck(this.formData?.emails,form?.numbers).then(r=>{this.formData1=r?.data?.data,this.error = r?.response?.data?.message,this.success = r?.data?.message,r?.data?.message ?  this.current = 3 : console.log({r},"top")});
        }
        this.loading2  =false;
      },
      async form3(form){
        this.loading3  =true;
        UpadtePassword(this.formData1?.id,form?.password).then(r=>{this.error = r?.response?.data?.message,this.success = r?.data?.message,r?.data?.message ?  this.current = 4 : console.log({r},"top")});
        this.loading3  =false;
      },
      errorup(){
        this.error =null;
        this.success = null;
      },
      login(login) {
       // console.log('login');
       this.show = login;
     },
    },
}
</script>
<style>
 h2{
    font-family: "Lobster Two", sans-serif;
    color: #251718;
}
</style>