<template>
  <!-- Navigation bar  -->
  <Nav :navactive="navactive"  />
  <!-- Main  -->
  <Help/>
  <Footer />

</template>
<script>
// @ is an alias to /src
import Nav from "@/components/General/Navbar.vue";
import Help from "@/components/Help/Help.vue";
import Footer from "@/components/General/Footer.vue";
export default {
  name: "HomeView",
  components: {
    Nav,
    Help,
    Footer,
  },
  data() {
    return {
      navactive:"help",
      show: false,
    };
  },
 
};
</script>
<style>
@media (min-width: 1600px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1520px;
  }
}
</style>
