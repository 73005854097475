<template>
      <body data-aos-easing="slide" data-aos-duration="800" data-aos-delay="0" class="responsivewhite">
          <div class="d-flex flex-wrap">
              <SideNav :active="active" :show="show" />
  
              <div class="col-lg-9 col-xl-10 col-12 p-0">
                  <div class="d-flex flex-wrap ">
                      <MainNav  :active="active"  :show="show"  @nav-show="sidenav" />
  
                      <div class="col-lg-12 col-xl-12  col-12 p-0">
                        <main class="p-0 p-lg-3">
                            <div class="mainbody pt-5 mt-4 mt-lg-0 pt-lg-0">
                              <Inbox_list />
                              </div>
                         </main>     
                      </div>
                     
                       </div>

                    </div>
                    </div>
          <MobileNav :active="active"/>
  </body>
</template>
<script>
import Inbox_list from '@/components/Authenticated/Inbox/Inbox_list.vue';
import MainNav from "@/components/Authenticated/UserMainNav.vue";
import SideNav from "@/components/Authenticated/UserSideNav.vue";
import MobileNav from  "@/components/Authenticated/MobileNav.vue"
export default {
  components: {
    Inbox_list,
    MainNav,
    SideNav,
    MobileNav,
   },
   data() {
        return {
          active:"Inbox",
          show: false,
        };
      },
      methods: {
        sidenav() {
          this.show = !this.show;
        },
    }
}
</script>
<style scoped src="@/assets/css/application.css" />