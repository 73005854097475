<template>
    <body data-aos-easing="slide" data-aos-duration="800" data-aos-delay="0" class="responsivewhite">
          <div class="d-flex flex-wrap">
              <SideNav :active="active" :show="show" />
  
              <div class="col-lg-9 col-xl-10 col-12 p-0">
                  <div class="d-flex flex-wrap ">
                      <MainNav  :active="active"  :show="show"  @nav-show="sidenav" />
  
                      <div class="col-lg-12 col-xl-12  col-12 p-0">
                        <main class="p-0 p-lg-3">
                            <div class="mainbody pt-5 mt-4 mt-lg-0 pt-lg-0">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button @click="InterestedAtTab" class="nav-link active" id="interested_at-tab" data-bs-toggle="tab"
                                            data-bs-target="#interested_at" type="button" role="tab" aria-controls="interested_at"
                                            aria-selected="true">Interested At You</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button @click="YourInterestedTab" class="nav-link" id="your_interested-tab" data-bs-toggle="tab"
                                            data-bs-target="#your_interested" type="button" role="tab" aria-controls="your_interested"
                                            aria-selected="false">Your Interested At</button>
                                    </li>
                                    
                                   

                                </ul>
                                <div class="p-2">

                                    <div class="tab-content" id="myTabContent">

                                        <!-- Just Joined Table -->
                                        <div class="tab-pane fade active show " id="interested_at" role="tabpanel" aria-labelledby="interested_at-tab">
                                            <Spinner v-if="loading" />
                                            <div v-else class="row table">
                                                <div class="col-12 col-xl-4 col-lg-6 col-md-6 tr  p-1" v-for="(item) in atData" >
                                                  <Card :item="item" />
                                                </div>
                                            </div>
                                            <button v-if="at?.next_page_url != null && !loading" class="btn btn-primary mt-3 showmore mb-4 mb-lg-0"
                                               @click="InterestedAtMore()" >Show More</button>
                                        </div>

                                        <!-- My Matches Table -->
                                        <div class="tab-pane fade" id="your_interested" role="tabpanel" aria-labelledby="your_interested-tab">
                                            <Spinner v-if="loading" />
                                            <div v-else  class="row table">
                                                <div class="col-12 col-xl-4 col-lg-6 col-md-6 tr  p-1" v-for="(item) in yourInterestedData" >
                                                  <Card :item="item" />
                                                </div>
                                            </div>
                                            <button v-if="yourInterested?.next_page_url != null && !loading" class="btn btn-primary mt-3 showmore mb-4 mb-lg-0"
                                               @click="MoreYourInterested()" >Show More</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>


                   
                      
                  </div>
              </div>
  
  
  
          </div>
          <MobileNav :active="active"/>
    
  </body>
    </template>
  
   
    <script>
    import AOS from 'aos'
    import { Icon } from "@iconify/vue";
    import MainNav from "@/components/Authenticated/UserMainNav.vue";
    import SideNav from "@/components/Authenticated/UserSideNav.vue";
    import MobileNav from  "@/components/Authenticated/MobileNav.vue";
    import Card from "@/components/Authenticated/UserCard.vue";
    import { InterestedAt,YourInterested } from "@/util/Services/Authenticated/matches";
    import Spinner from "@/components/Authenticated/Spinner.vue";
    import { ref } from 'vue';
    export default {
      name: "PendingProposal",
      setup() {
            const atCount             = ref(1);
            const yourInterestedCount = ref(1);
            const loading             = ref(false);
            const show                = ref(false);
            const active              = 'PendingProposal';
            const tab                 = 'joined';
            //Interested At
            const atData              = ref([]);
            const at                  = ref([]);
            //Your Interested
            const yourInterestedData  = ref([]);
            const yourInterested      = ref([]);
            return {
                atCount,yourInterestedCount,loading,show,active,tab,atData,at,yourInterestedData,yourInterested
            }
        },
      components: {
        MainNav,SideNav,Icon,MobileNav,Card,Spinner
      },
      methods: {
        sidenav() {
          this.show = !this.show;
        },
        InterestedAtMore(){
          this.atCount++;
          this.InterestedAtAPI();
        },
        InterestedAtTab(){
          this.atCount = 1;
          this.atData = [];
          this.InterestedAtAPI();
        },
        async InterestedAtAPI(){
          if (this.atCount == 1) this.loading = true;
           await InterestedAt(this.atCount).
          then(r=>{this.at = r?.data?.data,r?.data?.data?.data ? this.atData.push(...r?.data?.data?.data) : []});
          if (this.atCount == 1) this.spinner();
        },
        MoreYourInterested(){
          this.yourInterestedCount++;
          this.YourInterestedAPI();
        },
        YourInterestedTab(){
          this.yourInterestedCount = 1;
          this.yourInterestedData = [];
          this.YourInterestedAPI();
        },
        async YourInterestedAPI(){
          if (this.yourInterestedCount == 1) this.loading = true;
          await YourInterested(this.yourInterestedCount).
          then(r=>{this.yourInterested = r?.data?.data,r?.data?.data?.data ? this.yourInterestedData.push(...r?.data?.data?.data) : []});
          if (this.yourInterestedCount == 1) this.spinner();
        },
        spinner(){
              var overlayLoader = document.querySelector('.spinner-border');
              if (overlayLoader)  setTimeout(() => overlayLoader.classList.add('fade-out'), 15);
              setTimeout(() =>  this.loading = false, 20);
         },
      },
      mounted() {
        AOS.init()  
      },
      created(){
        this.InterestedAtAPI();
      }
    };
    </script>
    <style scoped src="@/assets/css/application.css" />
    <style scoped src="@/assets/css/components/Authenticated/tab_card.css"/>
    <style scoped src="@/assets/css/components/Authenticated/spinner.css"/>
  <style scoped >
   .select-wrap .icon {
    position: absolute;
    right: 0px;
    top:0%;
    -webkit-transform: translateY(50%) translateX(-15px);
    -ms-transform: translateY(50%) translateX(-15px);
    transform: translateY(50%) translateX(-15px);
    transition: .3s;
    }
    .form-control{
      
        font-size: .7rem !important;
        padding: .175rem !important;
    }
    input{
        font-size: .7rem !important;
    }
  </style>
   
    