<template>
    <expansion-panel>
       <label for="panel1" class="expansion-panel-header"><h6 class="fs-title">Profile Picture</h6></label>
           <div class="expansion-panel-content">
               <fieldset>
               <form @submit.prevent="submit">
                   <div class="form-card">

                       <div id="app" class="container my-3">
                           <div class="row">
                               <div class="col-md-12  row">
                                    <div class="col-md-6 d-flex align-items-center">
                                       <div class="form-group text-start ">
                                           <label for="my_file">Profile pic</label>
                                           <input :key="reload"  type="file" accept="image/png, image/jpeg, image/jpg" @change="previewImage" class="form-control-file" id="my_file">
                                       </div>
                                       <span v-if="v$.image.$error" class="error"> {{ v$.image.$errors[0].$message.replace('This field','Password') }} <br></span>
                                    </div>
                                    <div class="col-md-6">
                                                    <v-card  v-if="preview" width="auto m-2">
                                                      <div class="example">
                                                        <cropper ref="cropper" class="example-cropper" :src="preview" 	:stencil-props="{
                                                          handlers: {},
                                                          movable: false,
                                                          resizable: false,
                                                          aspectRatio: 257/218,
                                                        }"
                                                        :resize-image="{
                                                          adjustStencil: false
                                                        }"
                                                        image-restriction="stencil" />
                                                        <drop-zone
                                                          v-model="preview"
                                                          :label="upload"
                                                          :location="location"
                                                          
                                                          @uploaded="newImagesUploaded"
                                                          class="pb-15"
                                                        />
                                                        <div class="button-wrapper ">
                                                          <span v-if="!loading" class="button" @click="cropImage"> Upload </span>
                                                          <div v-else class="spinner-border " role="status"></div>
                                                        </div>
                                                      </div>
                                                    </v-card>
                                    </div>
                                    <h5 v-if="pending" class="mt-3">Pending For Approvel</h5>
                                    <div v-if="pending"  class="col-md-12 row">

                                      <div class="col-md-2 "  >
                                        <div class="containerimg">
                                            <img :src="base_url+pending?.image" class="img-fluid  shadow-lg" />
                                            <!-- <button @click="remove(pending?.id)" class="button">X</button> -->
                                        </div>
                                      </div>
                                  </div>
                               </div>
                           </div>
                       </div>
                   </div>
               </form>                    
               </fieldset>
           </div>
       </expansion-panel>
       <ErrorToast :key="error"  :message="error" @update="errorup" />
       <SuccesToast :key="success"  :message="success" @update="errorup" />
</template>
<script>
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import Multiselect from 'vue-multiselect';
import { required } from "@vuelidate/validators";
import { useVuelidate } from '@vuelidate/core';
import ErrorToast from "@/components/General/ErrorToast.vue";
import SuccesToast from "@/components/General/SuccessToast.vue";
import { profilePic,pendingProfilePic } from "@/util/Services/Authenticated/edit";

export default{
   components:{
       Multiselect,ErrorToast,SuccesToast,Cropper
   },
   data(){
       return{
            v$:useVuelidate(),
            preview: null,
            image: null,
            error:null,
            success:null,
            croppedImageSrc:null,
            loading:false,
            reload:false,
            pending:null,
            base_url:null,
       }
   },
   validations(){
        return{
            image:{required},
        } ;
    },
 
   methods:{
   async cropImage() {
      const result = this.$refs.cropper.getResult();
      if (result) {
          this.loading =true;
            await  profilePic(this.dataURLtoFile(result.canvas.toDataURL(
            "image/jpeg"
          ))).then(r=>{this.error = r?.response?.data?.message,this.success = r?.data?.message,this.loading = false});
              if (this.success) {
                this.image = null;
                this.preview = null;
                this.reload = !this.reload;
                this.getPending();
              }
          }
    },
    previewImage: function(event) {
            var input = event.target;
            if (input.files) {
            var reader = new FileReader();
            reader.onload = (e) => {
                this.preview = e.target.result;
            }
            this.image=input.files[0];
            reader.readAsDataURL(input.files[0]);
            }
    },
    dataURLtoFile(dataurl, filename) {
          var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
          while(n--){
            u8arr[n] = bstr.charCodeAt(n);
          }
          return new File([u8arr], filename, {type:mime});
    },
    errorup(){
            this.error =null;
            this.success = null;
    },
    async getPending(){
          await pendingProfilePic().then(r=>{this.pending = r?.data?.data ? r?.data?.data : [],this.base_url = r?.data?.base_url});
    }
        
 },mounted(){
  this.getPending();
 }
   
}
</script>
<style scoped src="@/assets/css/application.css" />
<style scoped src="@/assets/css/components/Authenticated/edit.css"></style>
<style scoped>
img{
   height: 120px;
   width: 100px;
   object-fit: contain;
}
.cropper {
	height: 200px;
	width: 200px;
	background: #DDD;
}
.example-cropper {
      border: solid 1px #eee;
      min-height: 200px;
      width: 100%;
      height: 5vh;
    }
    
    .button-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 17px;
      margin-bottom: 30px !important;
    }
    
    .button {
      color: white;
      font-size: 16px;
      padding: 10px 20px;
      background: #E3086C;
      cursor: pointer;
      transition: background 0.5s;
      font-family: Open Sans, Arial;
      margin: 0 10px;
    }
    
    .button:hover {
      background: #b90455;
    }
    
    .button input {
      display: none;
    }
    .spinner-border{
    color: #af0b55;
}
.containerimg {
  position: relative;
  height: 128.5px !important;
  width: 109px !important;
}

.containerimg img {
    height: 128.5px !important;
    width: 109px !important;
    object-fit:fill;
}

.containerimg .button {
  position: absolute;
  top: 0%;
  left: 100%;
  padding: 4px 5px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: red;
  color: white;
  font-size: 10px;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
}
</style>
