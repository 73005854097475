<template>
  <div
    class="site-blocks-cover overlay aos-init aos-animate"
    style="
      background-image: url('../img/hero.jpg');
      background-position: 50% 50%;
      background-size: cover;
    "
    data-aos="fade"
    data-stellar-background-ratio="0.5"
  >
    <div class="container">
      <div class="row align-items-center justify-content-center text-center">
        <div class="col-md-12">
          <div class="row justify-content-center mb-4 pt-5">
            <div class="col-md-8 text-center">
              <h1
                class="aos-init aos-animate display-6 m-0 text-white txt-shadow"
                data-aos="fade-up"
              >
                Find your
              </h1>
              <h1
                class="aos-init aos-animate display-1 secondary txt-shadow"
                data-aos="fade-up"
              >
                Perfect Companion
              </h1>

              <p
                data-aos="fade-up"
                data-aos-delay="100"
                class="aos-init aos-animate text-white fs-5 txt-shadow"
              >
                We made it easy for you to get life partner in your location
              </p>
            </div>
          </div>
          <div
            class="form-search-wrap p-3 p-md-4 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <form @submit.prevent="submitForm">
              <div
                class="d-grid d-lg-flex gap-3 gap-lg-5 justify-lg-content-evenly align-items-center text-start"
              >
                <div class="w-auto">
                  <label for="I’m looking for">I’m looking for</label>
                  <div class="select-wrap">
                    <Icon class="icon" icon="iconamoon:arrow-down-2-light" />
                    <select v-model="form.gender" class="form-control" name="" id="">
                      <option value=null>Select any</option>
                      <option  v-for="item in this?.slide?.gender" :value="item">
                        {{ item }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="w-auto">
                  <label for="Martial Status">Martial Status</label>

                  <div class="select-wrap">
                    <Icon class="icon" icon="iconamoon:arrow-down-2-light" />
                    <select v-model="form.martial_status" class="form-control" name="">
                      <option  value=null >Select any</option>
                      <option  v-for="martial in martialOptions" :key="martialOptions" :value="martial?.title">
                        {{ martial?.title }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="w-auto">
                  <label for="I’m looking for">Religion</label>
                  <div class="select-wrap">
                    <Icon class="icon" icon="iconamoon:arrow-down-2-light" />
                    <select @change="castLoad" v-model="form.religion" class="form-control" name="" id="">
                      <option   value=null >Select Religion</option>
                      <option  v-for="religion in religionOptions" :value="religion?.title">
                        {{ religion?.title }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="w-auto">
                  <label for="Caste">Caste</label>

                  <div class="select-wrap">
                    <Icon class="icon" icon="iconamoon:arrow-down-2-light" />
                    <select v-model="form.cast" class="form-control" name="" id="">
                      <option value=null >Select Caste</option>
                      <option v-for="item in castOptions" :value="item?.title">{{ item?.title }}</option>
                    </select>
                  </div>
                </div>
                <div class="w-auto">
                  <input
                    type="submit"
                    class="btn btn-primary btn-block rounded"
                    value="Search"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AOS from 'aos'
import { Icon } from "@iconify/vue";
import { religion,cast } from '@/util/Services/home';
export default {
  props: {
    slide: {
      type: Array,
      required: true,
    },
    martialOptions:{
      required: true,
    }
  },
  data(){
     return{
      form:{
          martial_status:null,
          gender:null,
          religion:null,
          cast:null,
      },
     
      religionOptions:[],
      castOptions:[],
     }
  },
  components: {
    Icon,
  },
  methods:{
   
    async religionLoad(){
          await religion().then(r => { this.religionOptions = r?.data?.data });
          // console.log(this.religionOptions)
        },
    async castLoad(){
          await cast(this.form?.religion).then(r => { this.castOptions = r?.data?.data });
          // console.log(this.castOptions)
        },    
        submitForm(){
                 this.$router.push('/search/'+JSON.stringify(this.form))
          },

  },
  mounted() {
    // console.log(this?.slide);
    // this.martialLoad();
    AOS.init()  
  },
  created(){
    this.religionLoad();
  }
};
</script>
