<template>
      <div class="col-lg-7 col-xl-7 col-12 ps-lg-3 pt-3 pt-lg-0">
                           <div class="profileinformation">
                                        <ul class="nav nav-tabs border-0" id="myTab" role="tablist">
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link active" id="personel-tab" data-bs-toggle="tab"
                                                    data-bs-target="#personel" type="button" role="tab"
                                                    aria-controls="personel" aria-selected="true">Personal
                                                    Information</button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link" id="preference-tab" data-bs-toggle="tab"
                                                    data-bs-target="#preference" type="button" role="tab"
                                                    aria-controls="preference" aria-selected="false">Partner Preference
                                                </button>
                                            </li>

                                        </ul>
                                        <div class="tab-content" id="myTabContent">
                                            <div class="tab-pane overflow-y-scroll fade show active" id="personel"
                                                role="tabpanel" aria-labelledby="personel-tab">
<!-- 
                                                <div class="info">
                                                    <Icon class="icon" icon="material-symbols-light:frame-person"/>
                                                    <div class="d-grid w-100 sta">
                                                        <h5 >A Few Line About My Daughter</h5>
                                                        <hr>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                            Praesent est augue, posuere ac
                                                            porttitor vestibulum, interdum ac orci. Nulla ullamcorper
                                                            magna in euismod sodales.
                                                            Donec sit amet arcu interdum, scelerisque lorem eu,
                                                            pellentesque mauris.. tempus ipsum
                                                            neque, et viverra erat rutrum a. Nulla quis quam in sem
                                                            tempus hendrerit. Donec dapibus est
                                                            sed felis auctor finibus. Morbi aliquet sem non augue
                                                            accumsan blandit.
                                                            Vestibulum non rutrum erat. Donec vel facilisis orci.
                                                            Praesent </p>
                                                    </div>
                                                </div> -->
                                                <div class="info">
                                                    <Icon class="icon" icon="hugeicons:profile-02"/>
                                                    <div class="d-grid w-100 sta">
                                                        <h5>Basic Details</h5>
                                                        <hr>
                                                        <ul class="row list-unstyled pt-2">

                                                            <li class="col-12 col-sm-6" v-for="(item,index) in basicDetails" >
                                                               <LiRow :item="item" />
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                                <div class="info">
                                                    <Icon class="icon" icon="mdi:religion-judaic"/>
                                                    <div class="d-grid w-100">
                                                        <h5>Religious Information</h5>
                                                        <hr>
                                                        <ul class="row list-unstyled pt-2 sta">
                                                            <li class="col-12 col-sm-6 " v-for="(item) in religiousInformation" >
                                                               <LiRow :item="item" />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="info">
                                                    <Icon class="icon" icon="hugeicons:contact"/>
                                                    <div class="d-grid w-100">
                                                        <h5>Contact Details</h5>
                                                        <hr>
                                                        <ul class="row list-unstyled pt-2 sta">
                                                            <div class="col-10 col-sm-6">
                                                                <li class="col-12 col-sm-12 " v-for="(item) in contactDetails" >
                                                                    <LiRow :item="item" />
                                                                </li>
                                                            </div>
                                                                <li v-if="!this.data?.user_data?.matched"class="col-2 col-sm-6 text-black" >
                                                                    <Icon icon="ic:baseline-lock" class="lock"/>
                                                                </li>
                                                        </ul>
                                                        
                                                    </div>
                                                </div>
                                                <div class="info">
                                                    <Icon  class="icon" icon="solar:case-linear"/>
                                                    <div class="d-grid w-100">
                                                        <h5>Professional Information</h5>
                                                        <hr>
                                                        <ul class="row list-unstyled pt-2 sta">
                                                            <li class="col-12 col-sm-6 " v-for="(item) in professionalInformation" >
                                                               <LiRow :item="item" />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="info">
                                                    <Icon class="icon" icon="fluent:location-ripple-16-regular"/>
                                                    <div class="d-grid w-100">
                                                        <h5>Location</h5>
                                                        <hr>
                                                        <ul class="row list-unstyled pt-2 sta">

                                                            <li class="col-12 col-sm-6 " v-for="(item) in location" >
                                                                    <LiRow :item="item" />
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="info">
                                                    <Icon class="icon" icon="fluent:location-ripple-16-regular"/>
                                                    <div class="d-grid w-100 sta">
                                                        <h5>Family Details</h5>
                                                        <hr>
                                                        <ul class="row list-unstyled pt-2">

                                                            <li class="col-12 col-sm-6 " v-for="(item) in familyDetails" >
                                                                    <LiRow :item="item" />
                                                            </li>

                                                        </ul>
                                                        <h6>About Family</h6>
                                                        <p>{{ this.data?.user_data?.family?.about }}</p>
                                                    </div>
                                                </div>
                                                <div class="info">
                                                    <Icon class="icon" icon="material-symbols-light:menstrual-health-outline-rounded"/>
                                                    <div class="d-grid w-100">
                                                        <h5>Life Style</h5>
                                                        <hr>
                                                        <ul class="row list-unstyled pt-2 sta">
                                                            <li class="col-12 col-sm-6 " v-for="(item) in lifeStyle" >
                                                                    <LiRow :item="item" />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="tab-pane overflow-y-scroll fade" id="preference" role="tabpanel"
                                                aria-labelledby="preference-tab">
                                                <div class="p-2 sta">
                                                    <h3>{{ $store.getters.user?.name }} & {{this.data?.user_data?.name}}</h3>
                                                    <div class="d-flex align-items-center">
                                                        <div class="flex-nowrap d-flex">
                                                            <img :src="$store.getters.base_url+$store.getters.user?.profile_pic" class="lazy" alt="">
                                                            <img :src=data?.base_url+data?.user_data?.profile_pic class="lazy" alt="">
                                                        </div>
                                                        <h5 class="ps-3">You match {{this.data?.user_data?.preferredMatches?.match}}/{{this.data?.user_data?.preferredMatches?.count_out_off}} of her preferences</h5>
                                                    </div>
                                                    <p>Your partner preferences match this member’s basic details and her partner preferences match your basic details</p>
                                                    <hr>
                                                    <div class="preference-text" v-for="(item) in preferences">
                                                        <Preference :item="item"/>
                                                    </div>
                                                </div>

                                                <br>
                                                <br>
                                            </div>

                                        </div>
                                    </div>
     </div>
</template>
<style scoped src="@/assets/css/application.css" ></style>
<script>
   import { Icon } from "@iconify/vue";
   import LiRow from "@/components/Authenticated/Show/LiRow.vue"
   import Preference from "./Preference.vue";
   export default {
      props: {
         data: {
            required: true,
         },
      },
      components: {
        Icon,
        LiRow,
        Preference
      },
      data(){
        return{
               basicDetails:[
                  {
                     head:'Profile Created By',
                     value:this.data?.user_data?.details?.created
                  },
                  {
                     head:'Name',
                     value:this.data?.user_data?.name
                  },
                  {
                     head:'Age',
                     value:this.data?.user_data?.age+' Yrs'
                  },
                  {
                     head:'Height',
                     value:this.data?.user_data?.details?.height+' cm'
                  },
                  {
                     head:'Gender',
                     value:this.data?.user_data?.details?.gender.charAt(0).toUpperCase()+ this.data?.user_data?.details?.gender.slice(1)
                  },
                  {
                     head:'Marital Status',
                     value:this.data?.user_data?.details?.marital_status
                  },
                  {
                     head:'Mother Tongue',
                     value:this.data?.user_data?.details?.mother_tongue
                  },
                  {
                     head:'Weight',
                     value:this.data?.user_data?.details?.weight? this.data?.user_data?.details?.weight+" kg":""
                  }
               ],
               religiousInformation:[
                  {
                     head:'Religion',
                     value:this.data?.user_data?.details?.religion
                  },
                  {
                     head:'Caste',
                     value:this.data?.user_data?.details?.cast
                  },
                  {
                     head:'Star',
                     value:this.data?.user_data?.horoscope?.star
                  },
                  {
                     head:'Dasa',
                     value:this.data?.user_data?.horoscope?.dasa
                  },
               ],
               contactDetails:[
                  {
                     head:'Contact Number',
                     value:this.data?.user_data?.details?.mobile && this.data?.user_data?.matched ? this.data?.user_data?.details?.mobile:'+91 1234567890',
                     lock:!this.data?.user_data?.matched,
                  },
                  {
                     head:'Contact Number',
                     value:this.data?.user_data?.details?.mobile1 && this.data?.user_data?.matched ? this.data?.user_data?.details?.mobile1:'+91 1234567890',
                     lock:!this.data?.user_data?.matched,
                  },
               ],
               professionalInformation:[
                  {
                     head:'Education',
                     value:this.data?.user_data?.education?.qualification ? this.data?.user_data?.education?.qualification:'Not Specified',
                  },
                  {
                     head:'Education Detail',
                     value:this.data?.user_data?.education?.qualification_details ? this.data?.user_data?.education?.qualification_details:'Not Specified',
                  },
                  {
                     head:'Occupation',
                     value:this.data?.user_data?.education?.profession ? this.data?.user_data?.education?.profession :'Not Specified',
                  },
                  {
                     head:'Occupation Detail',
                     value:this.data?.user_data?.education?.profession_details ? this.data?.user_data?.education?.profession_details :'Not Specified',
                  },
                  {
                     head:'Workplace',
                     value:this.data?.user_data?.education?.workplace ? this.data?.user_data?.education?.workplace :'Not Specified',
                  },
                  {
                     head:'Annual Income',
                     value:this.data?.user_data?.education?.income ? this.data?.user_data?.education?.income :'Not Specified',
                  },
               ],
               location:[
                  {
                     head:'Country',
                     value:this.data?.user_data?.details?.country,
                  },
                  {
                     head:'State',
                     value:this.data?.user_data?.details?.state,
                  },
                  {
                     head:'City',
                     value:this.data?.user_data?.details?.district,
                  },
                  //  {
                  //     head:'Citizenship',
                  //     value:'India',
                  //  },
               ],
               familyDetails:[
                  {
                     head:"Father's Name",
                     value:this.data?.user_data?.family?.father_name ? this.data?.user_data?.family?.father_name : '-',
                  },
                  {
                     head:"Mother's Name",
                     value:this.data?.user_data?.family?.mother_name ? this.data?.user_data?.family?.mother_name : '-',
                  },
                  {
                     head:"Father's Occupation",
                     value:this.data?.user_data?.family?.father_occupation ? this.data?.user_data?.family?.father_occupation : '-',
                  },
                  {
                     head:"Mother's Occupation",
                     value:this.data?.user_data?.family?.mother_occupation ? this.data?.user_data?.family?.mother_occupation : '-',
                  },
                  {
                     head:'No. of Brothers',
                     value:this.data?.user_data?.family?.no_brother ? this.data?.user_data?.family?.no_brother : '-',
                  },
                  {
                     head:"No Brother's Married",
                     value:this.data?.user_data?.family?.married_no_brother ? this.data?.user_data?.family?.married_no_brother : '-',
                  },
                  {
                     head:'No. of Sisters',
                     value:this.data?.user_data?.family?.no_sisters ? this.data?.user_data?.family?.no_sisters : '-',
                  },
                  {
                     head:"No Sister's Married",
                     value:this.data?.user_data?.family?.married_no_sisters ? this.data?.user_data?.family?.married_no_sisters : '-',
                  },
               ],
               lifeStyle:[
                  {
                     head:'Eating Habits',
                     value:this.data?.user_data?.details?.diet_preference
                  },
                  //  {
                  //     head:'Drinking Habits',
                  //     value:'Non- Drinker',
                  //  },
                  //  {
                  //     head:'Smoking Habits',
                  //     value:'Non- Drinker',
                  //  },
               ],
               preferences:[
                  {
                     head:'Marital Status',
                     value:this.data?.user_data?.preferredMatches?.Marital_Status?.data,
                     strict:true,
                     preference:this.data?.user_data?.preferredMatches?.Marital_Status?.status
                  },
                  {
                     head:'Age',
                     value:this.data?.user_data?.preferredMatches?.age?.data,
                     strict:false,
                     preference:this.data?.user_data?.preferredMatches?.age?.status
                  },
                  {
                     head:'Height',
                     value:this.data?.user_data?.preferredMatches?.height?.data,
                     strict:false,
                     preference:this.data?.user_data?.preferredMatches?.height?.status
                  },
                  {
                     head:'Qualification',
                     value:this.data?.user_data?.preferredMatches?.qualification?.data,
                     strict:false,
                     preference:this.data?.user_data?.preferredMatches?.qualification?.status
                  },
                  {
                     head:'Profession',
                     value:this.data?.user_data?.preferredMatches?.profession?.data,
                     strict:false,
                     preference:this.data?.user_data?.preferredMatches?.profession?.status
                  },
                  {
                     head:'Religion',
                     value:this.data?.user_data?.preferredMatches?.religion?.data,
                     strict:false,
                     preference:this.data?.user_data?.preferredMatches?.religion?.status
                  },
                  {
                     head:'Cast',
                     value:this.data?.user_data?.preferredMatches?.cast?.data,
                     strict:false,
                     preference:this.data?.user_data?.preferredMatches?.cast?.status
                  },
                  {
                     head:'Country',
                     value:this.data?.user_data?.preferredMatches?.country?.data,
                     strict:false,
                     preference:this.data?.user_data?.preferredMatches?.country?.status,
                  },
                  {
                     head:'State',
                     value:this.data?.user_data?.preferredMatches?.state?.data,
                     strict:false,
                     preference:this.data?.user_data?.preferredMatches?.state?.status,
                  },
                  {
                     head:'District',
                     value:this.data?.user_data?.preferredMatches?.district?.data,
                     strict:false,
                     preference:this.data?.user_data?.preferredMatches?.district?.status,
                  },
                  

               ]
         }

      },methods:{
         basicDetailsData(){
            if (this.data?.user_data?.preferredMatches?.HoroscopeMatch) {
               this.preferences.push({
                   head:'Horoscope Match',
                   value:this.data?.user_data?.preferredMatches?.HoroscopeMatch?.data,
                   strict:false,
                   preference:this.data?.user_data?.preferredMatches?.HoroscopeMatch?.status
                });
            }
         }
       
      },
       mounted(){
        this.basicDetailsData();
      }
    }
</script>
<style scoped>
.icon {
         font-size: 3rem;
         background-color: #F7941D;
         padding: .5rem;
         border-radius: 2vh;
         color: #251718;
         height: fit-content;
         line-height: 0;
      }
h5,h6 {
         color: black;
         text-align: start;
      }
p, b {
         font-size: .85rem;
    
     }
.tab-pane {
            background-color: #fff9f2;
            padding: .5rem;
            height: 100vh !important;
            scroll-behavior: smooth;
         }
</style>