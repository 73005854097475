<template>
    <div class="bg-pale">
<div class="container ">
  <div class="row servicebenefit pt-5 flex-wrap align-items-center">
  
    <div class="col-md-4" v-for="(item, index) in data" :key="index">
       <BeneCard :item=item />
    </div>
  </div>
</div>
</div>
</template>
<script>
   import BeneCard from '@/components/General/BeneCard.vue';
   export default {
      components: {
         BeneCard
      },
      data() {
        return {
            data:[{
                icon:'line-md:phone-call-loop',
                head:'Call Us on',
                p:'1800 - 103 - 4080 (INDIA-Toll Free)'
            },{
                icon:'streamline:missed-call',
                head:'Get a Call Back',
                p:'Give a missed call to 8893099999'
            },{
                icon:'streamline:chat-two-bubbles-oval',
                head:'Live chat',
                p:''
            }]
        }
    },
      
   }
   </script>
   