<template>
 <body data-aos-easing="slide" data-aos-duration="800" data-aos-delay="0" class="responsivewhite">
   
          <div class="d-flex flex-wrap">
              <SideNav :active="active" :show="show" />
  
              <div class="col-lg-9 col-xl-10 col-12 p-0">
                  <div class="d-flex flex-wrap ">
                      <MainNav  :active="active"  :show="show"  @nav-show="sidenav" />
  
                      <div class="col-lg-12 col-xl-12  col-12 p-0 sta">
                        <main class="p-0 p-lg-2">
                            <div class="mainbody2 d-flex flex-wrap p-3 p-lg-5 p-3 pt-5 mt-5 pt-lg-5 mt-lg-0 p-lg-5 searchpart">
                                <div class="container-fluid">
                                  
                                        <div class="row justify-content-around">
                                           
                                            <InputWithIcon v-if="SearchShow" :label="'Search by ID'" :label1="labels" :placeholder="''" @submit="SearchByID"/>
                                               
                                            <theLine v-if="SearchShow && AdvanceSearchShow"/>

                                            <AdvanceSearch @submit="AdvanceSearchAPI" v-if="AdvanceSearchShow"/>
                                           <div class="mb-3 mt-5">
                                                <div v-if="SearchData" class="tab-content shadow-lg " id="myTabContent">
                                                    <div class="tab-pane fade show active" id="joined" role="tabpanel"
                                                        aria-labelledby="joined-tab">
                                              
                                                        <Spinner v-if="loading"  />

                                                        <div v-else class="row table ">
                                                            <div  class="col-12 col-xl-4 col-lg-6 col-md-6 tr  p-1" v-for="(item) in SearchData"   >
                                                                  <Card :item="item" />
                                                            </div>
                                                        </div>
                                                        
                                                              <button v-if="SearchUrl?.next_page_url != null && !loading" class="btn  mt-3 showmore mb-4 mb-lg-0"
                                                              @click="More()" > Show More
                                                            </button>
                                                       
                                                       
                                                    </div>
                                                </div>
                                           </div>
                                           
                                        </div>

                                </div>

                            </div>
                        </main>

                      </div>
                  </div>
              </div>

          </div>
          <MobileNav :active="active"/>
          <!-- //tosat -->



    
  </body>
  <ErrorToast  :key="error"    :message="error"   @update="errorup" />
  <SuccesToast :key="success"  :message="success" @update="errorup" />
</template>
<script>
import AOS from 'aos'
import { Icon } from "@iconify/vue";
import MainNav from "@/components/Authenticated/UserMainNav.vue";
import SideNav from "@/components/Authenticated/UserSideNav.vue";
import MobileNav from  "@/components/Authenticated/MobileNav.vue"
import InputWithIcon from "@/components/Authenticated/Search/InputWithIcon.vue"
import AdvanceSearch from "@/components/Authenticated/Search/AdvanceSearch.vue"
import theLine from '@/components/Authenticated/Search/theLine.vue';
import { searchById,AdvanceSearchAPIs } from '@/util/Services/Authenticated/search';
import ErrorToast from "@/components/General/ErrorToast.vue"
import SuccesToast from "@/components/General/SuccessToast.vue"
import Spinner from "@/components/Authenticated/Spinner.vue"
import Card from "@/components/Authenticated/UserCard.vue"

export default {
  name: "AcceptedView",
  components: {
    MainNav,
    SideNav,
    Icon,
    MobileNav,
    InputWithIcon,
    AdvanceSearch,
    theLine,
    ErrorToast,
    SuccesToast,
    Spinner,
    Card
  },
  data() {
    return {
      error:null,
      success:null,
      active:"Search",
      show: false,
      labels:this.$store.getters.gender == 'Female' ? 'GRM':'BRD',
      SearchShow:true,
      SearchData:null,
      SearchUrl:[],
      AdvanceSearchShow:true,
      loading:false,
      page:1,
      inputData:null,
    };
  },
  methods: {
    sidenav() {
      this.show = !this.show;
    },
   async SearchByID(input){
       this.page = 1;
       this.loading =true;
       this.AdvanceSearchShow =false;
       this.inputData = input;
      await searchById(input,this.page)
       .then(r=>{this.error = r?.response?.data?.message,this.SearchUrl = r?.data?.data,this.loading = false,this.SearchData = r?.data?.data?.data});
      //  console.log(this.SearchData) 
    },
   async AdvanceSearchAPI(form){
        this.page = 1;
        this.loading =true;
        this.SearchShow = false;
        this.inputData = form;
        await AdvanceSearchAPIs(form,this.page)
        .then(r=>{this.error = r?.response?.data?.message,this.SearchUrl = r?.data?.data,this.loading = false,this.SearchData = r?.data?.data?.data});
        // console.log(form);
    },
   async More(){
      if (this.AdvanceSearchShow == false) {
        this.page++;
       await searchById(this.inputData,this.page)
       .then(r=>{this.error = r?.response?.data?.message,this.SearchUrl = r?.data?.data,this.loading = false,r?.data?.data?.data ? this.SearchData.push(...r?.data?.data?.data) : []});
      } else {
        this.page++;
       await AdvanceSearchAPIs(this.inputData,this.page)
       .then(r=>{this.error = r?.response?.data?.message,this.SearchUrl = r?.data?.data,this.loading = false,r?.data?.data?.data ? this.SearchData.push(...r?.data?.data?.data) : []});
      }
      
    },
    errorup(){
        this.$store.commit('SUCCESS_MESSAGE', null);
        // console.log(this.$store.auth)
        this.$store.state.auth.successMessage = null;
        this.success = null;
        this.error = null;
      },
    
  },
  mounted() {
    AOS.init()  
  },
};
</script>
<style scoped src="@/assets/css/application.css" />
<style scoped>
.tab-content{
  min-height: 50vh;
  background-color: #EFEFEF;
  padding: 1rem;
  border-radius: 10px;
}
.showmore {
        background-color: transparent !important;
        display: block;
        width: auto;
        margin: auto;
        text-decoration: underline;
        color: #251718;
    }
.showmore:hover {
        background-color: transparent !important;
        display: block;
        width: auto;
        margin: auto;
        text-decoration: underline;
        color: #b00553;
    }
</style>
