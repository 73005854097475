<template>
   <!-- :href="'/user/'+item?.id" -->
    <a @click="login(item?.id)" class="card sta text-decoration-none shadow-lg m-1 col-12 col-12">
        <img :src="item?.profile_pic" alt="" class="w-25 p-0 lazy shadow-lg">
        <div class="d-grid w-75 p-0 ps-2">
            <span>{{ item?.user_ids }}</span>
            <h6>{{ item?.name }}</h6>
            <p>{{ item?.age }} yrs, {{ item?.details?.height }}cm| {{ item?.education?.qualification }} | {{ item?.education?.profession }} |
               {{ item?.details?.marital_status }} | {{ item?.details?.cast }} | {{ item?.details?.district }}{{ item?.details?.state }} {{ item?.details?.country }}
                </p>
            <div class="d-flex justify-content-between mt-2">
                <button :to="{name: 'Home'}" class="callbtn"><Icon icon="ph:phone-call-fill"/>Call Now</button>
                <button class="interestbtn"><Icon icon="ph:heart-fill"/>Sent Interest</button>
            </div>
        </div>
    </a>
</template>
<script>
  import AOS from 'aos'
  import { Icon } from "@iconify/vue";
  export default {
    components: {
      Icon,
    },
    props: {
    item: {
      required: true,
    },
  },
  data() {
      return {
        innerprofile:"/user/"+this.item?.id,
      };
    },
    methods:{
      login(id) {
            // console.log('login');
            if (this.$store.getters.isAuthenticated) {
              this.$router.push(this.innerprofile)
            }
        this.$emit('message-updated', true);
        }
    },
    mounted() {
      AOS.init()  
    },
  };
  </script>
  <style scoped src="@/assets/css/application.css" ></style>
  <style scoped >
  .card{  border-radius: 10px !important; overflow: hidden;}
   img {
          display: block;
          height: -moz-max-content;
          height: max-content;
          margin: auto;
          border-radius: 1vh;
      }
  .d-grid {
          align-content: center;
          align-items: center;
      }
  .d-grid span {
          font-size: x-small;
          color: #8D7D7F;
      }
  .d-grid h6 {
          /* margin: 0; */
          font-weight: 600;
          color: #251718;
      }
  .d-grid p {
          font-size: 12px;
          color: #8D7D7F;
          text-align: justify;
          margin: 0;
          line-height: normal;
      }
  .interestbtn {
          font-size: x-small;
          display: flex;
          border-radius: 2vh;
          color: #E3086C;
          justify-content: center;
          align-items: center;
          grid-gap: .2rem;
          background: #FFF3F9;
          border: .5px solid #FFE1EF;
          padding: .2rem .5rem;
      }
      .callbtn {
            font-size: x-small;
            background: #F4FFF3;
            border-radius: 2vh;
            padding: 0;
            color: #35CB00;
            display: flex;
            justify-content: center;
            align-items: center;
            grid-gap: .2rem;
            border: .5px solid #C2FEC8;
            padding: .2rem .5rem;
        }
        button{
            z-index: 999;
        }
        img{
          height: 130px !important;
          width: 110px !important;
          object-fit: cover;
        }

 </style>