<template>
    <!-- Navigation bar  -->
    <Nav :navactive="navactive"  />
    <!-- Main  -->
    <main class="site-wrap">
     <Upgrade />
     <Pale />
    </main>
    <Footer />
  </template>
  <script>
  // @ is an alias to /src
  import Nav from "@/components/General/Navbar.vue";
  import Footer from "@/components/General/Footer.vue";
  import Pale from "@/components/General/Pale.vue";
  import About from "@/components/About/About.vue";
  import Upgrade from "@/components/Payment/Upgrade.vue";
  export default {
    name: "UpgradeView",
    components: {
      Nav,
      Pale,
      Footer,
      About,
      Upgrade
    },
    data() {
      return {
        navactive:"upgrade",
      };
    },
    
  };
  </script>
  <style>
  @media (min-width: 1600px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
      max-width: 1520px;
    }
  }

    

</style>
  