<template>
    <fieldset>
      <form @submit.prevent="next">
     <div class="form-card">
                    <div class="row">
                      <div class="col-7">
                        <h5 class="fs-title">Account Information:</h5>
                      </div>
                      <div class="col-5">
                        <h6 class="steps">Step 1 - 4</h6>
                      </div>
                    </div> 
                    
                    <label class="fieldlabels">Profile Created by :</label>
                    <select required  v-model="form.created_by" v-bind:class="{'err':v$.form.created_by.$error}" class="form-select" aria-label="Default select example">
                        <option value="">Select Relationship</option>
                         <option v-for="item in createdBy" :value="item?.title" > {{ item?.title }}</option>
                    </select>
                    <span v-if="v$.form.created_by.$error" class="error"> Created by is required <br></span>
                    <label class="fieldlabels">Full Name : </label> 
                    <input  required v-model="form.name" v-bind:class="{'err':v$.form.name.$error}"  type="text" placeholder="Full Name" />
                    <span v-if="v$.form.name.$error" class="error"> Name is required <br></span>
                 
                    <label class="fieldlabels">Email : </label> 
                    <input required @input="checkMail" v-model="form.emails" :class="{'err':v$.form.emails.$error}"  type="email"  id="email"
                    placeholder="Email" />
                    <span v-if="v$.form.emails.$error" class="error"> 
                      {{ v$.form.emails.$errors[0].$message == "Value is required" ? "Email is required":v$.form.emails.$errors[0].$message }}  <br></span>
                      <span v-if="!mailcheck" class="error mt-1"> 
                      {{ "This email address is already registered in our system.Please use a different email address"}}
                    </br> 
                    </span>

                    <div class="row">
                   
                      <div class="col-md-6">
                          <label class="fieldlabels">Martial Status:</label>
                          <select required v-model="form.marital_status" v-bind:class="{'err':v$.form.marital_status.$error}" class="form-select" aria-label="Default select example">
                              <option value="">Select Martial Status</option>
                              <option v-for="item in maritalStatus"  :value="item?.title">{{ item?.title }}</option>
                          </select>
                          <span v-if="v$.form.marital_status.$error" class="error"> Martial Status is required <br></span>
                      </div>
                      
                      <div class="col-md-6">
                          <label class="fieldlabels">Gender :</label>
                          <select @change="genderChange" required v-model="form.gender" v-bind:class="{'err':v$.form.gender.$error}" class="form-select" aria-label="Default select example">
                            <option value="">Select Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </select>
                          <span v-if="v$.form.gender.$error" class="error"> Gender is required <br></span>
                      </div>
                      <div class="col-md-6">
                          <label class="fieldlabels">Date of Birth :</label> 
                          <input required v-model="form.date" id="datefield" v-bind:class="{'err':v$.form.date.$error}" type="date" name="cpwd" placeholder="" />
                          <span v-if="v$.form.date.$error" class="error"> Date of Birth is required <br></span>
                      </div>
                      <div class="col-md-6">
                          <label class="fieldlabels">Religion:</label>
                          <select @change="castAPI" v-model="form.religion" class="form-select" aria-label="Default select example">
                            <option value="">Select Religion</option>
                            <option v-for="item in religions" :value="item?.title">{{ item?.title }}</option>
                          </select>
                      </div>
                      <div class="col-md-6">
                        <label class="fieldlabels">Caste:</label>
                        <select v-model="form.cast" class="form-select" aria-label="Default select example">
                          <option value="">Select Caste</option>
                          <option v-for="item in casts"  :value="item?.title">{{ item?.title }}</option>

                        </select>
                      </div>
                      <div class="col-md-6">
                        <label class="fieldlabels">Mother tongue:</label>
                        <select v-model="form.mother_tongue" class="form-select" aria-label="Default select example">
                          <option value="" >Select Mother tongue</option>
                          <option v-for="item in mother_tongue" :value="item?.title">{{ item?.title }}</option>
                        </select>
                      </div>
                      <div class="col-md-6">
                        <label class="fieldlabels">Country:</label>
                        <select @change="stateAPI" v-model="form.country" class="form-select" aria-label="Default select example">
                          <option value="">Select Country</option>
                          <option v-for="item in country" :value="item?.title">{{ item?.title }}</option>
                        </select>
                      </div>
                      <div class="col-md-6">
                        <label class="fieldlabels">State:</label>
                        <select @change="districtAPI" v-model="form.state" class="form-select" aria-label="Default select example">
                          <option value="">Select state</option>
                          <option v-for="item in states" :value="item?.title">{{ item?.title }}</option>
                        </select>
                      </div>
                      <div class="col-md-6">
                        <label class="fieldlabels">District:</label>
                        <select  v-model="form.district" class="form-select" aria-label="Default select example">
                          <option value="">Select District</option>
                          <option v-for="item in district" :value="item?.title">{{ item?.title }}</option>
                        </select>
                      </div>
                      <div class="col-md-6">
                        <label class="fieldlabels">Pin:</label>
                        <input required v-model="form.post" v-bind:class="{'err':v$.form.post.$error}" type="number" placeholder="Postal Code" />
                        <span v-if="v$.form.post.$error" class="error"> Pin is required <br></span>
                      </div>
                      <div class="col-md-12">
                        <label class="fieldlabels">Whatsapp Number:</label>
                        <input   v-model="form.whatsapp" v-bind:class="{'err':v$.form.whatsapp.$error}"  type="number" placeholder="Whatsapp Number" />
                        <span v-if="v$.form.whatsapp.$error" class="error"> Please enter a valid mobile number with ten digits. <br></span>
                      </div>
                      <div class="col-12">
                        <label class="fieldlabels">Mobile Number:</label> 
                        <input required v-model="form.phone" v-bind:class="{'err':v$.form.phone.$error}" type="number" placeholder="Mobile number" />
                        <span v-if="v$.form.phone.$error" class="error"> {{ v$.form.phone.$errors[0].$message == "Value is required" ? "Mobile Number is required":"Please enter a valid mobile number with ten digits." }} <br></span>
                      </div>
                      <div class="col-12">
                        <label class="fieldlabels">Password:</label> 
                        <input required v-model="form.password" v-bind:class="{'err':v$.form.password.$error}" type="password" placeholder="Password" />
                        <span v-if="v$.form.password.$error" class="error"> 
                          {{ v$.form.password.$errors[0].$message == "Value is required" ? "Password is required":v$.form.password.$errors[0].$message.replace('This field','Password') }} <br></span>
                      </div>
                    </div>

    </div> <input @click="next" type="submit" name="next" class="next action-button" value="Next" />
  </form>
</fieldset>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required,minLength,maxLength,email } from '@vuelidate/validators'
import { profileCreatedBy,getMotherTongue,country,states,district,mailcheck } from "@/util/Services/register"
import { martial_status,religion,cast } from "@/util/Services/home"
export default{
    props: {
    current: {
      required: true,
    },
    formData1:{
      required: true,
    }
},
data() {
    return {
                v$:useVuelidate(),
           form:{
                  created_by :     this.formData1?.created_by ? this.formData1?.created_by: "",
                  name :           this.formData1?.name ? this.formData1?.name: "",
                  emails :           this.formData1?.email ? this.formData1?.email: "",
                  gender :         this.formData1?.gender ? this.formData1?.gender:'',
                  marital_status : this.formData1?.marital_status ? this.formData1?.marital_status:'',
                  date :           this.formData1?.date ? this.formData1?.date:'',
                  state :          this.formData1?.state ? this.formData1?.state:'',
                  country :        this.formData1?.country ? this.formData1?.country:'',
                  district :       this.formData1?.district ? this.formData1?.district:'',
                  phone :          this.formData1?.phone ? this.formData1?.phone:'',
                  password :       this.formData1?.password ? this.formData1?.password:'',
                  religion :       this.formData1?.religion ? this.formData1?.religion:'',
                  cast :           this.formData1?.cast ? this.formData1?.cast:'',
                  mother_tongue :  this.formData1?.mother_tongue ? this.formData1?.mother_tongue:'',
                  post           : this.formData1?.post ? this.formData1?.post:'',
                  whatsapp       : this.formData1?.whatsapp ? this.formData1?.whatsapp:'',
           },
                

                max_date:'',
                min_date:'',
                age:18,
                createdBy:[],
                maritalStatus:[],
                religions:[],
                casts:[],
                mother_tongue:[],
                country:[],
                states:[],
                district:[],
                mailcheck:true,
               
    };
},
validations(){
  return{
         form:{
                created_by : {required},
                name : {required},
                emails : {required,email},
                gender :{required},
                date : {required},
                phone :{required,minLength:minLength(10),maxLength:maxLength(10)},
                password : {required,minLength:minLength(8)},
                marital_status : {required},
                post : {required},
                whatsapp :{minLength:minLength(10),maxLength:maxLength(10)},
                // check:{required,sameAs:sameAs(true)},
                // captcha:{required,sameAs:sameAs(this.code)},
         } 
  } ;
},
methods:{
    next(){
      this.v$.$validate()
      // console.log(this.form);
      if(!this.v$.$error && this.mailcheck == true){
        
        this.$emit('form-data', this.form);
        this.$emit('message-updated', this.current+1);
        
      }else if(this.mailcheck != true){
          document.getElementById("email").focus();
      }
        
    }, dob() {
      var today = new Date();
      var dd    = today.getDate();
      var mm    = today.getMonth(); 
      var yyyy  = today.getFullYear()-this.age;
      if(dd<10){
              dd='0'+dd
          } 
      if(mm<10){
              mm='0'+mm
          } 
          today = yyyy+'-'+mm+'-'+dd;
          document.getElementById("datefield").setAttribute("max", today);
          today = new Date();
          yyyy = today.getFullYear()-150;
          today = yyyy+'-'+mm+'-'+dd;
          document.getElementById("datefield").setAttribute("min", today);
          
    },
    genderChange(){
      this.age = this.form.gender == 'male' ? 21:18;
      this.dob(); 
    },
    async createdByAPI(){
     await profileCreatedBy().then(r=>{this.createdBy = r?.data?.data});
    },
    async maritalStatusAPI(){
     await martial_status().then(r=>{this.maritalStatus = r?.data?.data});
    },
    async religionAPI(){
     await religion().then(r=>{this.religions = r?.data?.data});
    },
    async castAPI(){
      if (this.form.religion) {
        await cast(this.form.religion).then(r=>{this.casts = r?.data?.data});
      }
    },
    async MotherTongueAPI(){
      await getMotherTongue().then(r=>{this.mother_tongue = r?.data?.data});
    },
    async countryAPI(){
      await country().then(r=>{this.country = r?.data?.data});
    },
    async stateAPI(){
      if (this.form.country) {
        await states(this.form.country).then(r=>{this.states = r?.data?.data});
      }
    },
    async districtAPI(){
      if (this.form.state) {
        await district(this.form.state).then(r=>{this.district = r?.data?.data});
      }
    },
    async checkMail(){
      // console.log('email',this.form?.emails);
      if (this.form?.emails) {
        await mailcheck(this.form?.emails).then(r=>{this.mailcheck = r})
      }
      
      //  console.log('mailcheck',this.mailcheck);
    },

},mounted() {
  this.dob(); 
 
  // console.log(this.formData1)
    // this.max_date = this.subtractYears(new Date(), 18);
    // this.min_date = this.subtractYears(new Date(), 150);
    // this.date = this.max_date;
  },
  created(){
    this.createdByAPI();
    this.maritalStatusAPI();
    this.religionAPI();
    this.castAPI();
    this.MotherTongueAPI();
    this.countryAPI();
    this.stateAPI();
    this.districtAPI();
  }
}
</script>
<style>
.error{
 color: red;
 text-align:start !important;
 font-size:small !important;
}
.err{
  border-color: red !important;
}
</style>