<template>
    <fieldset>
                  <div class="form-card">
                    <div class="row">
                      <div class="col-12">
                        <h5 class="fs-title  text-center">Finish</h5>
                      </div>
                      
                    </div> 
                    <h2 class="purple-text text-center"><strong>Congratulations !</strong></h2>
                    <h5 class="text-center">Your password has been updated successfully.</h5>
                    <div class="d-flex justify-content-center mt-5" >
                        <a v-if="!$store.getters.isAuthenticated" @click="login" class="btn text-white">Login</a>
                    </div>
                   
                    
                  </div>
                 
                </fieldset>
               
</template>
<script>

export default{
    props: {
    data: {
      required: true,
    },
  },
  data(){
    return{
      show:false
    }
  },
  methods:{
    login() {
       // console.log('login');
     this.$emit('message-updated', true);
     },
  }
}
</script>