<template>

    <div class="row mt-5 happycouples">
          <div class="col-12">
            <div class="sechead">
              <Icon icon="twemoji:red-heart" class="big-icon"/>
              <h4 class="display-6">Our Happy Couples</h4>
              <Icon icon="twemoji:red-heart"class="big-icon"/>
            </div>

          </div>
          <div class="col-sm-6 col-md-6 col-lg-3 my-2" v-for="(item, index) in succes_story" :key="index">
            <Card :item=item  :base_url="base_url"/>
          </div>
          
        </div>
</template>

<script > 
import Card from '@/components/General/SuccessStoryCard.vue';
import AOS from 'aos'
import { Icon } from '@iconify/vue';
export default {
   components: {
       Icon,Card
   },
   props: {
       succes_story:{
        required: true,
      },
      base_url:{
        required: true,
      }
    },
   data() {
    return {
      image:"",
      moreLink:"/home"
    };
  },
  mounted(){
    // console.log('couple',this.base_url)
        AOS.init()
  }
}
</script>
<style scoped>
.big-icon {
   font-size: 28px;
}
</style>