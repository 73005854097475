<template>
    <div   class="d-flex pt-4 justify-content-between">
        <h6 class="looktitle m-0 text-black">Explore Goodlife Matrimony :</h6>
    </div>
    <div class="row pt-1 table overflow-y-scroll flex-nowrap" >
        <div  class="explore  "v-for="(item, index) in data" :key="index">
            <Card :item="item" />
        </div>
        
    </div>

                                           
</template>
<script>
  import Card from "@/components/Authenticated/Home/ExploreCard.vue"
  import AOS from 'aos'
  export default {
    components: {
        Card
    },
    mounted() {
      AOS.init()  
    },
    data() {
        return {
            data:[
                {
                    icon:"mdi:encryption-secure",
                    head:"Safe and Secured"
                },
                {
                    icon:"solar:diploma-verified-bold",
                    head:"Verified Profiles"
                },
                {
                    icon:"carbon:iot-connect",
                    head:"Smart Matchmaking"
                },
                {
                    icon:"ic:round-privacy-tip",
                    head:"Privacy you control"
                },
                {
                    icon:"material-symbols:workspace-premium-rounded",
                    head:"Elite Matrimony"
                },
                {
                    icon:"mdi:customer-service",
                    head:"Assisted"
                },
            ]
        }
    }
  };
  </script>
<style scoped src="@/assets/css/application.css" ></style>
<style scoped>
    .table>:not(caption)>*>* {
            color: #e3086c !important;
    }
</style>