<template>
    <fieldset>
      <form @submit.prevent="next">
     <div class="form-card">
                    <div class="row">
                      <div class="col-12 m-3">
                        <h5 class="fs-title text-center ">Forgot Password</h5>
                      </div>
                     
                    </div> 
                    <label class="fieldlabels">Email : </label> 
                    <input required @change="checkMail" v-model="form.emails" :class="{'err':v$.form.emails.$error}"  type="email"  id="email"
                    placeholder="Enter Your Email Id" />
                    <span v-if="v$.form.emails.$error" class="error"> 
                      {{ v$.form.emails.$errors[0].$message == "Value is required" ? "Email is required":v$.form.emails.$errors[0].$message }} 
                      <br>  
                    </span>
                    <br v-else>
                    <span v-if="mailcheck" class="error mt-1"> 
                      {{ "No account found with this email. Please check and try again or contact support if needed."}}
                    </span>
                      <br v-else>
    </div>
    <div class="d-flex justify-content-center">
     
      <div v-if="loading" style="color:#e3086c;" class="spinner-border m-4 " role="status">
        <span class="sr-only"></span> 
        </div>
        <input v-else @click="next" type="submit" name="next" class="next action-button" value="Submit" />

    </div> 
  </form>
</fieldset>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required,email } from '@vuelidate/validators'
import { mailcheck } from "@/util/Services/register"
export default{
  props: {
    loading: {
      required: true,
    },
  },
    data() {
        return {
              v$:useVuelidate(),
              form:{
                      emails : null,
              },
              mailcheck:false,     
        };
    },
    validations(){
      return{
            form:{
                    emails : {required,email}  
            } 
      } ;
    },
    methods:{
        next(){
          this.v$.$validate()
          if(!this.v$.$error && this.mailcheck == false){
            this.$emit('form-data', this.form);
          }
        }, 
        async checkMail(){
          if (this.form?.emails && !this.v$.$error) {
              await mailcheck(this.form?.emails).then(r=>{this.mailcheck = r})
          }
        },

    }
}
</script>
<style>
.error{
 color: red;
 text-align:start !important;
 font-size:small !important;
}
.err{
  border-color: red !important;
}
</style>