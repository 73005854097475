<template>
    <div class="site-section aos-init bg-white position-relative" data-aos="fade">

<div class="container">
  <div class="row flex-wrap">
    <div class="col-lg-6 clients sta">
      <h1 class="mb-2">Paid Membership Service</h1>
      <p>Goodlifematrimony.com is the Most Trusted Paid Matrimony site for singles to find their suitable life partner from all over the world. A matrimony website managed since 1996. Create your Paid Matrimonial membership and find suitable partner today.</p>
      <h4>Purpose</h4>
      <ul>
        <li>To understand what is goodlifeMatrimony.com</li>
        <li>To check the availability of Christian matching profiles through site</li>
        <li>To confirm you are getting enough responses</li>
      </ul>
    </div>
    <div class="col-12 col-lg-6 d-flex flex-wrap pt-5 pt-lg-0 justify-content-evenly">
     <img src="@/assets/img/ai-driven-img.avif" alt="" class="img-fluid">
    </div>


  </div>
</div>
</div>
</template>
<script > 
     import AOS from 'aos'
     
     export default {
        mounted(){
           AOS.init()
     }
     
     }
     </script>