<template>
    <a :href="'/user/'+item?.id" class="card border-1  text-decoration-none  col-12">
        <img :src="item?.profile_pic" alt="" class="w-25 p-0 lazy">
            <div class="d-grid w-75 p-0 ps-2 sta">
                <span>{{ item?.user_ids }}</span>
                <h6>{{ item?.name }}</h6>
                <p>{{ item?.age }}yrs {{ item?.details?.height }}cm | {{ item?.education?.qualification }} | {{ item?.education?.profession }} |
                    {{ item?.details?.marital_status }} | {{ item?.details?.cast }} | {{ item?.details?.district }}  {{ item?.details?.state }} {{ item?.details?.country }}
                </p>
                <div class="d-flex justify-content-between mt-2">
                  <button v-if="item?.matched" :to="{name: 'Home'}" class="callbtn"><Icon icon="ph:phone-call-fill"/>Call Now</button>
                    <button v-if="!item?.matched" class="interestbtn"><Icon class="iconify-icon" icon="ph:heart-fill"/>Sent Interest</button>
               </div>
         </div>
    </a>
</template>
<script>
  import AOS from 'aos'
  import { Icon } from "@iconify/vue";
  export default {
    components: {
      Icon,
    },
    props: {
    item: {
      required: true,
    },
  },
  data() {
      return {
        innerprofile:"/user/"+this.item?.id,
      };
    },
    mounted() {
      AOS.init()  
    },
  };
  
  </script>
   
  <style scoped src="@/assets/css/application.css" />
  <style scoped src="@/assets/css/components/Authenticated/home-HomeCard.css" />
  <style scoped>
  .callbtn {
      font-size: x-small;
      background: #F4FFF3;
      border-radius: 2vh;
      padding: 0;
      color: #35CB00;
      display: flex;
      justify-content: center;
      align-items: center;
      grid-gap: .2rem;
      border: .5px solid #C2FEC8;
      padding: .2rem .5rem;
  }
  </style>