<template>
    <footer class="site-footer pb-2 position-relative" style="z-index: 0;">
    <div class="container">
      <div class="row mr-4">
        <div class="col-md-4 mb-4 flex-row">
          <img src="@/assets/img/footerlogo.png" alt="" class="img-fluid mb-4"> <br>
          <a :href="facebook" class="pl-0 pr-3"><Icon class="big-icon" icon="eva:facebook-fill"/></a>
          <a :href="x" class="pl-3 pr-3 m-3"><Icon class="big-icon" icon="mage:x"/></a>
          <a :href="youtube" class="pl-3 pr-3 m-3"><Icon class="big-icon" icon="formkit:youtube"/></a>
          <a :href="insta" class="pl-3 pr-3 m-3 "><Icon class="big-icon" icon="lets-icons:insta"/></a>
          <a :href="linkedin" class="pl-3 pr-3 m-3 "><Icon class="big-icon" icon="ri:linkedin-fill"/></a>
        </div>
        <div class="col-md-8 ">
          <div class="row footer-inner">
            <div class="col-sm-6 col-md-4 mb-4 text-left">
              <h2 class="footer-heading mb-4 text-left" >About</h2>
              <ul class="list-unstyled align">
                <li><a :href="menu">Menu</a></li>
                <li><a :href="features">Features</a></li>
                <li><a :href="newsBlogs">News & Blogs</a></li>
                <li><a :href="help">Help & Supports</a></li>

              </ul>
            </div>
            <div class=" col-sm-6 col-md-4 mb-4">
              <h2 class="footer-heading mb-4">Company</h2>
              <ul class="list-unstyled align">
                <li><a :href="howWeWork">How we work</a></li>
                <li><a :href="termsOfService">Terms of service</a></li>
                <li><a :href="pricing">Pricing</a></li>
                <li><a :href="faq">FAQ</a></li>
              </ul>
            </div>
            <div class="col-md-4 mb-4">
              <h2 class="footer-heading mb-4">Contact Us</h2>
              <p class="text-white align">54, Padma Nagar, East Fort Thiruvananthapuram - 695 023. Kerala, India</p>
                <ul class="list-unstyled align">
                  <li><a href="#">
                    0471 7122222,8893099999</a></li>
                  <li><a href="#">support@goodlifematrimony.com</a></li>
                  <li><a href="#">www.goodlifematrimony.com</a></li>
                </ul>
              
            </div>
          </div>
        </div>
        <div class="col-12 text-center text-white mt-4">
         © Copyright  GOOD LIFE. All Rights Reserved Designed with <Icon icon="ph:heart-fill"/> <a :href="comapny" class="text-white">Kenland IT Solutions</a>
        </div>
      
      </div>
    
    </div>
    <hr class="border-white mt-4">
    <div class="container">
      <ul class="list-unstyled d-flex flex-wrap justify-content-between">
        <li>
          <a :href="privacyPolicy">Privacy Policy</a>
        </li>
        <li>
          <a :href="termsOfUse">Terms of Use</a>
        </li>
        <li>
          <a :href="salesAndRefunds">Sales and Refunds</a>
        </li>
        <li>
          <a :href="legal">Legal</a>
        </li>
        <li>
          <a :href="siteMap">Site Map</a>
        </li>
      </ul>
    </div>
  </footer>
</template>
<script > 

import AOS from 'aos'
import { Icon } from '@iconify/vue';
export default {
   components: {
       Icon
   },
   data() {
    return {
      image:"",
      facebook:"/home",
      x:"/home",
      youtube:"/home",
      insta:"/home",
      linkedin:"/home",
      menu:"/",
      features:"/",
      newsBlogs:"/",
      help:"/",
      howWeWork:"/",
      termsOfService:"/",
      pricing:"/",
      faq:"/",
      comapny:"/",
      privacyPolicy:"/",
      termsOfUse:"/",
      salesAndRefunds:"/",
      legal:"/",
      siteMap:"/",
    };
  },
  mounted(){
        AOS.init()
  }
}
</script>
<style scoped>
.big-icon {
   font-size: 21px;
}
.footer-heading{
  text-align: start !important;
} 
.align{
  text-align: start !important;
}

@media (min-width: 1600px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1520px;
    }
}
</style>