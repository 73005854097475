
<template>
<div v-bind:class="{ 'd-block':modelValue }" class="modal" id="modalbox" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content ">
        <div class="modal-header modalmain">
          <button @click="login" id="removeButton"><Icon icon="mingcute:close-fill"/></button>

        </div>
        <div class="modal-body">
          <h4 class="m-0 pink-text">Let’s Get Started Now!</h4>
          <p class="mb-3 grey-text">Login and find your life partner</p>
          <form class="sta" @submit.prevent="onSubmit" >
            <label class="sta"  for="userid">Email</label> <br>
            <input type="email" v-model="form.emails" v-bind:class="{'err':v$.form.emails.$error}" placeholder="Email"><br>
            <span v-if="v$.form.emails.$error" class="error"> 
              {{ v$.form.emails.$errors[0].$message == "Value is required" ? "Email is required":v$.form.emails.$errors[0].$message }}  <br></span>
            <label class="sta"  for="paswd">Password</label><br>
            <input type="password" v-model="form.password" v-bind:class="{'err':v$.form.password.$error}" placeholder="Password"><br>
            <span v-if="v$.form.password.$error" class="error"> Password is required <br></span>
            <input class="sta" checked type="checkbox"> <small >Remember me on this device</small> <br>
            <div class="d-flex pt-3 justify-content-between align-items-center">
              <a  type="submit"   @click="onSubmit" class="mt-2 submit"> Submit </a>
              <a :href="forgotpassword" class="forgotpswd">Forgot Password?</a>
            </div>
          </form> 
        </div>
        
      </div>
    </div>
    <ErrorToast :key="error"  :message="error" @update="errorup" />
  </div>
  
</template>

<script>
import AOS from 'aos'
import { Icon } from '@iconify/vue';
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { loginApi } from '@/util/Services/login';
import ErrorToast from "@/components/General/ErrorToast.vue"
export default {
    props: {
      modelValue: {
      type: Boolean,
      required: true,
    },
  },
  components: {
       Icon,ErrorToast
   },
   
  data() {
    return {
      shows:this.modelValue,
      model:"d-block",
      forgotpassword:"/forgot_password",
      v$:useVuelidate(),
      form:{
            emails:'',
            password:''
      },
      error:null,
    };
  },
  validations(){
  return{
    form:{
                emails:{required,email},
                password:{required}, 
    }
   
                
  } ;
},
  methods:{
   
        login() {
            this.$emit('update:modelValue', false)
        },
        async onSubmit() {
          this.v$.$validate()
          if(!this.v$.$error){
              await loginApi(this.form).then(r=>{this.error = r?.response?.data?.message})
              // console.log(this.$store.state)
              if(!this.error){
                  this.$router.push('/user/home') 
              }
          }
        },
    errorup(){
        this.error =null;
      },
  }, 
  mounted() {
    AOS.init()
    console.log('show',this.modelValue)
  },
  }
  </script>
   <style scoped>
   input[type="email"], input[type="password"] {
        border-radius: .5vh;
        border: 1px solid #c9c9c9;
        padding: .1rem .7rem;
    }
    template{
      font-family: "Inter", sans-serif !important;
    }
    .modal{
     position: fixed !important;
    }
   </style>