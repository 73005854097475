<template>
    <div class="site-section aos-init bg-white position-relative mt-5" data-aos="fade">
        <div class="container">
          <div class="row flex-wrap">
            <div class="d-flex align-content-center justify-content-center">
              <div class="col-md-6 p-4">
                  <h4 class="sta">Pay now to upgrade your profile</h4>
                  <ul class="paymentlist sta">
                      <li>Send unlimited messages & Chat, View 60 verified mobile Numbers</li>
                      <li>Check compatibility with matches by viewing unlimited horoscopes</li>
                      <li>View and  contact ID verified matches with photos from exclusive Prime section</li>
                      <li>Priority customer service helpine</li>
                  </ul>
                  <div class="d-flex align-content-center justify-content-center ">
                   
                    <div v-bind:class="{ 'best mt-1 border-danger':item?.best_selling  },{ 'mt-5 border-dark':!item?.best_selling  }"  class="card border m-2   text-center  p-0" v-for="(item, index) in cards" :key="index" >
                        <Card :item="item"/>
                    </div>
                     
                
                   
                  </div>
                  <button class="btn-primary btn w-auto fs-5 m-auto d-block mt-4">Pay now</button>
              </div>
          </div>
          </div>
        </div>
  </div>
</template>
<script>
import AOS from 'aos'
import Card from "@/components/Payment/PaymentCard.vue"
export default {
    name: "UpgradeComponent",
    components: {
      Card,
    },
    mounted(){
        AOS.init()
    },
    data() {
            return {
                cards: [    
                    {
                        best_selling:false,
                        name:"SILVER",
                        month:"3 Months",
                        amount:"4600"
                    },
                    {
                        best_selling:true,
                        name:"GOLD",
                        month:"6 Months",
                        amount:"5800"
                    },
                    {
                        best_selling:false,
                        name:"PLATINUM",
                        month:"3 Months",
                        amount:"4600"
                    },
                ],
            };
        },

    
  };
</script>
<style scoped>
    @media (min-width: 1600px) {
      .container,
      .container-lg,
      .container-md,
      .container-sm,
      .container-xl,
      .container-xxl {
        max-width: 1520px;
      }
    }
  
      .card{
          border-radius: 20px !important;
          width: 18rem;
          height: 12rem;
          margin: 0;
          overflow: hidden;
          border-width: 2px;
      }
      .best{
          border-radius: 20px;
          width: 18rem;
          height: 15rem;
          background-color: red !important;
          /* border-color: red !important; */
      }
      
  </style>
    