<template>
    <fieldset>
      <form @submit.prevent="next">
                  <div class="form-card">
                    <div class="row">
                      <div class="col-12">
                        <h5 class="fs-title text-center "> Update Password </h5>
                      </div>
                    </div>
                      <div class="row">
                          <div class="col-12">
                              <label class="fieldlabels">Password:</label> 
                              <input required v-model="form.password" v-bind:class="{'err':v$.form.password.$error}" type="password" placeholder="Enter the Password" />
                              <span v-if="v$.form.password.$error" class="error"> 
                                {{ v$.form.password.$errors[0].$message.replace('This field','Password') }} <br></span>
                          </div>
                          <div class="col-12">
                              <label class="fieldlabels">Re-enter Password:</label> 
                              <input required v-model="form.password1" v-bind:class="{'err':v$.form.password.$error}" type="password" placeholder="Re-enter the Password" />
                              <span v-if="v$.form.password1.$error" class="error"> 
                                {{v$.form.password1.$errors[0].$message == "The value must be equal to the other value" ? "Passwords do not match. Please ensure both fields contain the same password." : v$.form.password1.$errors[0].$message.replace('This field','Password') }} <br></span>
                          </div>
                      </div>
                  </div> 
                  <div :key="loading" class="d-flex justify-content-center">
                      <input v-if="!loading" @click="next" type="button" name="next" class="next action-button" value="Submit" /> 
                      <div v-else style="color:#e3086c;" class="spinner-border m-4 " role="status">
                          <span class="sr-only"></span> 
                      </div>
                  </div>
                 
                  </form>
                </fieldset>
</template>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<script>
import Multiselect from 'vue-multiselect'
import { useVuelidate } from '@vuelidate/core'
import { required, sameAs,minLength} from '@vuelidate/validators'
export default{
  components: { Multiselect },
data() {
    return {
      v$:useVuelidate(),
      form:{
        password:'',
        password1:''
      },
    };
  },
  validations(){
  return{
    form:{
                password : {required,minLength:minLength(8)},
                password1 : {required,minLength:minLength(8),sameAs:sameAs(this.form.password)},
    }
   
                
  } ;
},
  methods:{

    next(){
      this.v$.$validate()
      if(!this.v$.$error ){
        this.$emit('form-data', this.form);
      }
    },
}
}

</script>

