<template>
    <tr @click="readApi(item?.id,item?.read,item?.link,item?.interest)" :class="{'unread':item?.read != true}">
        <td class="view-message align-start dont-show">{{ item?.link }}</td>
        <td class="view-message ">{{ item?.message }}</td>
        <td class="view-message  text-right">{{ item?.timeago }}</td>
    </tr>

</template>

<script>

export default {
    props: {
        item: {required: true}
    },
    data(){
        return{
            innerprofile:"",
            }
        },
    methods:{
       async readApi(inbox_id,read,link,interest){
            if (read != true) {
                this.$emit('read',inbox_id);
            }
            if (link == "Interest Approved") {
                this.innerprofile = "/user/"+interest?.to;
                this.$router.push(this.innerprofile);
            } else if(link == "Interest") {
                this.innerprofile = "/user/"+interest?.from;
                this.$router.push(this.innerprofile);
            }else if (link == "Multiple Images" || link == "Profile Image") {
                this.$router.push('/user/profile_image');
            }else if (link == "Profile Edit") {
                this.$router.push('/user/profile_edit/0');
            }
        },
    }
}
</script>
<style scoped src="@/assets/css/components/Authenticated/inbox.css"/>
<style scoped src="@/assets/css/application.css" />