import axios from 'axios'
import store from '@/store'
import header from "@/util/Services/Authenticated/header"

let url = process.env.VUE_APP__BASE_URL;

export const justJoined = (page) => {
    return axios.get(url+"customer/matches/justJoined?page="+page).then((response) => response).catch(error => console.log(error));
  };
export const myMatches = (page) => {
    return axios.get(url+"customer/matches/myMatches?page="+page).then((response) => response).catch(error => console.log(error));
  };
export const Premium = (page) => {
    return axios.get(url+"customer/matches/Premium?page="+page).then((response) => response).catch(error => console.log(error));
  };
export const Suggested = (page) => {
    return axios.get(url+"customer/matches/Suggested?page="+page).then((response) => response).catch(error => console.log(error));
  };
export const AlreadyView = (page) => {
    return axios.get(url+"customer/matches/alreadyViewed?page="+page).then((response) => response).catch(error => console.log(error));
  };
export const successStory = () => {
    return axios.get(url+"customer/matches/successStory").then((response) => response).catch(error => console.log(error));
  };
export const QuickSearchPag = (page) => {
    return axios.post(url+"profile-search-filter-pagination?page="+page,
        {
            religion:store.getters.quick_religion,
            gender:store.getters.quick_gender,
            marital_status:store.getters.quick_martial_status,
            cast:store.getters.quick_cast
        }
    ).then((response) => response).catch((error) => error);
  };

export const InterestedAt = (page) => {
    return axios.get(url+"customer/pending-your_interested_at?page="+page).then((response) => response).catch(error => console.log(error));
};
export const YourInterested = (page) => {
  return axios.get(url+"customer/pending-interested_in_you?page="+page).then((response) => response).catch(error => console.log(error));
};