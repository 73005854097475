<template>
   <Nav />
   <main class="site-wrap bg-grey pt-5">
    <div class="container">
      <div class="row">
        <div class="registerpage d-flex justify-content-center pt-2 pt-md-5 mt-3">

          <div class="col-11 col-sm-10 col-md-10 col-lg-6 col-xl-6 text-center p-0 mt-3 mb-2">
            <div class="card col-12 p-3 p-lg-5 mb-4">
              <h2 id="heading">Register for free on Goodlife Matrimony</h2>
              <form id="msform">
                <!-- progressbar -->
                <ul id="progressbar" class="list-unstyled">
                  <li v-bind:class="{'active':current >= 1}" id="account"><strong>Basic</strong></li>
                  <li v-bind:class="{'active':current >= 2}" id="personal"><strong>Personal</strong></li>
                  <li v-bind:class="{'active':current >= 3}" id="payment"><strong>Preference</strong></li>
                  <li v-bind:class="{'active':current >= 4}" id="confirm"><strong>Finish</strong></li>
                </ul>
                <div class="progress">
                  <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                    aria-valuemin="0" aria-valuemax="100"></div>
                </div> <br> <!-- fieldsets -->
                
                    <FieldSet1 :current="current" :formData1="formData1" @form-data="form1" @message-updated="page" v-if="current == 1" />
                    <FieldSet2 :current="current" :formData2="formData2" @form-data="form2" @message-updated="page" v-if="current == 2" />
                    <FieldSet3 :current="current" :key="failed" :formData3="formData3" @form-data="form3" @message-updated="page" v-if="current == 3" />
                    <FieldSet4 :current="current" :key="data" :data="data" @message-updated="page" v-if="current == 4" />
                
                
              </form>
            </div>
          </div>

        </div>
      </div>
    </div>

  </main> 
  <Footer />
  <ErrorToast :key="error"  :message="error" @update="errorup" />
  <SuccesToast :key="success"  :message="success" @update="errorup" />
</template>
<script>
import Nav from '@/components/Register/Navbar.vue'
import Footer from "@/components/General/Footer.vue";
import FieldSet1 from '@/components/Register/FieldSet1.vue';
import FieldSet2 from '@/components/Register/FieldSet2.vue';
import FieldSet3 from '@/components/Register/FieldSet3.vue';
import FieldSet4 from '@/components/Register/FieldSet4.vue';
import { Registration } from "@/util/Services/register";
import ErrorToast from "@/components/General/ErrorToast.vue"
import SuccesToast from "@/components/General/SuccessToast.vue"
export default{
    name: "RegisterView",
    components: {
        Nav,
        Footer,
        FieldSet1,
        FieldSet2,
        FieldSet3,
        FieldSet4,
        ErrorToast,
        SuccesToast
    },
    data() {
        return {
            current:1,
            steps:4,
            formData1:'',
            formData2:'',
            formData3:'',
            error:null,
            success:null,
            failed:false,
            data:[],
        }
    },
    methods:{
        setProgressBar() {
        var percent = parseFloat(100 / this.steps) * this.current;
        percent = percent.toFixed();
        var progressBar = document.querySelector(".progress-bar");
        progressBar.style.width = percent + "%";
      },
      async page(count){
        if (count == 4 ) {
          this.failed = false;
          await Registration(this.formData1,this.formData2,this.formData3)
          .then(r=>{this.error = r?.response?.data?.message,this.success = r?.data?.message,this.data = r?.data?.data})
          // console.log(this.data);
          if(this.success){
            this.current = count;
          }else{
           this.failed = true;
          }
        }else{
          this.current = count;
        }
        this.setProgressBar();
      },
      form1(form){
        this.formData1 = form;
        // console.log(this.formData1,{form})
      },
      form2(form){
        this.formData2 = form;
      },
      form3(form){
        this.formData3 = form;
          // console.log(this.formData3,{form})
      },
      errorup(){
        this.error =null;
        this.success = null;
      },
    },
    mounted(){
        this.setProgressBar();
    }
}
</script>
<style>
 h2{
    font-family: "Lobster Two", sans-serif;
    color: #251718;
}
</style>