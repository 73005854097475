<template>
  <div class="site-section premium">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-2 col-md-1 order-1 order-md-0">
          <div class="slide-left">
            <Icon icon="solar:alt-arrow-left-linear" />
          </div>
        </div>
        <div class="col-12 col-md-10 order-0 order-md-1">
          <div class="d-flex justify-content-between">
            <h4 class="text-white">Premium Matches</h4>
            <a :href="moreLink" class="text-white">View More</a>
          </div>
          <div id="galleries-list">
            <ul>
              <li
                v-for="(item, index) in gallery"
                :key="index"
                id="gallery-item"
                class="gallery-item"
              >
                <!-- Your gallery item content here -->
                <Card :item="item" :base_url="base_url" @login="login" />
              </li>
            </ul>
          </div>
        </div>
        <div class="col-2 col-md-1 order-2 order-md-2">
          <div class="slide-right">
            <Icon icon="solar:alt-arrow-right-linear" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "@/components/Home/PremiumCard.vue";
import AOS from "aos";
import { Icon } from "@iconify/vue";
export default {
  components: {
    Icon,
    Card,
  },
  props: {
    gallery:{
        required: true,
      },
      base_url:{
        required: true,
      }
    },
  data() {
    return {
      numberElements: 4,
      numberViewed: 4,
      currentPosition: 1,
      image: "",
      moreLink: "/user/matches",
    };
  },
  methods: {
    getImage(img) {
      this.image = img;
    },
    login() {
        this.$emit('login', true);
        // console.log('login')
        },
    countLi() {
      try {
         return document.querySelectorAll("#galleries-list ul li").length;
      }
      catch(err) {
        console.log( err?.message);
      }
    },
    slideRight() {
      try {
      if (this.countLi() > this.numberViewed) {
        if (this.currentPosition === this.countLi() - (this.numberViewed - 1)) {
          document
            .querySelectorAll("#galleries-list ul li")
            .forEach(function (li) {
              li.style.visibility = "hidden";
              li.style.transition = "none";
            });
          this.setSliderPosition(this.numberViewed + 1, this.countLi());
          document
            .querySelectorAll("#galleries-list ul li")
            .forEach(function (li) {
              li.style.transition = "left .75s ease-out";
              li.style.visibility = "visible";
            });

          return this.setSliderPosition(
            this.currentPosition + 1,
            this.countLi()
          );
        } else {
          return this.setSliderPosition(
            this.currentPosition + 1,
            this.countLi()
          );
        }
      }
     }
      catch(err) {
        console.log( err?.message);
      }
    },

    slideLeft() {
      try {
      if (this.countLi() > this.numberViewed) {
        if (this.currentPosition !== 1) {
          return this.setSliderPosition(
            this.currentPosition - 1,
            this.countLi()
          );
        }
      }
    }
      catch(err) {
        console.log( err?.message);
      }
    },

    setSliderPosition(n1, count) {
      try {
      if (n1 < 1) {
        this.setSliderPosition(count - (this.numberViewed - 1) + n1, count);
        return;
      }
      n1 = ((n1 - 1) % (count - (this.numberViewed - 1))) + 1;
      this.currentPosition = n1;
      document.querySelectorAll("#galleries-list ul li").forEach(function (li) {
        li.style.left = -(n1 - 1) * li.offsetWidth + "px";
      });
    }
      catch(err) {
        console.log( err?.message);
      }
    },

    initWidths() {
      try {
      const galleryItems = document.querySelectorAll("#galleries-list ul li");
      const width = this.countLi();
      galleryItems.forEach(function (item) {
        item.style.width = 100 / width + "%";
      });
      document.querySelector("#galleries-list ul").style.width =
        100 + (width - this.numberViewed) * (100 / this.numberViewed) + "%";
      }
      catch(err) {
        console.log( err?.message);
      }
    },

    initSlider() {
      // this.initClones();
      this.initResponsive();
      this.initWidths();
    },

    initPosition() {
      this.setSliderPosition(this.currentPosition, this.countLi());
    },

    initResponsive() {
      if (window.innerWidth > 1400) {
        if (this.numberViewed !== 5) {
          this.numberViewed = 5;
          this.initWidths();
        }
      } else if (window.innerWidth > 1200) {
        if (this.numberViewed !== 4) {
          this.numberViewed = 4;
          this.initWidths();
        }
      } else if (window.innerWidth > 992) {
        if (this.numberViewed !== 3) {
          this.numberViewed = 3;
          this.initWidths();
        }
      } else if (window.innerWidth > 576) {
        if (this.numberViewed !== 2) {
          this.numberViewed = 2;
          this.initWidths();
        }
      } else if (this.numberViewed !== 1) {
        this.numberViewed = 1;
        this.initWidths();
      }
      this.initPosition();
    },
  },
  mounted() {
    // alert(this.countLi());
    try {
        this.numberElements = this.gallery.length < 4 ? this.gallery.length:4;
      }
      catch(err) {
            console.log(err);
          }
        this.initSlider();
        window.addEventListener("resize", this.initResponsive);
        document
          .querySelector(".slide-left")
          .addEventListener("click", this.slideLeft);
        document
          .querySelector(".slide-right")
          .addEventListener("click", this.slideRight);
        AOS.init();
  },
};
</script>
