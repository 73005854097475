<style scoped>

  .input-group {
    position: relative;
  }

  .input {
    border: solid 1.5px #8D7D7F;
    border-radius: 1vh !important;
    background: #ffffff21;
    backdrop-filter: blur(15vh);
    padding: .5rem;
    width: 100%;
    color: #251718;
    transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  ::placeholder {
    font-size: small;
    padding-left: .6rem;
    color: #8D7D7F;
  }
  input[type="date"]::placeholder {
    font-size: small;
    padding-left: .6rem;
    color: #8D7D7F !important;
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(50%); 
    font-size: small;

  }


  input[type="date"] {
    font-size: 0;
    padding-left: 1rem;
    color: #8D7D7F; 
  }
  #dateInput {
    position: relative;
    z-index: 1;
    /* Other input styles */
  }
  
  #dateInputLabel {
    position: absolute;
        top: 0;
        left: 0;
        padding: .5rem;
        z-index: 2;
        color: #8D7D7F;
        padding-left: 1rem;
  }
  .input:focus,
  input:valid {
    outline: none;
    border: 1.5px solid #E3086C;
    background: #fff;
  }

  .input:focus~label,
  input:valid~label {
    transform: translateY(-50%) scale(0.8);
    background-color: #e3086c;
    backdrop-filter: blur(15vh);
    padding: 0 .2em;
    color: #ffffff;
  }

  input:valid~.cbx {
    background-color: #ffffff00;
    transform: none;

  }

  .reset {
    padding: .5rem;
    background-color: #D9D9D9;
    border: none;
    border-radius: 5vh;
    line-height: 0;
    height: fit-content;
  }

  canvas {
    /*prevent interaction with the canvas*/
    pointer-events: none;
  }

  .checkbox-wrapper-46 input[type="checkbox"] {
    display: none;
    visibility: hidden;
  }

  .checkbox-wrapper-46 .cbx {
    margin: auto;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
  }

  .checkbox-wrapper-46 .cbx span {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
  }

  .checkbox-wrapper-46 .cbx span:first-child {
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    transform: scale(1);
    vertical-align: middle;
    border: 1px solid #9098a9;
    transition: all 0.2s ease;
  }

  .checkbox-wrapper-46 .cbx span:first-child svg {
    position: absolute;
    top: 3px;
    left: 2px;
    fill: none;
    stroke: #ffffff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
  }

  .checkbox-wrapper-46 .cbx span:first-child:before {
    content: "";
    width: 100%;
    height: 100%;
    background: #E3086C;
    display: block;
    transform: scale(0);
    opacity: 1;
    border-radius: 50%;
  }

  .checkbox-wrapper-46 .cbx span:last-child {
    padding-left: 8px;
  }

  .checkbox-wrapper-46 .cbx:hover span:first-child {
    border-color: #E3086C;
  }

  .checkbox-wrapper-46 .inp-cbx:checked+.cbx span:first-child {
    background: #E3086C;
    border-color: #E3086C;
    animation: wave-46 0.4s ease;
  }

  .checkbox-wrapper-46 .inp-cbx:checked+.cbx span:first-child svg {
    stroke-dashoffset: 0;
  }

  .checkbox-wrapper-46 .inp-cbx:checked+.cbx span:first-child:before {
    transform: scale(3.5);
    opacity: 0;
    transition: all 0.6s ease;
  }

  @keyframes wave-46 {
    50% {
      transform: scale(0.9);
    }
  }

  .form-control {
    background: #ffffff21 !important;
    border: solid 1.5px #8D7D7F !important;
    backdrop-filter: blur(15vh);
    font-size: smaller !important;
    border-radius: 1vh !important;
    padding: .5rem 1rem !important;
    min-width: 120px;
    color: #8D7D7F !important;
  }

  .form-control:focus {
    border-color: #E3086C !important;
  }

  .select-wrap iconify-icon {

    color: #8D7D7F;
  }



.submitbtn {
  color: #fff;
  background-image: linear-gradient(140deg, #E3086C -20%, #EBBB7A 130%);
  text-decoration: none;
  padding: .2rem 1.2rem;
  /* font-family: 'Lobster Two'; */
  font-size: larger;
  border-radius: 5vh;
  transition: 0.3s;
  border: 1px solid transparent;
}

.submitbtn:hover {
  background-image: linear-gradient(160deg, #E3086C -50%, #EBBB7A 100%);
  box-shadow: 0 0 30px #f3c17ac7;
  border: 1px solid white;
}
    </style>
<template>
    
     <div class="site-section overlay aos-init aos-animate"
      style="background-image: url('../img/register.png'); background-position: 10% 50%; background-size: cover;  background-repeat: no-repeat;" id="quick-register">
      <div class="container">
        <div class="row align-items-center justify-content-center text-center quick-register">
          <div class="col-md-6">
            <form class="register" @submit.prevent="submitForm">
              <h2 class="text-start text-white display-6"> Quick Registration </h2>
              <div class="row py-2">
                <div class="col-12">
                  <div class="input-group">
                    <input required  type="text" v-model="form.name"  autocomplete="off" class="input" placeholder="Full Name">
                    <span v-if="v$.form.name.$error" class="error"> Name is a required </span>
                    
                  </div>
                </div>
              </div>
              <div class="row py-2">
                <div class="col-12">
                  <div class="select-wrap">
                    <!-- <Icon icon="iconamoon:arrow-down-2-light"/> -->
                    <select @change="findage" required class="form-control " v-model="form.gender">
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>

                    </select>
                    <span v-if="v$.form.gender.$error" class="error"> Gender is a required </span>
                  </div>
                  
                </div>
              </div>
              <div class="row py-2">
                <div class="col-12">
                  <div class="input-group">
                    <input @input="checkMail" id="email" required type="email" v-model="form.emails"  autocomplete="off" class="input" placeholder="Email">
                    <!-- <span class="error">{{ errors.email }}</span> -->
                    <span v-if="v$.form.emails.$error" class="error"> 
                      {{ v$.form.emails.$errors[0].$message == "Value is required" ? "Email is required":v$.form.emails.$errors[0].$message }} 
                    </span>
                    <span v-if="!mailcheck" class="error mt-1"> 
                      {{ "This email address is already registered in our system.Please use a different email address"}} 
                    </span>
                  </div>
                </div>
              </div>
              <div class="row py-2">
                <div class="col-6">
                  <!-- @click="doboOnClick()" -->
                  <div @click="doboOnClick()" class="input-group position-relative">
                    <!-- <label  class="pl-4"  for="" id="dateInputLabel">Date of birth</label> -->
                    <VueDatePicker @change="doboOnClick()" v-model="form.date" :min-date="min_date"   :max-date="max_date"  class="dp__theme_dark date" format="dd-MM-yyyy" id="dateInput" placeholder="Date of birth"  ></VueDatePicker>

                    <!-- <label   for="" id="dateInputLabel">Date of birth</label>
                    <input @change="doboOnClick()" required type="date"  v-model="date" autocomplete="off" class="input w-100" id="dateInput" placeholder="Date of birth"> -->
                  </div>
                  <!-- <span class="error">{{ errors.date }}</span> -->
                  <span v-if="v$.form.date.$error" class="error"> Date of Birth is a required </span>
                </div>
                <div class="col-6">
                  <div class="select-wrap">
                    <!-- <Icon icon="iconamoon:arrow-down-2-light"/> -->
                    <select @change="statesApi" class="form-control"  id="" v-model="form.country">
                      <option value="">Select Country</option>
                      <option   v-for="item in country" :key="country" :value="item?.title">
                        {{ item?.title }}
                      </option>

                    </select>
                  </div>
                </div>
                
              </div>
              <div class="row py-2">
                <div class="col-6">
                  <div class="select-wrap">
                    <!-- <Icon icon="iconamoon:arrow-down-2-light"/> -->
                    <select @change="districtApi" class="form-control "  id="" v-model="form.state">
                      <option value="">Select State</option>
                      <option   v-for="item in states" :key="states" :value="item?.title">
                        {{ item?.title }}
                      </option>

                    </select>
                  </div>
                </div>
                <div class="col-6">
                  <div class="select-wrap">
                    <!-- <Icon icon="iconamoon:arrow-down-2-light"/> -->
                    <select class="form-control "  id="" v-model="form.district" >
                      <option value="">Select District</option>
                      <option   v-for="item in districts" :key="districts" :value="item?.title">
                        {{ item?.title }}
                      </option>

                    </select>
                  </div>
                </div>
              </div>
              <div class="row py-2">
                <div class="col-6">
                  <div class="select-wrap">
                    <!-- <Icon icon="iconamoon:arrow-down-2-light"/> -->
                    <select required class="form-control "  id="" v-model="form.marital_status">
                      <option value="">Select Marital Status</option>
                      <option  v-for="martial in martialOptions"  :value="martial?.title">
                        {{ martial?.title }}
                      </option>
                    </select>
                  </div>
                  <span v-if="v$.form.marital_status.$error" class="error"> 
                    Marital Status is required
                  </span>
                </div>
                <div class="col-6">
                  <div class="select-wrap">
                    <input required type="number"  autocomplete="off" class="input" placeholder="Height in Cm" v-model="form.height" >
                  </div>
                  <span v-if="v$.form.height.$error" class="error"> 
                    Height is required
                  </span>
                </div>
              </div>
              <div class="row py-2">
                <div class="col-6">
                  <div class="input-group">
                    <input required type="number"  autocomplete="off" class="input" placeholder="Phone Number" v-model="form.phone" >
                    <!-- <span class="error">{{ errors.phone }}</span> -->
                    <!-- <span v-if="v$.phone.$error" class="error"> Phone Number is a required </span> -->
                    <span v-if="v$.form.phone.$error" class="error"> {{ v$.form.phone.$errors[0].$message == "Value is required" ? "Phone Number is required":"Please enter a valid Phone number with ten digits." }} <br></span>
                  </div>
                </div>
                <div class="col-6">
                  <div class="select-wrap">
                    <input required type="number"  autocomplete="off" class="input" placeholder="Postal Code" v-model="form.post" >
                  </div>
                  <span v-if="v$.form.post.$error" class="error"> 
                    Postal Code is required
                  </span>
                </div>
              </div>
              <div class="row py-2">
                <div class="col-12">
                  <div class="input-group">
                    <input required type="password"  v-model="form.password" autocomplete="off" class="input" placeholder="Password">
                    <!-- <span class="error">{{ errors.password }}</span> -->
                    <span v-if="v$.form.password.$error" class="error"> 
                      {{ v$.form.password.$errors[0].$message == "Value is required" ? "Password is required":v$.form.password.$errors[0].$message.replace('This field','Password') }} </span>
                  </div>
                </div>
              </div>
              
              <div class="row py-2">
                <div class="col-6 d-flex justify-content-between">
                   
                  <div  id="captcha" style="height: 100%; width: 80%; background-color: #fff;">
                  </div>
                 
                  <button  @click="createCaptcha()" class="reset" type="button" >
                    <Icon icon="grommet-icons:power-reset"/></button>
                </div>
                <div class="col-6">
                  <div class="input-group">
                    <input type="text" placeholder="Captcha"   v-model="form.captcha" id="cpatchaTextBox" class="input">
                    <!-- <span class="error">{{ errors.captcha }}</span> -->
                    <span v-if="v$.form.captcha.$error" class="error"> Oops! Invalid Captcha </span>
                  </div>
                </div>
              </div>
              <div class="d-flex py-2 flex-nowrap">
                <div class="checkbox-wrapper-46 w-auto d-flex ">
                  <input type="checkbox" id="cbx-46" required v-model="form.check" value="true" class="inp-cbx" />
                  <label for="cbx-46" class="cbx"><span>
                      <svg viewBox="0 0 12 10" height="10px" width="12px">
                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                      </svg></span><span></span>
                  </label>
                 
                </div>
                
                <p class="m-0 text-start text-white">I have read and agree to the <span class="primary">Terms of Use &
                    Privacy Policy </span></p>
                   
                    
              </div>
              <!-- <span  class="error">{{ errors.check }}</span> -->
              <span v-if="v$.form.check.$error" class="error"> Please kindly accept the terms of service to proceed further. </span>
               <br v-if="v$.form.check.$error">
              
              <div class="d-block m-auto py-3">
                <div v-if="load" style="color:#e3086c;" class="spinner-border " role="status">
                  <span class="sr-only"></span>
                </div>
                <button v-if="!load" type="submit"  @click="onSubmit()"  class="submitbtn">Register</button>
              </div>
              <input type="hidden" id="captchava"  :value="captchajoin?.captchastring"  >
              <!-- <span  class="error">{{ errors.codes }}</span> -->
            </form>
          </div>
          <div class="col-6"></div>
        </div>
      </div>
    </div>
    <ErrorToast :key="error"  :message="error" @update="errorup" />
    <SuccesToast :key="success"  :message="success" @update="errorup" />
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import AOS from 'aos'
import { Icon } from '@iconify/vue';
import { useVuelidate } from '@vuelidate/core'
import { required, email,minLength, sameAs,maxLength } from '@vuelidate/validators'
import {country,states,district,mailcheck,QuickRegistration} from "@/util/Services/register"
import ErrorToast from "@/components/General/ErrorToast.vue"
import SuccesToast from "@/components/General/SuccessToast.vue"
export default {
    components: {
		Icon,VueDatePicker,ErrorToast,SuccesToast
	},
  props: {
    captchastring: {
      type: String,
      required: true,
    },
    martialOptions:{
      required:true,
    }
  },
    data() {
    return {
                v$:useVuelidate(),
                form:{
                        name: '',
                        gender:'',
                        emails:' ',
                        date: '',
                        state:'',
                        country:'',
                        district: '',
                        phone:'',
                        password:'',
                        check:'',
                        captcha:"",
                        marital_status:"",
                        height:"",
                        post:""
                },
               

                code:'',
                codes:'' ,
                max_date:'',
                min_date:'',
                min_age:18,
                country:[],
                states:[],
                districts:[],
                mailcheck:true,
                error:null,
                success:null,
                load:false
    };
},
validations(){
  return{
    form:{
                name: {required},
                gender:{required},
                emails:{required,email},
                date: {required},
                marital_status: {required},
                height: {required},
                post: {required},
                phone:{required,minLength:minLength(10),maxLength:maxLength(10)},
                password:{required,minLength:minLength(8)},
                check:{required,sameAs:sameAs(true)},
                captcha:{required,sameAs:sameAs(this.code)},
    }
   
                
  } ;
},

methods: {
  async onSubmit() {
        this.v$.$validate()
        if(!this.v$.$error && this.mailcheck == true ){
          this.load = true;
          await QuickRegistration(this.form).then(r=>{this.error = r?.response?.data.message,this.success = r?.data?.message, this.load = false})
          // console.log(this.error)
          // console.log(this.success,'success')
          if(this.success){
            this.resets()
          }
        }else if(this.mailcheck != true){
          document.getElementById("email").focus();
        }
    },
    errorup(){
      this.error =null;
      this.success = null;
    },
    findage(){
      this.min_age = 18;
      if (this.form.gender == 'male') {
        this.min_age = 21;
      }
      this.max_date = this.subtractYears(new Date(), this.min_age);
      this.form.date = this.max_date;
    },
    
    subtractYears(date, years) {
      date.setFullYear(date.getFullYear() - years);
      return date;
    },
    createCaptcha() {
      this.$emit('message-updated', false);
      setTimeout(() =>  this.Captchac(captcha), 150);
    },
    doboOnClick(){
      const dateInputLabel = document.getElementById('dateInputLabel');
      const dateInput = document.getElementById('dateInput');
      // console.log("onclick",this.code)
      if (dateInput.value) {
          dateInputLabel.style.visibility = 'hidden';
          dateInput.style.fontSize = 'small';
        } else {
          dateInputLabel.style.visibility = 'visible';
          dateInput.style.fontSize = '0';
        }
    },
    Captchac(){
      var captcha = this.captchastring;
      document.getElementById('captcha').innerHTML = "";
      var canv = document.createElement("canvas");
      canv.id = "captchaCanvas";
      canv.width = 100;
      canv.height = 50;
      var ctx = canv.getContext("2d");
      ctx.font = "25px Georgia";
      ctx.strokeText(captcha, 0, 30);
      // Storing captcha so that can validate you can save it somewhere else according to your specific requirements
      this.code =captcha;
      // console.log(this.code)
      document.getElementById("captcha").appendChild(canv); // Adds the canvas to the body element
    },
    async CountryApi(){
            await country().then(r=>{this.country = r?.data?.data})
            // console.log('country',this.country);
    },
    async statesApi(){
       await states(this.form?.country).then(r=>{this.states = r?.data?.data})
    },
    async districtApi(){
      await district(this.form?.state).then(r=>{this.districts = r?.data?.data})
      
    },
    async checkMail(){
      // console.log('email',this.form?.emails);
      if (this.form?.emails) {
        await mailcheck(this.form?.emails).then(r=>{this.mailcheck = r})
      }
      //  console.log('mailcheck',this.mailcheck);
    },
    resets(){
            // validation reset
            this.v$.$reset();
            // reset form data
            this.form.name = '';
            this.form.gender = '';
            this.form.emails = '';
            this.form.date = '';
            this.form.state = '';
            this.form.country = '';
            this.form.district = '';
            this.form.phone = '';
            this.form.password = '';
            this.form.check = '';
            this.form.captcha = "";
            this.form.marital_status = "";
            this.form.height = "";
            this.form.post = "";
            this.findage();
    }
    

   
  },mounted() {
    this.findage();
    this.min_date = this.subtractYears(new Date(), 150);
    AOS.init()
    this.createCaptcha();
    this.CountryApi();
  },
  
}
</script>
<style scoped>
.error{
 color: red;
 text-align:start !important;
 float: left !important;
}
.dp__theme_dark {
    --dp-background-color: #ffffff21;
    --dp-text-color: #8D7D7F;
    --dp-hover-color: #8D7D7F;;
    --dp-hover-text-color: #fff;
    --dp-hover-icon-color: #959595;
    --dp-primary-color: #005cb2;
    --dp-primary-disabled-color: #61a8ea;
    --dp-primary-text-color: #484848;
    --dp-secondary-color: #8D7D7F;
    --dp-border-color: #8D7D7F;
    --dp-menu-border-color: #2d2d2d;
    --dp-border-color-hover: #f50569;
    --dp-border-color-focus: #f50569;
    --dp-disabled-color: #737373;
    --dp-disabled-color-text: #d0d0d0;
    --dp-scroll-bar-background: #212121;
    --dp-scroll-bar-color: #484848;
    --dp-success-color: #00701a;
    --dp-success-color-disabled: #428f59;
    --dp-icon-color: #959595;
    --dp-danger-color: #e53935;
    --dp-marker-color: #e53935;
    --dp-tooltip-color: #3e3e3e;
    --dp-highlight-color: rgb(0 92 178 / 20%);
    --dp-range-between-dates-background-color: var(--dp-hover-color, #484848);
    --dp-range-between-dates-text-color: var(--dp-hover-text-color, #fff);
    --dp-range-between-border-color: var(--dp-hover-color, #fff);
    --dp-font-size: .9rem !important; 
    --dp-cell-padding: 10px !important;
    --dp-border-radius: 1vh !important;
    --dp-font-family: -apple-system,  sans-serif !important;;
}

</style>