<template>

        <img :src="item?.image" alt="" class="noti-img lazy">
        <div class="d-grid sta ">
            <p>{{ item?.message }}</p>
            <a :href="item?.link">View Profile</a>
        </div>

</template>
<script>
  export default {
    props: {
    item: {
      required: true,
    },
  },
  };
</script>
<style scoped src="@/assets/css/application.css" ></style>
<style scoped>
    .noti-img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 50%;
    }
    img {
        max-width: 30%;
        display: block;
    }
    .d-grid {
            justify-items: flex-start;
            margin-left: 10px;
        }
    p {
            margin: 0;
            width: 230px;
            text-align: left;
        }
    </style>
   