<template>

<div class="col-xl-2 col-lg-3 col-12 p-0">
                <div class="position-relative">
                    <div class="sidebar p-0 p-lg-2" v-bind:class="{'showbar':show}">
                        <div class="sidebarinner m-1 p-3">
                            <div class="profile p-lg-4 p-3 pt-2 pt-lg-4">
                                <img src="@/assets/img/logo.svg" alt="" class="d-none d-lg-block img-fluid pb-4">
                                <img :src="$store.getters.base_url+$store.getters.user?.profile_pic" alt="" class="profiledp">

                            </div>
                            <div class="editprofile">
                                <div class="details sta text-black">
                                    <h5>{{ $store.getters.user?.name }}</h5>
                                    <small>{{$store.getters.user?.user_ids}}</small>
                                </div>
                                <a :href="editProfile"><Icon class="iconify-icon big-icon" icon="flowbite:edit-solid"/> </a>
                            </div>
                            <hr>
                            <div class="quicklink p-3 sta">
                                <ul class="list-unstyled d-grid">
                                    <h5>Quick Links</h5>

                                    <li >
                                        <a v-bind:class="{'active':active == 'AcceptedProfile'}" :href="acceptedProfile"> 
                                            <Icon class="iconify-icon" icon="mingcute:heart-hand-fill"/>
                                            Accepted Proposals
                                        </a>
                                    </li>
                                    <li >
                                        <a v-bind:class="{'active':active == 'PendingProposal'}" :href="pendingProfile"> 
                                            <Icon class="iconify-icon" icon="hugeicons:user-love-01"/>
                                            Pending Proposals
                                        </a>
                                    </li>
                                    <li>
                                       <a v-bind:class="{'active':active == 'Shortlisted'}" :href="shortListed"> 
                                            <Icon class="iconify-icon" icon="basil:stack-solid"/> 
                                            Shortlist & More
                                        </a>
                                    </li>
                                    <li>
                                        <a v-bind:class="{'active':active == 'SavedMatches'}" :href="savedMatches"> 
                                            <Icon class="iconify-icon" icon="mage:inbox-download-fill"/> 
                                            Saved Matches
                                        </a>
                                    </li>
                                    <li>
                                        <a v-bind:class="{'active':active == 'Astrology'}" :href="astrology"> 
                                            <Icon class="iconify-icon" icon="mage:stars-c-fill"/> Astrology
                                        </a>
                                    </li>
                                    <li>
                                        <a v-bind:class="{'active':active == 'PartnerPreference'}" :href="partner_preference"> 
                                            <Icon class="iconify-icon" icon="solar:checklist-minimalistic-bold"/> 
                                            Partner Preference
                                        </a>
                                    </li>
                                    <li>
                                        <a v-bind:class="{'active':active == 'AssistedService'}" :href="assistedService"> 
                                            <Icon class="iconify-icon" icon="wpf:assistant"/>
                                            Assisted Service
                                        </a>
                                    </li>
                                    <li>
                                        <a v-bind:class="{'active':active == 'NeedHelp'}" :href="needHelp"> 
                                            <Icon class="iconify-icon" icon="tabler:help-square-rounded-filled"/> 
                                            Need Help?
                                        </a>
                                    </li>
                                </ul>
                                <button @click="LogOut" class="submitbtn">Logout</button>
                            </div>
                            <hr>
                            <p>© Copyright GOOD LIFE. All Rights Reserved Designed with 
                               <Icon class="iconify-icon" icon="ph:heart-fill"/> by Kenland IT Solutions
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <ErrorToast :key="error"  :message="error" @update="errorup" />
</template>
<script>
  import AOS from 'aos'
  import { Icon } from "@iconify/vue";
import { logout } from '@/util/Services/Authenticated/Logout';
import ErrorToast from "@/components/General/ErrorToast.vue"
  export default {
    name: "UserSideNav",
    components: {
      Icon,ErrorToast
    },
    props: {
    show: {
      type: Boolean,
      required: true,
    },
    active: {
      required: true,
    },
  },
    data() {
      return {
        editProfile:"/user/profile_edit/0",
        acceptedProfile:"/user/accepted-proposals",
        shortListed:"/user/shortlist",
        savedMatches:"/user/saved-matches",
        astrology:"/user/astrology",
        assistedService:"/user/home",
        needHelp:"/user/home",
        error:null,
        partner_preference :'/user/profile_edit/1',
        pendingProfile:"/user/pending-proposals"
      }
    },
    methods: {
      sidenav() {
        this.show = !this.show;
      },
     async LogOut(){
       await logout().then(r=>{this.error = r?.response?.data?.message})
        if (!this.error) {
            this.$router.push({path: '/home'});
        }
      },
      errorup(){
        this.error =null;
      },
    },
    mounted() {
      AOS.init()  
      // console.log(this.$store)
    },
  };
  </script>
  <style scoped src="@/assets/css/application.css" ></style>
<style scoped >
     .big-icon{
         font-size: 2.5rem !important;
     }
</style>