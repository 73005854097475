<template>
  <div class="site-section aos-init bg-pale position-relative" data-aos="fade">
    <img src="@/assets/img/leftabstract.png" alt="" class="abstract start-0" />
    <img src="@/assets/img/rightabstract.png" alt="" class="abstract end-0" />
    <div class="container">
      <div class="row flex-wrap">
        <div class="col-lg-4 clients">
          <h5>Over</h5>
          <h1 class="display-1">
            <span class="counter" data-target="1000">{{ currentCount }}</span
            >+
          </h1>
          <h5>Happy Couples</h5>
        </div>
        <div class="col-12 col-lg-8 d-flex flex-wrap justify-content-evenly">
          <div class="card col-md-6 col-lg-4 col-xl-4 p-2">
            <div class="card-body">
              <img src="@/assets/img/arcticons_moto-secure.svg" alt="" />
              <h3>
                Safety <br />
                & Security
              </h3>
              <p>
                Your safety is paramount. Goodlife employs cutting-edge security
                features to ensure your online journey is worry-free
              </p>
            </div>
          </div>
          <div class="card col-md-6 col-lg-4 col-xl-4 p-2">
            <div class="card-body">
              <img
                src="@/assets/img/solar_diploma-verified-broken.svg"
                alt=""
              />
              <h3>
                Verified <br />
                Profiles
              </h3>
              <p>
                Trust is built on authenticity. Our personal visit verification
                ensures that every profile you encounter is genuine, fostering
                real connections.
              </p>
            </div>
          </div>
          <div class="card col-md-6 col-lg-4 col-xl-4 p-2">
            <div class="card-body">
              <img src="@/assets/img/image 3.svg" alt="" />
              <h3>
                Smart <br />
                Matchmaking
              </h3>
              <p>
                Let us simplify your search. Our intelligent matching system
                uses smart algorithms to connect you with compatible partner,
                making your quest for love efficient and enjoyable.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      currentCount: 0,
      targetCount: 1000,
      duration: 2000, // Animation duration in milliseconds
      intervalTime: 20, // Interval time for updating the count
      stepCount: 0,
      timer: null,
      finalValue: 1000,
      initialValue: 0,
    };
  },
  // props: {
  //   initialValue: {
  //     type: Number,
  //     required: true
  //   },
  //   finalValue: {
  //     type: Number,
  //     required: true
  //   }
  // },
  mounted() {
    this.startAnimation();
  },
  methods: {
    startAnimation() {
      // this.currentCount = this.initialValue;
      // this.targetCount = this.finalValue;
      this.stepCount = Math.ceil(
        this.targetCount / (this.duration / this.intervalTime)
      );
      this.timer = setInterval(this.updateCount, this.intervalTime);
    },
    updateCount() {
      if (this.currentCount < this.targetCount) {
        this.currentCount += this.stepCount;
        if (this.currentCount > this.targetCount) {
          this.currentCount = this.targetCount;
        }
      } else {
        clearInterval(this.timer);
      }
    },
  },
};
</script>
