<template>
     <div class="ps-1 pt-lg-0 ps-lg-0 p-lg-3">
                            <div class="p-3 happycoupe">
                                <img src="@/assets/img/pin.png" alt="" class="img-fluid lazy">
                                <h5>Our Happy Couples</h5>
                                <div class="d-flex">
                                    <div id="myCarousel" class="carousel slide mb-6" data-bs-ride="carousel">

                                        <div class="carousel-inner text-start" >
                                            <div class="carousel-item " :class="{'active':index == 0}" v-for="(item,index) in successStory" >
                                                  <Card :item = "item"/>
                                            </div>
                                        </div>
                                        <div class="carousel-indicators">
                                            <button :class="{'active':index == 0}" v-for="(item,index) in successStory" type="button" data-bs-target="#myCarousel" :data-bs-slide-to="index" class="active" aria-current="true" :aria-label="'Slide '+index+1 "></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
</template>
<style scoped src="@/assets/css/application.css" />
<script>
import Card from "@/components/Authenticated/Matches/CoupleCard.vue"
export default {
    components: {
        Card,
    },
    props:{
        successStory:{
            require:true
        }
    }
    
}
</script>