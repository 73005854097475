import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from './modules/auth'
import auth_quick_search from './modules/auth-quick-search'
export default createStore({
  plugins:[
    createPersistedState()
  ],
  modules:{
      auth,
      auth_quick_search
  }
})
