<template>
    <body data-aos-easing="slide" data-aos-duration="800" data-aos-delay="0" class="responsivewhite">
        <div class="container-fluid p-0">
        <div class="d-flex flex-wrap">
            <div class="col-lg-12 col-xl-12 col-12 p-0">
                <div class="d-flex flex-wrap ">
                   <MainNav />
                    <div class="col-12 p-0">
                        <main class="p-0 p-lg-2 pt-5 mt-4 mt-lg-0 pt-lg-0">
                            <div class="mainbody d-flex flex-wrap ">
                                <SideNav :percentage="parseInt(data?.percentage)" :links="true" />
                                <div class="col-lg-9 col-12">
                                    <div class="mainbody2 registerpage p-3 m-2 pb-5 mb-4 mb-lg-0">
                                        <accordion>
                                            <input type="radio" id="panel1" name="accordion" hidden  value="panel1" v-model="accordion">
                                            <ImagePanel1 :key="data" :data="data?.data" @submit="ApiCall" />
                                        </accordion>
                                        <accordion>
                                            <input type="radio" id="panel2" name="accordion" hidden value="panel2" v-model="accordion" />
                                            <ImagePanel2 :key="data" :data="data?.data" @submit="ApiCall" />
                                        </accordion>

                                        <accordion>
                                            <input type="radio" id="panel3" name="accordion" hidden value="panel3" v-model="accordion" />
                                            <ImagePanel3 :key="data" :data="data?.data"  @submit="ApiCall"/>
                                        </accordion>

                                        <accordion>
                                            <input type="radio" id="panel4" name="accordion" hidden value="panel4" v-model="accordion" />
                                            <ImagePanel4 :key="data" :data="data?.data"  @submit="ApiCall"/>
                                        </accordion>
                                      

                                    </div>

                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>

        </div>
        <MobileNav />
    </div>
  </body>
</template>
<script>
import AOS from 'aos'
import { Icon } from "@iconify/vue";
import MainNav from "@/components/Authenticated/UserMainNav.vue";
import SideNav from "@/components/Authenticated/Edit/SideNav.vue";
import MobileNav from  "@/components/Authenticated/MobileNav.vue"
import SuccesToast from "@/components/General/SuccessToast.vue"
import { userData } from "@/util/Services/Authenticated/edit"
import ImagePanel1 from '@/components/Authenticated/Edit/ImagePanel1.vue';
import ImagePanel2 from '@/components/Authenticated/Edit/ImagePanel2.vue';
import ImagePanel3 from '@/components/Authenticated/Edit/ImagePanel3.vue';
import ImagePanel4 from '@/components/Authenticated/Edit/ImagePanel4.vue';
import { ref } from 'vue';

export default {
  name: "UserHomeView",
  props: ['id'],
  setup() {
          
            const data              = ref([]);
            const accordion                  = ref("panel1");
            async function ApiCall(){
                 await userData().then(r=>{this.data = r?.data});
             }
            return {
                data,accordion,ApiCall
            }
        },
  components: {
    MainNav,
    SideNav,
    Icon,
    MobileNav,
    SuccesToast,
    ImagePanel1,
    ImagePanel2,
    ImagePanel3,
    ImagePanel4,
  },

  mounted(){
    
    AOS.init()  
  },
  created(){
    this.ApiCall();
  }
}
    
</script>
   <style scoped src="@/assets/css/application.css" />
   <style scoped src="@/assets/css/components/Authenticated/tab_card.css"/>
   
    