<template>
    <img :src="$store.getters.base_url+item?.image" alt="" class="img-fluid lazy">
    <h6>{{ item?.heading }}</h6>
    <p> {{ item?.namr }}
    </p>
</template>
<script>
export default {
    props: {
        item: {
            required: true,
        }
    }
}
</script>
<style scoped src="@/assets/css/components/Authenticated/match-CoupleCard.css" />