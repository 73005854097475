<template>
 <label class="fieldlabels">{{ label }}:</label>

        <select v-model="selected" :key="error" :class="{'errors':error}" class="form-select" @change="updated" aria-label="Default select example">
            <option v-for="(item) in option"  :value="item?.value" >{{ item?.option }}</option>
           
        </select>
        <span class="error">{{ error }}</span>
</template>
<script>

export default {
    setup(){
        // const emit = defineEmits(['update:Value'])
    },
  props: {
    option: {
      required: true,
    },
    value: {
      required: true,
    },
    label: {
      required: true,
    },
    error: {},
  },
  data(){
    return{
        selected:this.value
    }
  },
  methods:{
    updated(){
      // this.$router.push('/user/');
        this.$emit('on_update', this.selected);
    }
  },
  mounted(){
    
  }
};
</script>
<style scoped src="@/assets/css/application.css" />
<style scoped>
label{
  padding-top: 1rem;
}
.errors {
      border: 1px solid red !important;
    }
.error{
  margin-top: 10px;
  color: red;
}
</style>