<template>
    <expansion-panel>
       <label for="panel4" class="expansion-panel-header"><h6 class="fs-title">Approved Images</h6></label>
           <div class="expansion-panel-content">
              <fieldset>
                <form @submit.prevent="submit">
                    <div class="form-card">
                        <div id="app" class="container my-3">
                            <div class="row">
                              <div class="col-md-12 row"> 
                                        <h5  class="mt-3">Approved Images</h5>
                                        <Spinner v-if="loading"  />
                                        <div v-if="pending.length && !loading"  class="col-md-12 row">

                                            <div class="col-md-2 col-sm-6 p-2 d-flex justify-content-center" v-for="item in pending" >
                                                <div class="containerimg ">
                                                    <img :src="base_url+item?.image" class="img-fluid  shadow-lg lazy" />
                                                    <button @click="removePending(item?.id)" class="button">X</button>
                                                </div>  
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>                    
              </fieldset>
           </div>
       </expansion-panel>
       <ErrorToast :key="error"  :message="error" @update="errorup" />
       <SuccesToast :key="success"  :message="success" @update="errorup" />
</template>
<script>
import Multiselect from 'vue-multiselect';
import ErrorToast from "@/components/General/ErrorToast.vue";
import SuccesToast from "@/components/General/SuccessToast.vue";
import { approvedImages,removeApprovedImages } from '@/util/Services/Authenticated/edit';
import Spinner from '../Spinner.vue';
export default{
   components:{
       Multiselect,ErrorToast,SuccesToast,Spinner
   },
   data(){
       return{
           loading:false,
           error:null,
           success:null,
           pending:[],
       }
   },
   props:{
     data:{required:true}
   },
   methods:{
            async getPending(){
                this.loading = true;
                await approvedImages().then(r=>{this.pending = r?.data?.data ? r?.data?.data : [],this.base_url = r?.data?.base_url})
                this.loading = false;
            },
            errorup(){
                this.error =null;
                this.success = null;
            },
            removePending(id){
                this.$swal.fire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#E3086C",
                                cancelButtonColor: "black",
                                confirmButtonText: "Yes, delete it!"
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        removeApprovedImages(id).then(r=>{this.error = r?.response?.data?.message,this.success = r?.data?.message,this.getPending()});
                                        
                                    }
                                });
            }
   
 },
 mounted(){
    this.getPending();
 }
   
}
</script>
<style scoped src="@/assets/css/application.css" />
<style scoped src="@/assets/css/components/Authenticated/edit.css"></style>
<style scoped>
img{
    height: 109px !important;
   width: 128.5px !important;
   object-fit: cover;
   object-position: 10% 15%;
}
   .containerimg {
  position: relative;
  object-fit: fill;
  
}

.containerimg .button {
  position: absolute;
  top: 0%;
  left: 100%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: red;
  color: white;
  font-size: 10px;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
}
.spinner-border{
    color: #af0b55;
}

</style>
