<template>
  
      <div class="container">
        <div class="row align-items-center justify-content-center text-center mt-5">
          <div class="col-md-12 mt-5 mb-5">
            
            <div class="form-search-wrap p-3 p-md-4 aos-init aos-animate  shadow-lg"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <form @submit.prevent="submitForm">
                <div
                  class="d-grid d-lg-flex gap-3 gap-lg-5 justify-lg-content-evenly align-items-center text-start"
                >
                  <div class="w-auto">
                    <label for="I’m looking for">I’m looking for</label>
                    <div class="select-wrap">
                      <Icon class="icon" icon="iconamoon:arrow-down-2-light" />
                      <select v-model="form.gender" class="form-control" name="" id="">
                        <option value=null >Select any</option>
                        <option  v-for="item in this?.slide?.gender" :value="item">
                          {{ item }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="w-auto">
                    <label for="Martial Status">Martial Status</label>
  
                    <div class="select-wrap">
                      <Icon class="icon" icon="iconamoon:arrow-down-2-light" />
                      <select v-model="form.marital_status" class="form-control" name="">
                        <option  value=null >Select any</option>
                        <option  v-for="martial in martialOptions" :key="martialOptions" :value="martial?.title">
                          {{ martial?.title }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="w-auto">
                    <label for="I’m looking for">Religion</label>
                    <div class="select-wrap">
                      <Icon class="icon" icon="iconamoon:arrow-down-2-light" />
                      <select @change="castLoad" v-model="form.religion" class="form-control" name="" id="">
                        <option   value=null >Select Religion</option>
                        <option  v-for="religion in religionOptions" :value="religion?.title">
                          {{ religion?.title }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="w-auto">
                    <label for="Caste">Caste</label>
  
                    <div class="select-wrap">
                      <Icon class="icon" icon="iconamoon:arrow-down-2-light" />
                      <select v-model="form.cast" class="form-control" name="" id="">
                        <option value=null>Select Caste</option>
                        <option v-for="item in castOptions" :value="item?.title">{{ item?.title }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="w-auto">
                    <input
                      type="submit"
                      class="btn btn-primary btn-block rounded"
                      value="Search"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
       
      </div>
      

  </template>
  <script>
  import AOS from 'aos'
  import { Icon } from "@iconify/vue";
  import { martial_status,religion,cast,HomeSearch} from '@/util/Services/home';
import { compile } from 'vue';

 
  export default {
    props: {
      slide: {
        type: Array,
        required: true,
      },
      formdata:{
        required: true,
      }
    },
    data(){
       return{

        form:{
            marital_status:null,
            gender:null,
            religion:null,
            cast:null,
        },
        martialOptions:[],
        religionOptions:[],
        castOptions:[],
        Result:[],
        error:"",
       }
    },
    components: {
      Icon,
    },
    methods:{
      async martialLoad(){
            await martial_status().then(r => { this.martialOptions = r?.data?.data });
            // console.log(this.martialOptions)
          },
      async religionLoad(){
            await religion().then(r => { this.religionOptions = r?.data?.data });
            // console.log(this.religionOptions)
          },
      async castLoad(){
            await cast(this.form?.religion).then(r => { this.castOptions = r?.data?.data });
            // console.log(this.castOptions)
          },
      submitForm(){
                 this.$emit('from-submit', this.form);
          },
         
    },
    mounted() {
      this.martialLoad();
      this.religionLoad();
      // console.log(this?.formdata?.martial_status);
      this.form.marital_status = this?.formdata?.martial_status;
      this.form.gender = this?.formdata?.gender;
      this.form.religion = this?.formdata?.religion;
      this.castLoad();
      this.form.cast = this?.formdata?.cast;
      this.submitForm()
     
      AOS.init()  
    },
  };
  </script>
<style scoped>
.form-search-wrap {
    /* background: #6d6c6ceb; */
    border-radius: 2vh;
    backdrop-filter: blur(15vh);
}
 </style>
  