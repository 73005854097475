<template>
     <div class="contact_info_sec">
                                  <div class="d-flex info_single py-3 sta">
                                    <Icon class="big-icon" icon="fa-solid:address-card"/>
                                      <p class=" m-0">54, Padma Nagar, East Fort Thiruvananthapuram - 695 023. Kerala, India</p>
                                  </div>
                                  <div class="d-flex info_single py-3 ">
                                    <Icon class="big-icon" icon="ic:round-phone-in-talk"/>
                                      <a href="#">
                                        0471 7122222,8893099999</a>
                                  </div>
                                  <div class="d-flex info_single py-3 ">
                                    <Icon class="big-icon" icon="material-symbols-light:mail"/>
                                      <a href="#">support@goodlifematrimony.com</a>
                                  </div>
                                  
                                  <div class="socil_item_inner py-3 d-flex">
                                    
                                     
                                      <a href="#" class="pl-0 pr-3"><Icon class="big-icon" icon="eva:facebook-fill"/></a>
                            
                                    <a href="#" class="pl-3 pr-3"><Icon class="big-icon" icon="mage:x"/></a>
                            
                                    <a href="#" class="pl-3 pr-3"><Icon class="big-icon" icon="formkit:youtube"/></a>
                            
                                    <a href="#" class="pl-3 pr-3"><Icon class="big-icon" icon="lets-icons:insta"/></a>
                            
                                    <a href="#" class="pl-3 pr-3"><Icon class="big-icon" icon="ri:linkedin-fill"/></a>
                                    
                                 </div>
                              </div>
    </template>
     <script > 
     import { Icon } from '@iconify/vue';
     export default {
        components: {
            Icon
        },
     
     }
     </script>
     <style scoped>
     .big-icon {
        font-size: 28px;
        margin-right:20px;
        color:#251718;
     }
     p{
        color:#251718;
     }

     
     </style>