<template>
    <div class="position-relative d-block d-lg-none">
            <nav class="p-2 fixed-bottom w-100 bottom-0 left-0">
                <div class="navbar">

                    <a  v-bind:class="{'active':active == 'Home'}" :href="homeLink" >
                        <Icon class="iconify-icon" icon="solar:home-2-broken" />
                        <span>Home</span></a>

                    <a v-bind:class="{'active':active == 'Match'}" :href="matchLink">
                        <img src="@/assets/img/matchesicon.svg" alt="" class="img-fluid">
                        <img src="@/assets/img/matchesicon white.svg" alt="" class="img-fluid">
                        <span>Matches</span> 
                    </a>

                    <a v-bind:class="{'active':active == 'Search'}" :href="searchLink">
                        <Icon class="iconify-icon" icon="iconamoon:search"/>
                        <span>Search</span>
                    </a>

                    <a v-bind:class="{'active':active == 'Inbox'}" :href="inboxLink">
                        <Icon class="iconify-icon" icon="solar:chat-dots-broken" />
                        <span>Inbox</span> 
                    </a>

                    <a v-bind:class="{'active':active == 'Premium'}" :href="premiumLink">
                        <Icon class="iconify-icon" icon="solar:crown-line-broken" />
                        <span>Premium</span> 
                    </a>

                </div>
            </nav>
        </div>
</template>
<script>
  import AOS from 'aos'
  import { Icon } from "@iconify/vue";
  export default {
    name: "UserMobileNav",
    components: {
      Icon,
    },
    props: {
    active: {
      required: true,
    },
  },
    data() {
      return {
        homeLink:"/user/home",
        matchLink:"/user/matches",
        searchLink:"/user/search",
        inboxLink:"/user/inbox",
        premiumLink:"/user/home",
      };
    },
    methods: {
      sidenav() {
        this.$emit('nav-show', false);
      },
    },
    mounted() {
      AOS.init()  
    },
  };
  </script>
<style scoped src="@/assets/css/application.css" ></style>