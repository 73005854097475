<template>
    <expansion-panel>
       <label for="panel2" class="expansion-panel-header"><h6 class="fs-title">Upload images</h6></label>
           <div class="expansion-panel-content">
               <fieldset>
              
                   <div class="form-card">

                       <div id="app" class="container my-3">
                           <div class="row">
                         
                           <div class="col-md-12 row">
                                <h5>Upload images</h5>
                                <div class="col-md-5 d-flex align-items-center">
                                   <div class="form-group text-start ">
                                       <label for="my-file">Select Image</label>
                                       <input :key="reload" type="file" accept="image/png, image/jpeg, image/jpg" multiple="multiple" @change="previewMultiImage" class="form-control-file" id="my_file">
                                   </div>
                                </div>
                                <div class="col-md-7 row">
                                       <div class=" p-2 mt-3 d-flex"  >
                                           <template v-if="preview_list.length" >
                                               <div v-for="item, index in preview_list" :key="index" class="containerimg m-1">
                                                
                                                   <img :src="item" class="img-fluid  shadow-lg" />
                                                   <button @click="remove(index)" class="button">X</button>
                                               </div>
                                           </template>
                                       </div>
                                </div>
                                <div class="col-md-12 d-flex justify-content-center">
                                    <div v-if="!loading" > 
                                        <input v-if="preview_list.length" type="submit" @click="submit" name="next" class="next action-button" value="Upload" /> 
                                    </div>
                                    <div v-else class="spinner-border " role="status"></div>
                                       
                                </div>
                                <h5 v-if="pending.length" class="mt-3">Pending For Approvel</h5>
                                  <div v-if="pending.length"  class="col-md-12 row">

                                      <div class="col-md-2 col-sm-6 p-2 d-flex justify-content-center" v-for="item in pending" >
                                        <div class="containerimg ">
                                            <img :src="base_url+item?.image" class="img-fluid  shadow-lg lazy" />
                                            <button @click="removePending(item?.id)" class="button">X</button>
                                        </div>
                                            
                                      </div>

                                  </div>
                            </div>
                             
                           </div>
                       </div>
                   </div>
                  
                           
               </fieldset>
           </div>
       </expansion-panel>
       <ErrorToast :key="error"  :message="error" @update="errorup" />
       <SuccesToast :key="success"  :message="success" @update="errorup" />
</template>
<script>
import Multiselect from 'vue-multiselect';
import ErrorToast from "@/components/General/ErrorToast.vue";
import SuccesToast from "@/components/General/SuccessToast.vue";
import { otherImage,pendingImages,removeImages } from '@/util/Services/Authenticated/edit';
 
export default{
   components:{
       Multiselect,ErrorToast,SuccesToast
   },
   data(){
       return{
           preview_list: [],
           image_list: [],
           reload:false,
           loading:false,
           error:null,
           success:null,
           pending:[],
           base_url:null,
       }
   },
   props:{
     data:{required:true}
   },
   methods:{
            previewMultiImage: function(event) {
                var input = event.target;
                var count = input.files.length;
                var index = 0;
                if (input.files) {
                    while(count --) {
                        var reader = new FileReader();
                        const arr = ['jpeg','png','jpg'];
                        const substr = input.files[index].type;
                        const substr1 = substr.split('/')
                        const subArr = arr.some(str => str.includes(substr1[1]));
                      
                        if (subArr) {
                            reader.onload = (e) => {
                                this.preview_list.push(e.target.result);
                            }
                            this.image_list.push(input.files[index]);
                            reader.readAsDataURL(input.files[index]);
                            index ++;
                        } else {
                            this.$swal.fire({
                                                icon: "error",
                                                title: "Oops...",
                                                text: "Only upload JPG, JPEG, or PNG files.!",
                                            });
                        }
                    }
                }
                this.reload = !this.reload;
            },
            remove(index){
                    this.preview_list.splice(index, 1);
                    this.image_list.splice(index, 1);
                    this.reload = !this.reload;
            },
            async submit(){
                this.loading = true;
                await otherImage(this.image_list).then(r=>{this.error = r?.response?.data?.message,this.success = r?.data?.message,this.loading = false,this.getPending()});
                if (this.success) {
                    this.preview_list = [];
                    this.image_list = [];
                    this.reload = !this.reload;
                }
            },
            errorup(){
                this.error =null;
                this.success = null;
            },
            async getPending(){
                await pendingImages().then(r=>{this.pending = r?.data?.data ? r?.data?.data : [],this.base_url = r?.data?.base_url})
                console.log(this.pending)
            },
            removePending(id){
                this.$swal.fire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#E3086C",
                                cancelButtonColor: "black",
                                confirmButtonText: "Yes, delete it!"
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        removeImages(id).then(r=>{this.error = r?.response?.data?.message,this.success = r?.data?.message,this.getPending()});
                                        
                                    }
                                });
            }
            
 },
 mounted(){
    this.getPending();
 }
}
</script>
<style scoped src="@/assets/css/application.css" />
<style scoped src="@/assets/css/components/Authenticated/edit.css"></style>
<style scoped>
.preview{
    height: 109px !important;
    width:  128.5px !important;
}
img{
   height: 109px !important;
   width: 128.5px !important;
   object-fit: cover;
   object-position: 10% 15%;
}

.containerimg {
  position: relative;
  height: 109px !important;
  width: 128.5px !important;
}

.containerimg img {
    height: 109px !important;
    width: 128.5px !important;
}

.containerimg .button {
  position: absolute;
  top: 0%;
  left: 100%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: red;
  color: white;
  font-size: 10px;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
}
.spinner-border{
    color: #af0b55;
}

</style>
