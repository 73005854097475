<template>
     <div id="myCarousel" class="carousel position-relative slide mb-6"
                                        data-bs-ride="carousel">

                                        <div class="carousel-inner">
                                            <div class="carousel-item " v-for="(item, index) in images" v-bind:class="{'active':index==0}" :key="index">
                                                <img :src="base_url+item?.image" alt="" class="img-fluid lazy">

                                            </div>
                                        </div>
                                        <div class="carousel-indicators justify-content-between">
                                            <button type="button" data-bs-target="#myCarousel" :data-bs-slide-to=index v-for="(item, index) in images" v-bind:class="{'active':index==0}" :key="index" >
                                                <img :src="base_url+item?.image" alt="" class="thumb lazy">
                                            </button>
                                           
                                        </div>

                                        <button class="carousel-control-prev" type="button" data-bs-target="#myCarousel"
                                            data-bs-slide="prev">
                                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span class="visually-hidden">Previous</span>
                                        </button>
                                        <button class="carousel-control-next" type="button" data-bs-target="#myCarousel"
                                            data-bs-slide="next">
                                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span class="visually-hidden">Next</span>
                                        </button>
                                    </div>
</template>
<script>
 export default {
    props: {
              images: {required: true},
              base_url:{required: true}
    },
    data() {return {count:-1}},
    methods:{
        slide(){
           if (this.count >= this.images.length)  this.count = -1;
           this.count++;
        }
    }
}
</script>
<style scoped src="@/assets/css/application.css" ></style>
<style scoped src="@/assets/css/components/Authenticated/showSlider.css"></style>