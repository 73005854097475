<template>
    <expansion-panel>
       <label for="panel3" class="expansion-panel-header"><h6 class="fs-title">Upload Document</h6></label>
           <div class="expansion-panel-content">
              <fieldset>
                <form @submit.prevent="submit">
                    <div class="form-card">

                        <div id="app" class="container my-3">
                            <div class="row">
                          
                              <div class="col-md-12 row">
                                        <h5>Upload Document</h5>
                                        <div class="col-md-6 d-flex align-items-center">
                                            <div class="form-group text-start ">
                                                <label for="my-file"> Select Pdf </label>
                                                <input :key="reload" type="file" accept="application/pdf" multiple="multiple" @change="previewMultiImage" class="form-control-file" id="my-file">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class=" p-2 mt-3 d-flex"  >
                                                <template v-if="preview_list.length" >
                                                    <div v-for="item, index in preview_list" :key="index" class="containerimg m-1">
                                                        <iframe id="fred" style="border:1px solid #666CCC" title="PDF in an i-Frame" :src="item" frameborder="1" scrolling="auto" width="100%" height="150px" ></iframe>
                                                        <button @click="remove(index)" class="button">X</button>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                        <div class="col-md-12 d-flex justify-content-center">
                                            <div v-if="!loading" > 
                                                    <input v-if="preview_list.length" @click="upload" type="submit" name="next" class="next action-button" value="Upload" />
                                            </div>
                                            <div v-else class="spinner-border " role="status"></div>
                                        </div>
                                        <h5 v-if="pending.length" class="mt-3">Uploaded Documents</h5>
                                        <div v-if="pending.length"  class="col-md-12 row">

                                            <div class="col-md-3 col-sm-6 p-2 d-flex justify-content-center" v-for="item in pending" >
                                                <div class="containerimg ">
                                                    <iframe id="fred" style="border:1px solid #666CCC" title="PDF in an i-Frame" :src="base_url+item?.document" frameborder="1" scrolling="auto" width="100%" height="150px" ></iframe>
                                                    <button @click="removePending(item?.id)" class="button">X</button>
                                                </div>
                                                    
                                            </div>

                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>                    
              </fieldset>
           </div>
       </expansion-panel>
       <ErrorToast :key="error"  :message="error" @update="errorup" />
       <SuccesToast :key="success"  :message="success" @update="errorup" />
</template>
<script>
import Multiselect from 'vue-multiselect';
import ErrorToast from "@/components/General/ErrorToast.vue";
import SuccesToast from "@/components/General/SuccessToast.vue";
import { uploadDocuments,pendingDocuments,removeDocuments } from '@/util/Services/Authenticated/edit';
export default{
   components:{
       Multiselect,ErrorToast,SuccesToast
   },
   data(){
       return{
           preview_list: [],
           image_list: [],
           reload:false,
           loading:false,
           error:null,
           success:null,
           pending:[],
       }
   },
 
   props:{
     data:{required:true}
   },
   methods:{
            previewMultiImage: function(event) {
              var input = event.target;
              var count = input.files.length;
              var index = 0;
              if (input.files) {
                while(count --) {
                    var reader = new FileReader();
                    const arr = ['pdf'];
                    const substr = input.files[index].type;
                    const substr1 = substr.split('/')
                    const subArr = arr.some(str => str.includes(substr1[1]));
                    if (subArr) {
                          reader.onload = (e) => {
                            this.preview_list.push(e.target.result);
                          }
                          this.image_list.push(input.files[index]);
                          reader.readAsDataURL(input.files[index]);
                          index ++;
                    } else {
                        this.$swal.fire({
                                          icon: "error",
                                          title: "Oops...",
                                          text: "Only PDF files are allowed. Please upload a file in PDF format.",
                                        });
                    }
                }
              }
            },
            remove(index){
                    this.preview_list.splice(index, 1);
                    this.image_list.splice(index, 1);
                    this.reload = !this.reload;
            },
            async upload(){
                this.loading = true;
                await uploadDocuments(this.image_list).then(r=>{this.error = r?.response?.data?.message,this.success = r?.data?.message,this.loading = false,this.getPending()});
                if (this.success) {
                    this.preview_list = [];
                    this.image_list = [];
                    this.reload = !this.reload;
                }
            },
            async getPending(){
                await pendingDocuments().then(r=>{this.pending = r?.data?.data ? r?.data?.data : [],this.base_url = r?.data?.base_url});
            },
            errorup(){
                this.error =null;
                this.success = null;
            },
            removePending(id){
                this.$swal.fire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#E3086C",
                                cancelButtonColor: "black",
                                confirmButtonText: "Yes, delete it!"
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        removeDocuments(id).then(r=>{this.error = r?.response?.data?.message,this.success = r?.data?.message,this.getPending()});
                                        
                                    }
                                });
            }
   
 },
 mounted(){
    this.getPending();
 }
   
}
</script>
<style scoped src="@/assets/css/application.css" />
<style scoped src="@/assets/css/components/Authenticated/edit.css"></style>
<style scoped>
img{
   height: 120px;
   width: 100px;
   object-fit: contain;
}
   .containerimg {
  position: relative;
  
}

.containerimg .button {
  position: absolute;
  top: 0%;
  left: 100%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: red;
  color: white;
  font-size: 10px;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
}
.spinner-border{
    color: #af0b55;
}

</style>
