
export default {
    state: {
      token:null,
      base_url:'',
      user:[],
      gender:'',
      isLoggedIn:false,
      errorMessage:null,
      successMessage:null,
    },
    getters: {
      isAuthenticated : state => state.isLoggedIn,
      token           : state => state.token,
      user            : state => state.user,
      gender          : state => state.gender,
      base_url        : state => state.base_url,
      header(state){
        const headers = {
            'Authorization': 'Bearer '+state.token,
            'Accept': 'application/json',
            token: state.token
        };
        
        return headers;
      } 
    },
    mutations: {
        token (state,token) {
             state.token = token;
        },
        user (state,user) {
            state.user = user;
            state.gender = user?.user_ids.includes("GRM") ? "Male":'Female';
        },
        user_update (state,user) {
            state.user = user ? user : state.user;
        },
        
        base_url (state,base_url) {
            state.base_url = base_url ? base_url :state.base_url;
        },
        SET_AUTHENTICATED (state, value) {
            state.isLoggedIn = value
        },
        SUCCESS_MESSAGE(state, value) {
            state.successMessage = value
        },
    },
    actions: {
        SET_AUTHENTICATED({commit, state}, value){
            commit('SET_AUTHENTICATED', value)
       },
       SET_LOGIN({commit, state}, value){
            commit('token', value?.token),
            commit('user', value?.user),
            commit('base_url', value?.base_path)
       },
       UPDATE_LOGIN({commit, state}, value){
            commit('user_update', value?.data),
            commit('base_url', value?.base_url)
        },
       SET_MESSAGE({commit, state}, value){
            state.errorMessage   = value?.response?.data?.message;
            commit('SUCCESS_MESSAGE', value?.data?.message)
       },
    },
    modules: {
    }
  }