<template>
     
            <div v-if="item?.best_selling" class="text-white p-2 best_selling">
                          * Beast Selling *
            </div>
            <div class="ca p-2">
                    <div class="form-check">
                          <input class="form-check-input radio-button"  type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                          <label class="form-check-label ml-1" for="flexRadioDefault1"> <h4> {{ item?.name }}</h4> </label>
                    </div>
                        <hr>
                        <h5>{{ item?.month }}</h5>
                        <hr>
                        <h3>₹{{ item?.amount }}</h3>
            </div>
  
</template>
<script>

   export default {
     
      props: {
        item: {
        required: true,
        },
      },
      
     
   }
   </script>
    <style scoped>
    @media (min-width: 1600px) {
      .container,
      .container-lg,
      .container-md,
      .container-sm,
      .container-xl,
      .container-xxl {
        max-width: 1520px;
      }
    }
  
      
      .form-check-input{
          color: green !important;
      }
      .form-check-input:checked {
      background-color: green !important;
      border-color: green !important;
  }
  .form-check .form-check-input {
      float: none !important;
      margin-left: -1.5em !important;
  }
  .form-check-input {
      width: 1.2rem !important;
      height: 1.2rem !important;
  }
  .form-check-label {
      font-size: 24px;
      font-weight: 600;
      color: black ;
      margin-left: 8px !important;;
  }
  h3,h4,h2 {
      font-family: inherit;
      /* color: #251718; */
  }
  .ca{
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      background-color: white;
      height: 100%;
  }
  .best_selling{
      font-weight: 600;
      font-size: small;
  }
  h3{
    font-weight: 800 !important; 
  }
  </style>