<template>
    <div class="container mb-5">
        <div class="row table mb-3">
                <div class="col-12 col-xl-4 col-lg-3 col-md-6 tr  p-1 " v-for="(item) in carddata" >
                         <Card :item="item" @message-updated="login" />
                </div>
        </div>
    </div>
</template>
<style scoped src="@/assets/css/application.css" />
<script>
 import Card from "@/components/Search Page/Card.vue"
 export default {
    props: {
      carddata:{
        type: Array,
        required: true,
      }
    },
    components: {
      Card
    },
    methods:{
      login(login) {
            // console.log('login');
        this.$emit('message-updated', login);
        }
    },
 }
</script>