<template>
    <div class="container">
    <link rel='stylesheet prefetch' href='http://maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css'>
                  <div class="mail-box">
                      <aside class="lg-side">
                          <div class="inbox-body">
                             <div class="mail-option">
                                 <ul class="unstyled inbox-pagination">
                                     <li><span>{{ data?.from ? data?.from : 0 }}-{{ data?.to ? data?.to : 0 }} of {{ data?.total ? data?.total : 0 }}</span></li>
                                     <li>
                                         <a v-if="data?.prev_page_url" @click="prev" class="np-btn active" href="#"><i class="fa fa-angle-left  pagination-left"></i></a>
                                         <a v-else class="np-btn " ><i class="fa fa-angle-left  pagination-left"></i></a>
                                     </li>
                                     <li>
                                         <a v-if="data?.next_page_url" @click="next" class="np-btn active" href="#"><i class="fa fa-angle-right pagination-right"></i></a>
                                         <a v-else class="np-btn" href="#"><i class="fa fa-angle-right pagination-right"></i></a>
                                     </li>
                                 </ul>
                             </div>
                              <table class="table table-inbox table-hover">
                                    <tbody>
                                            <Indbox_item v-for="item in data?.data" :item="item" @read="readApi"/>
                                    </tbody>
                              </table>
                          </div>
                      </aside>
                  </div>
    </div>
    <ErrorToast  :key="error"    :message="error"   @update="toastUp" />
    <SuccesToast :key="success"  :message="success" @update="toastUp" />
    </template>
    <script>
    import { indexApi } from '@/util/Services/Authenticated';
    import Indbox_item from './Indbox_item.vue';
    import { read } from "@/util/Services/Authenticated/index";
    import ErrorToast from "@/components/General/ErrorToast.vue"
    import SuccesToast from "@/components/General/SuccessToast.vue"
    export default {
        components: {
            Indbox_item,ErrorToast,
            SuccesToast,
        },
        data(){
            return{
                data:[],
                page:1,
                error:null,
                success:null,
            }
        },
        methods:{
            async CallAPI(){
                await indexApi(this.page).then(r=>{this.data = r?.data?.data})
            },
            async readApi(inbox_id){
                await read(inbox_id).then(r=>{this.error = r?.response?.data?.message,this.success = r?.data?.message});
                this.CallAPI();
            },
            next(){
                if (this.page < 1) {
                    this.page == 1;
                }
                this.page++;
                this.CallAPI();
                
            },
            prev(){
                this.page--;
                if (this.page < 1) {
                    this.page == 1;
                }
                this.CallAPI();
            },
            toastUp(){
                    this.$store.commit('SUCCESS_MESSAGE', null);
                    // console.log(this.$store.auth)
                    this.$store.state.auth.successMessage = null;
                    this.success = null;
                    this.error = null;
            },
        },
        created(){
            this.CallAPI();
        }
    }
    </script>
   <style scoped src="@/assets/css/components/Authenticated/inbox.css"/>
   <style scoped >
   .active{
    color: #E3086C;
   }
   </style>