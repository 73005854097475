<template>
    <div class="col-md-12">
        <div class="inner-form">
            <div class="basic-search">
                <label class="label" for="search by id">Advanced Search</label>                  
                    <div class="row">                    
                        <div class="col-12 col-sm-6 col-md-4">
                            <SearchSelect :key="form.agefrom" :option="ageoption" :value="form.agefrom" :label="'Age from'" @on_update="$event => (form.agefrom = $event)"/>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <SearchSelect :key="ageError"  :error="ageError" :option="ageoption" :value="form.ageto" :label="'Age to'"  @on_update="$event => (form.ageto = $event,errorCheck())"/>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <SearchSelect :key="form.religion" :option="religiondata" :value="form.religion" :label="'Religion'"  @on_update="$event => (form.religion = $event,CastAPI())"/>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <SearchSelect :key="form.caste" :option="castedata" :value="form.caste" :label="'Caste'"  @on_update="$event => (form.caste = $event)"/>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <SearchSelect :key="form.qualification" :option="qualificationdata" :value="form.qualification" :label="'Qualification'"  @on_update="$event => (form.qualification = $event)"/>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <SearchSelect :key="form.occupation" :option="occupationdata" :value="form.occupation" :label="'Occupation'"  @on_update="$event => (form.occupation = $event)"/>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <SearchSelect :key="form.martialStatus" :option="martialStatusdata" :value="form.martialStatus" :label="'Martial Status'"  @on_update="$event => (form.martialStatus = $event)"/>
                        </div>                     
                        <div class="col-12 col-sm-6 col-md-4">
                            <SearchInput :key="form.heightFrom" :label="'Height From'" :placeholder="'eg: 165, 180'" :value="form.heightFrom" @on_update="$event => (form.heightFrom = $event)" />
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <SearchInput :key="form.heightTo"   :error="heightError" :label="'Height To'" :placeholder="'eg: 165, 180'" :value="form.heightTo" @on_update="$event => (form.heightTo = $event,errorCheck())"  />
                        </div>
                                                             
                    </div>
            </div>

        </div>
    </div>
                                            
    <div class="position-relative ">
        <div class="input-field searchingkey w-100">
            <div class="result-count">
                                                        
            </div>
            <div class="group-btn">
                <button class="btn-delete" @click="Reset" >RESET</button>
                <button class="btn-search" @click="submit" >SEARCH</button>
            </div>
        </div>
    </div>
</template>
<style scoped src="@/assets/css/application.css" />
<style scoped>
    .label {
        color: #E3086C;
        font-size: calc(1.8rem - 1vh);
        padding-bottom: .5rem;
        font-weight: 600;
    }
   
    
</style>
<script>
import SearchSelect from "@/components/Authenticated/Search/SearchSelect.vue"
import SearchInput from "@/components/Authenticated/Search/SearchInput.vue"
import { useVuelidate } from '@vuelidate/core'
import { minValue } from '@vuelidate/validators'
import { religion,martial_status,cast } from '@/util/Services/home';
import { qualification,profession } from '@/util/Services/register';
import { submit } from "@/util/Services/help";
import { ref } from 'vue';
import { useStore } from 'vuex';
export default {
  name: "AcceptedView",
  components: {
    SearchSelect,
    SearchInput,
  },
  setup() {
          const store                  = useStore();
          const minAage                = store.getters.gender == 'Female' ? 21:18;
          const maxAge                 = 65;
          const religion               = ref(null);
          const martial_status         = ref(null);
          const qualification          = ref(null);
          const profession             = ref(null);
          const ageoption              = ref([{option:"Select Age",value:""}]);
          const religiondata           = ref([]);
          const castedata              = ref([{option:"Select Caste",value:""}]);
          const qualificationdata      = ref([]);
          const occupationdata         = ref([]);
          const martialStatusdata      = ref([]);
          const cast                   = ref([]);
          const ageError               = ref(null);
          const heightError            = ref(null);
        
          
            function ageoptions(){
                    let count = minAage;
                    while (count <= maxAge) {
                        let array = {"value":count,"option":count}
                            ageoption.value.push(array);
                            count++;
                    }
                };
                function converted(item){
                    let array = {"value":item?.title,"option":item?.title}
                    return array;
                }
          return {
            minAage,maxAge,religion,martial_status,qualification,profession,ageoption,religiondata,castedata,qualificationdata,occupationdata,
            martialStatusdata,cast,ageError,heightError,ageoptions,converted
          }
      },
  data() {
    return {
        v$:useVuelidate(),
        form:{
            ageto:"",
            agefrom:"",
            religion:"",
            caste:"",
            qualification:"",
            occupation:"",
            martialStatus:"",
            heightFrom:"",
            heightTo:"",
        },
       
    }
  },
  validations(){
  return{
          ageto : {minValue : minValue(this.agefrom)},
        };
  },
  methods:{
    Reset(){
        this.form.ageto         = "";
        this.form.agefrom       = "";
        this.form.religion      = "";
        this.form.caste         = "";
        this.form.qualification = "";
        this.form.occupation    = "";
        this.form.martialStatus = "";
        this.form.heightFrom    = "";
        this.form.heightTo      = "";
    },
   async ReligionAPI(){
        await religion().then(r=>{this.religion = r?.data?.data ? r?.data?.data: []})
        this.religiondatas();
    },
    async MartialStatusAPI(){
        await martial_status().then(r=>{this.martial_status = r?.data?.data ? r?.data?.data: []})
        this.martial_statuses();
    },
    async QualificationAPI(){
        await qualification().then(r=>{this.qualification = r?.data?.data ? r?.data?.data: []})
        this.qualificationdatas();
    },
    async ProfessionAPI(){
        await profession().then(r=>{this.profession = r?.data?.data ? r?.data?.data: []})
        this.occupationdatas();
    },
    async CastAPI(){
        await cast(this.form?.religion).then(r=>{this.cast = r?.data?.data ? r?.data?.data: [] });
        this.form.caste  = "";
        this.casts();
    },
    
    async religiondatas(){
       this.religiondata = [{option:"Select Religion",value:""}].concat(this.religion.map(this.converted));
    },
    async qualificationdatas(){
        this.qualificationdata = [{option:"Select Education",value:""}].concat(this.qualification.map(this.converted));
    },
    async occupationdatas(){
        this.occupationdata = [{option:"Select Occupation",value:""}].concat(this.profession.map(this.converted));
    },
    async martial_statuses(){
        this.martialStatusdata = [{option:"Select Status",value:""}].concat(this.martial_status.map(this.converted));
    },
    async casts(){
        this.castedata = [{option:"Select Caste",value:""}].concat(this.cast.map(this.converted));
    },
    submit(){
       if (this.errorCheck()) {
         this.$emit('submit',this.form); 
       }
    },
    errorCheck(){
        this.ageError = null;
        this.heightError = null;
        if (  this.form.agefrom <= this.form.ageto &&  this.form.heightFrom <=  this.form.heightTo || this.form.ageto && this.form.heightTo) {
           return true;
        }
        if (this.form.agefrom > this.form.ageto && this.form.ageto ) {
            this.ageError = 'The age should be greater than Age from';
        }
        if (this.form.heightFrom >  this.form.heightTo && this.form.heightTo) {
            this.heightError = 'The height should be greater than height from';
        }
        return false;
    }

  },
  created(){
    this.ageoptions();
    this.ReligionAPI();
    this.MartialStatusAPI();
    this.QualificationAPI();
    this.ProfessionAPI();
  },
  
}
</script>