<template>
    <!-- Required Stylesheets -->

       <!-- Required scripts -->

   <title>Goodlife Matrimony</title>
    <header id="header" class="fixed-top header-transparent p-md-2 p-2 header-scrolled">
   <div class="container-fluid">
    
     <BNavbar  class="navbar navbar-expand-lg ">
         <div class="container-fluid">
           <a :href="homelink">
               <img  src="@/assets/img/logo.svg" alt="Goodlife" class="img-fluid d-block d-lg-none"/>
           </a>
           
           <div class="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
             <a :href="homelink"><img src="@/assets/img/logo.svg" alt="" class="img-fluid d-none d-lg-block"></a>
             <ul class="navbar-nav w-100 gap-0 gap-lg-5 justify-content-center mb-2 mb-lg-0 border-0 pt-4 pt-lg-0">
              
             </ul>
            
           </div>
         </div>
       </BNavbar >
     </div>
  
 </header>
</template>
<script>
import AOS from 'aos'
import { Icon } from '@iconify/vue';
export default {
 components: {
      Icon
  },
 
 data() {
   return {
     homelink: "/home",
    
   };
 },
   methods:{
     
   },
   mounted() {
   AOS.init()
 },

}   
</script>
