<template>
    <p>{{ item?.head }} -- <b>{{ item?.value }}</b></p>
    <div class="d-flex align-items-center gap-3 grid">
    <div :class="{'d-none':!item?.strict}" class="strict"><Icon icon="material-symbols-light:label-important-rounded"/>Strict</div>
        <Icon v-if="item?.preference" class="icon" icon="mdi:tick-circle"/>
        <Icon v-else class="close" icon="mdi:close-circle"/>
    </div>
</template>
<script>
    import { Icon } from "@iconify/vue";
    export default {
        props: {
    item: {
      required: true,
    }
  },
      components: {
        Icon,
      },
    }
</script>
<style scoped src="@/assets/css/application.css" ></style>
<style scoped>
 p, b {
    font-size: .85rem;
    
}
.strict {
        color: #F7941D;
        align-items: center;
        display: flex;
        grid-gap: .2rem;
    }
    .icon{
        font-size: larger;
        color: #35CB00;
    }
    .close{
        font-size: larger;
        color: red;
    }
</style>