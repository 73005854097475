<template>
    <div class="benecard">
        <Icon class="icon" :icon="item?.icon"/>
        <h5>{{item?.head}}</h5>
        <p>{{item?.p}}</p>
      </div>
</template>
<script>
 
   import AOS from 'aos'
   import { Icon } from '@iconify/vue';
   export default {
      components: {
          Icon
      },
      props: {
    item: {
      required: true,
    },
  },
      
     mounted(){
           AOS.init()
     }
   }
   </script>
   <style scoped>
   .big-icon {
      font-size: 28px;
   }
   .icon {
            font-size: 3rem;
            color: #e3086c;
        }
   
     
   </style>