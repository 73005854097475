<template>

    
        
                  <div class="col-md-12 mt-4">
                      <div class="contact_inner">
                          <div class="row align-items-center">
                              <div class="col-md-7">
                                <Form @error="errors" @success="successer" />
                              </div>
                              <div class="col-md-5">
                                <Contact />
                                  
                              </div>
                          </div>
                          
                      </div>
                  </div>
             
    
                  

        </template>
        
      
       <script > 
       import Form from '@/components/Help/form.vue';
       import Contact from '@/components/Help/Contact.vue';
       export default {
          components: {
              Form,Contact,
          },
          methods:{
                errors(error){
                  this.$emit('error',error)
                },
                successer(success){
                  this.$emit('success',success)
                }
          }
       }
       </script>
       <style>
       .big-icon {
          font-size: 28px;
       }
         @media (min-width: 1600px) {
           .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
               max-width: 1520px;
           }
         }
       </style>