<template>
 <label class="fieldlabels">{{label}} (cm):</label> 
 <input @keypress="onlyNumber($event)" :key="error" :class="{'errors':error}" type="text"  v-model="data" @change="updated" :placeholder="placeholder" />
 <span class="error">{{ error }}</span>
</template>
<script>
  export default {
      props: {
        placeholder: { required: true },
        value: { required: true },
        label: { required: true },
        min: { required: true },
        error: {},
      },
      data(){
        return{
            data:this.value
        }
      },
      methods:{
        updated(){
            this.$emit('on_update', this.data);
        },
        onlyNumber ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { 
                $event.preventDefault();
            }
          }
      }
  };
</script>
<style scoped src="@/assets/css/application.css" />
<style scoped>
    .fieldlabels {
        font-size: small;
        color: #757575;
        padding-bottom: 0;
        font-weight: 400;
        padding-top: 1rem;
    }
    input {
      border: 1px solid #cecece;
      border-radius: 5px;
      width: 100%;
      padding: .5rem 1rem;
    }
    .errors {
      border: 1px solid red !important;
    }
.error{
  margin-top: 10px;
  color: red;
}
</style>