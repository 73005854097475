<template>
    <!-- Navigation bar  -->
    <Nav :navactive="navactive" />
    <!-- Main  -->
    <main class="site-wrap">
     <Upgrade />
     <Pale />
    </main>

    <Footer />
  </template>
  <script>
  // @ is an alias to /src
  import Nav from "@/components/General/Navbar.vue";
  import Footer from "@/components/General/Footer.vue";
  import Pale from "@/components/General/Pale.vue";
  import About from "@/components/About/About.vue";
  import Upgrade from "@/components/Payment/Upgrade.vue";
  export default {
    name: "CompareView",
    components: {
      Nav,
      Pale,
      Footer,
      About,
      Upgrade
    },
    data() {
      return {
        navactive:"compare",
       
      };
    },
   
  };
  </script>
  <style scoped>
  @media (min-width: 1600px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
      max-width: 1520px;
    }
  }

    .card{
        border-radius: 20px !important;
        width: 18rem;
        height: 12rem;
        margin: 0;
        overflow: hidden;
    }
    .best{
        border-radius: 20px;
        width: 18rem;
        height: 15rem;
        background-color: red !important;
        border-color: red !important;
    }
    .form-check-input{
        color: green !important;
    }
    .form-check-input:checked {
    background-color: green !important;
    border-color: green !important;
}
.form-check .form-check-input {
    float: none !important;
    margin-left: -1.5em !important;
}
.form-check-input {
    width: 1.2rem !important;
    height: 1.2rem !important;
}
.form-check-label {
    font-size: 24px;
    font-weight: 600;
    color: black ;
    margin-left: 8px !important;;
}
h3,h4,h2 {
    font-family: inherit;
    /* color: #251718; */
}
.ca{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: white;
    height: 100%;
}
.best_selling{
    font-weight: 600;
    font-size: small;
}
</style>
  