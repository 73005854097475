import axios from 'axios';
import header from "@/util/Services/Authenticated/header";
import store from '@/store'
let url = process.env.VUE_APP__BASE_URL
export const searchById = (user_id,page) => {
    if (user_id != NaN) {
        return axios.post(url+"customer/search-by-id?page="+page,{user_id:user_id,gender:store.getters.gender})
        .then((response) => response).catch((error) => error );
    }
};
export const AdvanceSearchAPIs = (form,page) => {
        return axios.post(url+"customer/advanced-search?page="+page,
            {
                age_from:form?.agefrom ? form?.agefrom : null,
                age_to:form?.ageto ? form?.ageto : null,
                religion:form?.religion ? form?.religion : null,
                cast:form?.caste ? form?.caste : null,
                marital_status:form?.martialStatus ? form?.martialStatus : null,
                height_from:parseInt(form?.heightFrom) > 0 ? parseInt(form?.heightFrom) : null,
                height_to:parseInt(form?.heightTo) > 0  ? parseInt(form?.heightTo) : null,
                qualification:form?.qualification ? form?.qualification : null,
                profession:form?.occupation ? form?.occupation :null,
                gender:store.getters.gender})
        .then((response) => response).catch((error) => error );
};