<template>
    <fieldset>
      <form @submit.prevent="next">
                  <div class="form-card">
                    <div class="row">
                      <div class="col-7">
                        <h5 class="fs-title">Prefered Partner:</h5>
                      </div>
                      <div class="col-5">
                        <h6 class="steps">Step 3 - 4</h6>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <label class="fieldlabels">Age From: &nbsp;<span  id="agefrom" >{{ form.agefrom }}</span></label>
                        <div class="d-flex">
                          <input type="range" min="18" max="60" v-model.number="form.agefrom" class="slider1" id="agefrom">
                        </div>
                      </div>
                      <!-- {{ agefrom }} -->
                      <div class="col-6">
                        <label class="fieldlabels">Age To: &nbsp;<span  id="toout">{{ form.ageto }}</span></label>
                        <div class="d-flex">
                          <input type="range"  min="18" max="60"  v-model.number="form.ageto" class="slider1" id="ageto">
                        </div>
                      </div>
                      <div class="col-12">
                        <label class="fieldlabels">Oualification:</label>
                        <select v-model="form.qualification" class="form-select" aria-label="Default select example">
                          <option value="" >Select Education</option>
                          <option v-for="item in qualification" :value="item?.title">{{ item?.title }}</option>

                        </select>
                      </div>
                      <div class="col-12">
                        <label class="fieldlabels">Profession :</label>
                        <select v-model="form.profession" class="form-select" aria-label="Default select example">
                          <option value="" >Select Profession</option>
                          <option v-for="item in profession" :value="item?.title">{{ item?.title }}</option>
                        </select>
                      </div>
                      <div class="col-md-6">
                        <label class="fieldlabels">Religion:</label>
                        <Multiselect v-model="form.religion" :options="religions":multiple="true"
                            placeholder="Select Religion"  @select="prefCastAPI" @remove="prefCastAPI"
                          />
                      </div>
                      <div class="col-md-6">
                        <label class="fieldlabels">Caste:</label>
                        <Multiselect
                            v-model="form.caste"
                            :options="cast"
                             id="ajax"
                            :multiple="true"
                            :close-on-select="false" :clear-on-select="false"
                            :internal-search="false"
                            :preselect-first="true"
                             placeholder="Select Caste"
                            :key="cast"
                          />
                      </div>
                      <div class="col-md-6">
                        <label class="fieldlabels">Country:</label>
                        <select @change="stateAPI" v-model="form.country" class="form-select" aria-label="Default select example">
                          <option value="">Select Country</option>
                          <option v-for="item in country" :value="item?.title">{{ item?.title }}</option>
                        </select>
                      </div>
                      <div class="col-md-6">
                        <label class="fieldlabels">State:</label>
                        <select v-model="form.state" class="form-select" aria-label="Default select example">
                          <option value="">Select state</option>
                          <option v-for="item in states" :value="item?.title">{{ item?.title }}</option>
                        </select>
                      </div>
                      <!-- <div class="col-md-12">
                        <label class="fieldlabels">About Partner:</label>
                        <textarea v-model="form.about" name="Detail" form="usrform"> {{form?.about ? form?.about:"Enter text here..."}}</textarea>
                      </div> -->
                    </div>
                    <div class="d-flex py-2 flex-nowrap">
                <div class="checkbox-wrapper-46 w-auto d-flex ">
                  <input type="checkbox" id="cbx-46" required v-model="form.check" value=true class="inp-cbx" />
                  <label for="cbx-46" class="cbx"><span class="m-1">
                     </span><span></span>
                  </label>
                 
                </div>
                
                <p class="m-0 text-start text-black">I have read and agree to the <span class="primary">Terms of Use &
                    Privacy Policy </span></p>
                   
                    
              </div>
              <span v-if="v$.form.check.$error" class="error"> Please kindly accept the terms of service to proceed further. </span>
               <br v-if="v$.form.check.$error">
                  </div> 
                  <input v-if="!onSubmit" @click="next" type="button" name="next" class="next action-button" value="Submit" /> 
                  <input v-if="!onSubmit" @click="prev" type="button" name="previous" class="previous action-button-previous" value="Previous" />
                  <div v-if="onSubmit" style="color:#e3086c;" class="spinner-border m-4 " role="status">
                  <span class="sr-only"></span> 
                </div>
                  </form>
                </fieldset>
</template>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<script>
import Multiselect from 'vue-multiselect'
import { prefReligions,prefCast,qualification,profession } from "@/util/Services/register";
import { country,states } from "@/util/Services/register"
import { useVuelidate } from '@vuelidate/core'
import { required, sameAs} from '@vuelidate/validators'
export default{
  components: { Multiselect },
    props: {
    current: {
      required: true,
    },
    formData3:{
      required: true,
    },
    failed:{
      required: true,
    }
   
},
data() {
    return {
      v$:useVuelidate(),
      form:{
        agefrom       : this.formData3?.agefrom ? this.formData3?.agefrom :  18,
        ageto         : this.formData3?.ageto ? this.formData3?.ageto : 40,
        qualification : this.formData3?.qualification ? this.formData3?.qualification : '',
        profession    : this.formData3?.profession ? this.formData3?.profession : '',
        religion      : this.formData3?.religion ? this.formData3?.religion : '',
        caste         : this.formData3?.caste ? this.formData3?.caste : '',
        country       : this.formData3?.country ? this.formData3?.country : '',
        state         : this.formData3?.state ? this.formData3?.state : '',
        about         : this.formData3?.about ? this.formData3?.about : '',
        check         : this.formData3?.check ? this.formData3?.check : ''
      },
      religions: [],
      cast:[],
      casts:[],
      isLoading:false,
      qualification:'',
      profession:'',
      country:[],
      states:[],
      onSubmit:false
    };
  },
  validations(){
  return{
    form:{
                check:{required,sameAs:sameAs(true)},
    }
   
                
  } ;
},
  methods:{

    next(){
      this.v$.$validate()
      console.log(this.check)
      if(!this.v$.$error ){
        this.onSubmit = true;
        this.$emit('form-data', this.form);
        this.$emit('message-updated', this.current+1);
      }
        
    },
    prev(){
        this.onSubmit = false;
        this.$emit('form-data', this.form);
        this.$emit('message-updated', this.current-1);
    },
    async prefReligionsAPI(){
      await prefReligions().then(r=>{this.religions = r?.data?.data});
    },
     prefCastAPI(){
      this.isLoading = true
       prefCast(this.form.religion).then(r=>{this.cast = r?.data?.data,this.isLoading = false});
       if (!this.cast) {
        this.cast= [];
       } 
      console.log(this.form.cast,this.cast)
      
    },
    async qualificationAPI(){
      await qualification().then(r=>{this.qualification = r?.data?.data});
    },
    async professionAPI(){
      await profession().then(r=>{this.profession = r?.data?.data});
    },
    async countryAPI(){
      await country().then(r=>{this.country = r?.data?.data});
    },
    async stateAPI(){
      if (this.form.country) {
        await states(this.form.country).then(r=>{this.states = r?.data?.data});
      }
    },
   
},mounted(){
  this.prefReligionsAPI();
  this.qualificationAPI();
  this.professionAPI();
  this.countryAPI();
}
}

</script>
<style>
.multiselect {
    box-sizing: content-box;
    display: block;
    position: relative;
    width: 100%;
    min-height: 40px;
    text-align: left;
    color: #2C3E50;
}
.multiselect__tags {
    min-height: 40px;
    display: block;
    padding: 8px 40px 0 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background: #fff;
    font-size: 14px;
}
.multiselect__placeholder {
    color: #2C3E50;
    display: inline-block;
    margin-bottom: 0px;
    padding-top: 2px;
}
.multiselect__option {
    display: block;
    padding: 1rem;
    height: 1rem;
    line-height: 8px;
    text-decoration: none;
    text-transform: none;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    font-size: 14px !important;
}

</style>
