<template>
    <div class="site-blocks-cover2 overlay aos-init aos-animate"
  style="background-image: url('../img/banner-1.jpg'); 
  background-position: 70% 50%; 
  background-size: cover; 
  background-repeat: no-repeat;"
  data-aos="fade" 
  data-stellar-background-ratio="0.5">
  <div class="container ">
    
  </div>
</div>
</template>