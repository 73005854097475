<template>
<div class="site-section servicebenefit aos-init bg-pale position-relative" data-aos="fade">
  <img src="@/assets/img/leftabstract.png" alt="" class="abstract start-0 d-none d-lg-block">
  <img src="@/assets/img/rightabstract.png" alt="" class="abstract end-0 d-none d-lg-block">
  <div class="container">
    <div class="row flex-wrap">
      <h1 class="text-center mb-5">Free Membership Service Benefits</h1>
      <div class="col-md-4" v-for="(item, index) in data" :key="index">
            <BeneCard :item=item />
      </div>
    </div>
  </div>
</div>
</template>
<script > 
     import BeneCard from '@/components/General/BeneCard.vue';
     import AOS from 'aos'
     export default {
        components: {
         BeneCard
      },
      data() {
        return {
            data:[
                  {
                        icon:'mdi-light:camera',
                        head:'Photographs',
                        p:'Create a Matrimonial Profile With 7 Photographs'
                  },
                  {
                        icon:'fluent-mdl2:profile-search',
                        head:'Profiles',
                        p:'Search For Suitable Matches'
                  },
                  {
                        icon:'solar:server-square-update-broken',
                        head:'Updations',
                        p:'Can Update Profile Instantly'
                  },
                  {
                        icon:'solar:lock-linear',
                        head:'Fully Secure',
                        p:'Security Offered For Your Photo & Data'
                  },
                  {
                        icon:'fluent-mdl2:contact-heart',
                        head:'Proposals',
                        p:'Respond To All Proposals'
                  },
                  {
                        icon:'mage:message-dots-round',
                        head:'Message',
                        p:'Send Pre-defined Messages'
                  },
                ]
        }
    },
        mounted(){
           AOS.init()
     }
     
     }
</script>