import axios from 'axios'
import store from '@/store'

let url = process.env.VUE_APP__BASE_URL;

export const loginApi = (form) => {
    const data = axios.post(url+"login",{email:form?.emails,password:form?.password})
    .then(response => response)
    .catch((error) => error);
    data.then(r=>{
                    store.dispatch('SET_AUTHENTICATED', r?.data?.token ? true:false),
                    store.dispatch('SET_LOGIN', r?.data),
                    store.dispatch('SET_MESSAGE', r)
                 });
    return data;
  };