<template>
    <form>
                              <div class="contact_form_inner p-4">
                                  <div class="contact_field sta">
                                      <h3>Contact Us</h3>
                                      <p>Feel Free to contact us any time. We will get back to you as soon as we can!.</p>
                                      <form  @submit.prevent="onSubmit">
                                        <input type="text" v-model="form.name" v-bind:class="{'error':v$.form.name.$error}" class="form-control form-group" placeholder="Name" />
                                        <span v-if="v$.form.name.$error" class="error"> Name is a required </span>
                                        <input type="email" v-bind:class="{'error':v$.form.emails.$error}" v-model="form.emails" class="form-control form-group" placeholder="Email" />
                                        <span v-if="v$.form.emails.$error" class="error"> 
                                          {{ v$.form.emails.$errors[0].$message == "Value is required" ? "Email is required":v$.form.emails.$errors[0].$message }} </span>
                                        <textarea v-model="form.message" class="form-control form-group" placeholder="Message"></textarea>
                                        <button type="submit" class="contact_form_submit">Send</button>
                                      </form>  
                                  </div>
                              </div>
                              
    </form>
   
</template>
<script>

import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { submit } from '@/util/Services/help';
export default {
data() {
    return {
          v$:useVuelidate(),
          form:{
                name: '',
                emails:' ',
                message:''
              },
    };
},
validations(){
  return{     
    form:{
          name: {required},
          emails:{required,email}
    }
  };
},

methods: {
 async onSubmit() {
      this.v$.$validate()
      if(!this.v$.$error){
        await submit(this.form).then(r=>{this.error = r?.response?.data.message,this.success = r?.data?.message});
          if(this.success){
            this.$emit('success',this.success);
            //reset form 
            this.reset();
          }else{
            this.$emit('error',this.error);
          }
      }
    }, 
    reset(){
          // validation reset
          this.v$.$reset();
          this.form.name = '';
          this.form.emails = '';
          this.form.message = '';
      },
  }
}
</script>
<style scoped>
.error{
    border-color: red !important;
    color: red !important;
    text-align:start !important;
    float: left !important;
}
</style>