
  <template>
    <div v-if="success" class="toast align-items-center text-white bg-success border-0" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="d-flex">
      <div class="toast-body">
        {{ messages[0] }}
      </div>
      <button type="button" class="btn-close btn-close-white me-2 m-auto" @click="close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
  </div>
</template>
<style>
.toast{
    display: block !important;
    position: fixed !important;;
    top:5rem;
    right: 10px;
    text-align: center !important;
}

</style>
<script>
 export default {
    props: {
      message:{
        required: true,
      },
    },
    data(){
        return{
            success:false,
           messages:[]
        }
    },
    methods:{
        warnDisabled() {
            // console.log()
            this.success = true
            setTimeout(() => {
                this.close() 
            }, 2000)
          },
          close(){
            this.error = false
            this.$emit('update',true)
          }
    },
    mounted(){
        // console.log(this.message)
        
        if (this.message) {
          this.messages = this.message.split(',')
            this.warnDisabled()   
        }
            
        
    }
    
 }
</script>