<template>
   <div class="col-md-12">
    <div class="pt-4  position-relative inner-form">
        <label  for="search by id">{{ label }}</label>
        <span v-if="v$.search.$error" class="error"><br> Please enter a valid search term.  <br></span>
        <label v-if="label1" for="" class="grmid">{{label1}}</label>
        <input @keypress="onlyNumber($event)" :class="{'errors':v$.search.$error}" v-model="search" type="text" :placeholder="placeholder">
        <button @click="submit" class="btn-search iconsearch"><Icon icon="ri:search-line"/></button>
    </div>
 </div>  
</template>
<script>
import { submit } from "@/util/Services/help";
import { Icon } from "@iconify/vue";
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
export default {
  components: {
    Icon,
  },
  props: {
    label: { required: true },
    label1:{},
    placeholder: { required: true },
  },
  data(){
    return{
      v$:useVuelidate(),
      search:''
    }
  },
  validations(){
  return{
          search : {required},
        };
},
  methods:{
       submit(){
        this.v$.$validate()
        if(!this.v$.$error ){
            // this.search1 = this.label1+this.search;
            // console.log(this.search1);
            this.$emit('submit',this.search);
        }
       },
       onlyNumber ($event) {
        //console.log($event.keyCode); //keyCodes value
        // let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        // if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        //     $event.preventDefault();
        // }
      }
  }
};
</script>
<style scoped src="@/assets/css/application.css" />
<style scoped>
    label {
        color: #E3086C;
        font-size: calc(1.8rem - 1vh);
        padding-bottom: .5rem;
        font-weight: 600;
    }
    .grmid {
        font-size: small;
        position: absolute;
        bottom: 10%;
        left: 2%;
        padding-bottom: 0;
        color: #757575;
        font-weight: 400;
    }
    input {
        width: 100%;
        padding: .5rem 1rem;
        border: 1px solid #cecece;
        border-radius: 5px;
    }
    
    .grmid ~ input {
        padding-left: 3.5rem;
    }

    .errors {
      border: 1px solid red !important;
    }

</style>