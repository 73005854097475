<template>
    <!-- Navigation bar for Home -->
    <Nav :navactive="navactive"  />
    <!-- Main Sider in Home -->
    <SearchForm  @from-submit="submitForm" :formdata="formdata"    :slide="slide" />
    <div class="col-12 main">
        <Cards :carddata="Result" @message-updated="login" />
    </div>
     
    <Login :key="show" :show="show" @message-updated="login" />
    <Footer />
    <ErrorToast :key="error"  :message="error"/>
  </template>
  <script>
  // @ is an alias to /src
  import Nav from "@/components/General/Navbar.vue";
  import SearchForm from "@/components/Search Page/SearchForm.vue";
  import Footer from "@/components/General/Footer.vue";
  import Cards from "@/components/Search Page/Cards.vue"
  import ErrorToast from "@/components/General/ErrorToast.vue"
  import { HomeSearch} from '@/util/Services/home';
  export default {
    name: "SearchView",
    props: {
      form:{
        required: true,
      }
    },
    components: {
      Nav,
      SearchForm,
      Footer,
      Cards,
      ErrorToast
    },
    data() {
      return {
        navactive:"help",
        slide: {
          gender: ["Female", "Male"]
        },
        Result:[],
        error:"",
        show: false,
        formdata:JSON.parse(this.form),
       
      };
    },
    methods: {
     
      async submitForm(form){
        
        await HomeSearch(form).then(r => 
        {this.error = r?.response?.data.message,this.Result = r?.data?.data });
        // console.log(this.Result,this.error)
      },
      
    },mounted() {
      // console.log(this?.slide);
      let data = this.form;
      console.log({data});
      
    },
  };
  </script>

  