import axios from 'axios'

let url = process.env.VUE_APP__BASE_URL;

export const martial_status = () => {
    return axios.post(url+"get-maritial-status").then((response) => response).catch(error => console.log(error));
  };
export const religion = () => {
    return axios.post(url+"get-religions").then((response) => response).catch(error => console.log(error));
  };
export const cast = (religion) => {
    return axios.post(url+"get-cast",{religion:religion}).then((response) => response).catch(error => console.log(error));
  };
export const HomeSearch = (form) => {
    return axios.post(url+"profile-search-filter",
        {religion:form?.religion == 'null' ? null:form?.religion,gender:form?.gender == 'null' ? null:form?.gender
          ,marital_status:form?.marital_status == "null" ? null:form?.marital_status,cast:form?.cast == 'null' ? null :form?.cast}
    ).then((response) => response).catch((error) => error);
  };
export const HomeAPI = () => {
    return axios.post(url+"home_page").then((response) => response).catch(error => console.log(error));
  };
  