<template>
    <body data-aos-easing="slide" data-aos-duration="800" data-aos-delay="0" class="responsivewhite">
          <div class="d-flex flex-wrap">
              <SideNav :active="active" :show="show" />
  
              <div class="col-lg-9 col-xl-10 col-12 p-0">
                  <div class="d-flex flex-wrap ">
                      <MainNav  :active="active"  :show="show"  @nav-show="sidenav" />
  
                      <div class="col-lg-9 col-xl-10  col-12 p-0">
                        <main class="p-0 p-lg-3">
                            <div class="mainbody pt-5 mt-4 mt-lg-0 pt-lg-0">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button @click="JoinTab" class="nav-link active" id="joined-tab" data-bs-toggle="tab"
                                            data-bs-target="#joined" type="button" role="tab" aria-controls="joined"
                                            aria-selected="true">Just Joined</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button @click="MatcheTab" class="nav-link" id="matches-tab" data-bs-toggle="tab"
                                            data-bs-target="#matches" type="button" role="tab" aria-controls="matches"
                                            aria-selected="false">My Matches</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button @click="PremiumTab" class="nav-link" id="premium-tab" data-bs-toggle="tab"
                                            data-bs-target="#premium" type="button" role="tab" aria-controls="premium"
                                            aria-selected="false">Premium</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button @click="SuggestedTab" class="nav-link" id="mutual-tab" data-bs-toggle="tab"
                                            data-bs-target="#mutual" type="button" role="tab" aria-controls="mutual"
                                            aria-selected="false">Suggested</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button @click="AlreadyViewedTab" class="nav-link" id="already-tab" data-bs-toggle="tab"
                                            data-bs-target="#already" type="button" role="tab" aria-controls="already"
                                            aria-selected="false">Already Viewed</button>
                                    </li>
                                   

                                </ul>
                                <div class="p-2">

                                    <div class="tab-content" id="myTabContent">

                                        <!-- Just Joined Table -->
                                        <div class="tab-pane fade active show " id="joined" role="tabpanel" aria-labelledby="joined-tab">
                                            <Spinner v-if="loading" />
                                            <div v-else class="row table">
                                                <div class="col-12 col-xl-4 col-lg-6 col-md-6 tr  p-1" v-for="(item) in joinData" >
                                                  <Card :item="item" />
                                                </div>
                                            </div>
                                            <button v-if="justJoined?.next_page_url != null && !loading" class="btn btn-primary mt-3 showmore mb-4 mb-lg-0"
                                               @click="JoinMore()" >Show More</button>
                                        </div>

                                        <!-- My Matches Table -->
                                        <div class="tab-pane fade" id="matches" role="tabpanel" aria-labelledby="matches-tab">
                                            <Spinner v-if="loading" />
                                            <div v-else  class="row table">
                                                <div class="col-12 col-xl-4 col-lg-6 col-md-6 tr  p-1" v-for="(item) in matchesData" >
                                                  <Card :item="item" />
                                                </div>
                                            </div>
                                            <button v-if="myMatches?.next_page_url != null && !loading" class="btn btn-primary mt-3 showmore mb-4 mb-lg-0"
                                               @click="MoreMyMatches()" >Show More</button>
                                        </div>


                                        <!-- Premium Table -->
                                        <div class="tab-pane fade" id="premium" role="tabpanel" aria-labelledby="premium-tab">
                                            <Spinner v-if="loading" />
                                            <div v-else class="row table">
                                                <div class="col-12 col-xl-4 col-lg-6 col-md-6 tr  p-1" v-for="(item) in premiumData" >
                                                  <Card :item="item" />
                                                </div>
                                            </div>
                                            <button v-if="Premium?.next_page_url != null && !loading" class="btn btn-primary mt-3 showmore mb-4 mb-lg-0"
                                            @click="MorePremium()" >Show More</button>
                                        </div>

                                        <!-- Mutual Table -->
                                        <div class="tab-pane fade" id="mutual" role="tabpanel" aria-labelledby="mutual-tab">
                                            <Spinner v-if="loading" />
                                            <div class="row table">
                                                <div class="col-12 col-xl-4 col-lg-6 col-md-6 tr  p-1" v-for="(item) in suggestedData" >
                                                  <Card :item="item" />
                                                </div>
                                            </div>
                                            <button v-if="Suggested?.next_page_url != null && !loading" class="btn btn-primary mt-3 showmore mb-4 mb-lg-0"
                                            @click="MoreSuggested()" >Show More</button>
                                        </div>

                                        <!-- Already Viewed Table -->
                                        <div class="tab-pane fade" id="already" role="tabpanel" aria-labelledby="already-tab">
                                            <Spinner v-if="loading" />
                                            <div class="row table">
                                                <div class="col-12 col-xl-4 col-lg-6 col-md-6 tr  p-1" v-for="(item) in alreadyViewedData" >
                                                  <Card :item="item" />
                                                </div>
                                            </div>
                                            <button v-if="alreadyViewed?.next_page_url != null && !loading " class="btn btn-primary mt-3 showmore mb-4 mb-lg-0"
                                            @click="MoreAlreadyViewed()" >Show More</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>


                    <div class="col-lg-3 col-xl-2  d-none d-lg-block p-0">
                      <!-- Quick Search -->
                       <QuickSearch :martial_status="martial_status" :religion="religion"/>
                       <!-- Happy Couples -->
                       <HappyCouple  :successStory="successStory" />
                    </div>
                      
                  </div>
              </div>
  
  
  
          </div>
          <MobileNav :active="active"/>
    
  </body>
    </template>
  
   
    <script>
    import AOS from 'aos';
    import { Icon } from "@iconify/vue";
    import MainNav from "@/components/Authenticated/UserMainNav.vue";
    import SideNav from "@/components/Authenticated/UserSideNav.vue";
    import MobileNav from  "@/components/Authenticated/MobileNav.vue";
    import Card from "@/components/Authenticated/UserCard.vue";
    import QuickSearch from "@/components/Authenticated/Matches/QuickSearch.vue";
    import HappyCouple from "@/components/Authenticated/Matches/HappyCouples.vue";
    import { justJoined,myMatches,Premium,Suggested,AlreadyView,successStory } from "@/util/Services/Authenticated/matches";
    import { martial_status,religion } from "@/util/Services/home";
    import Spinner from "@/components/Authenticated/Spinner.vue";
    import { ref } from 'vue';
    export default {
      name: "AcceptedView",
      components: {
        MainNav,SideNav,Icon,MobileNav,Card,QuickSearch,HappyCouple,Spinner
      },
      setup() {
            const joinCount           = ref(1);
            const myMatchesCount      = ref(1);
            const premiumCount        = ref(1);
            const suggestedCount      = ref(1);
            const alreadyViewedCount  = ref(1);
            const loading             = ref(false);
            const show                = ref(false);
            const active              = 'Match';
            const tab                 = 'joined';
            //Just Joined
            const joinData          = ref([]);
            const justJoined        = ref([]);
            //My Matches
            const matchesData       = ref([]);
            const myMatches         = ref([]);
            //Premium
            const premiumData       = ref([]);
            const Premium           = ref([]);
            //Suggested
            const suggestedData     = ref([]);
            const Suggested         = ref([]);
             //Already Viewed
            const alreadyViewedData = ref([]);
            const alreadyViewed     = ref([]);
            const successStory      = ref([]);

            const martial_status    = ref([]);
            const religion          = ref([]);

            return {
                loading,show,active,tab,joinCount,myMatchesCount,premiumCount,suggestedCount,alreadyViewedCount,
                justJoined,joinData,matchesData,myMatches,premiumData,Premium,suggestedData,Suggested,
                alreadyViewedData,alreadyViewed,successStory,martial_status,religion
            }
        },
      methods: {
        sidenav() {
          this.show = !this.show;
        },
        JoinMore(){
          this.joinCount++;
          this.JoinedAPI();
        },
        JoinTab(){
          this.joinCount = 1;
          this.joinData = [];
          this.JoinedAPI();
        },
        async JoinedAPI(){
          if (this.joinCount == 1) this.loading = true;
           await justJoined(this.joinCount).
          then(r=>{this.justJoined = r?.data?.data,r?.data?.data?.data ? this.joinData.push(...r?.data?.data?.data):[]});
          if (this.joinCount == 1) this.spinner();
        },
        MoreMyMatches(){
          this.myMatchesCount++;
          this.MyMatches();
        },
        MatcheTab(){
          this.myMatchesCount = 1;
          this.matchesData = [];
          this.MyMatches();
        },
        async MyMatches(){
          if (this.myMatchesCount == 1) this.loading = true;
          await myMatches(this.myMatchesCount).
          then(r=>{this.myMatches = r?.data?.data,r?.data?.data?.data ? this.matchesData.push(...r?.data?.data?.data) : r?.data?.data?.data});
          if (this.myMatchesCount == 1) this.spinner();
        },
        MorePremium(){
          this.premiumCount++;
          this.PremiumAPI();
        },
        PremiumTab(){
          this.premiumCount = 1;
          this.premiumData = [];
          this.PremiumAPI();
        },
        async PremiumAPI(){
          if (this.premiumCount == 1) this.loading = true;
          await Premium(this.premiumCount).
          then(r=>{this.Premium = r?.data?.data,r?.data?.data?.data ? this.premiumData.push(...r?.data?.data?.data) : []});
          if (this.premiumCount == 1) this.spinner();
        },
        MoreSuggested(){
          this.suggestedCount++;
          this.SuggestedAPI();
        },
        SuggestedTab(){
          this.suggestedCount = 1;
          this.suggestedData = [];
          this.SuggestedAPI();
        },
        async SuggestedAPI(){
          if (this.suggestedCount == 1) this.loading = true;
          await Suggested(this.suggestedCount).
          then(r=>{this.Suggested = r?.data?.data,r?.data?.data?.data ? this.suggestedData.push(...r?.data?.data?.data):[]});
          if (this.suggestedCount == 1) this.spinner();
        },
        MoreAlreadyViewed(){
          this.alreadyViewedCount++;
          this.AlreadyViewedAPI();
        },
        AlreadyViewedTab(){
          this.alreadyViewedCount = 1;
          this.alreadyViewedData = [];
          this.AlreadyViewedAPI();
        },
        async AlreadyViewedAPI(){
          if (this.alreadyViewedCount == 1) this.loading = true;
           await AlreadyView(this.alreadyViewedCount).
          then(r=>{this.alreadyViewed = r?.data?.data,r?.data?.data?.data ? this.alreadyViewedData.push(...r?.data?.data?.data): r?.data?.data?.data});
          if (this.alreadyViewedCount == 1) this.spinner();
        },
        async successStoryAPI(){
           await successStory().then(r=>{this.successStory = r?.data?.data});
        },
        async Martial_Status(){
          await martial_status().then(r=>{
           this.martial_status = r?.data?.data
          });
        },
        async Religion(){
          await religion().then(r=>{
             this.religion = r?.data?.data
          });
        },
        spinner(){
              var overlayLoader = document.querySelector('.spinner-border');
              if (overlayLoader)  setTimeout(() => overlayLoader.classList.add('fade-out'), 15);
              setTimeout(() =>  this.loading = false, 20);
         },
      },
      mounted() {
        AOS.init()  
        
      },
      created(){
        this.JoinedAPI();
        this.successStoryAPI();
        this.Martial_Status();
        this.Religion();
      }
    };
    </script>
    <style scoped src="@/assets/css/application.css" />
    <style scoped src="@/assets/css/components/Authenticated/tab_card.css"/>
    <style scoped src="@/assets/css/components/Authenticated/spinner.css"/>
  <style scoped >
   .select-wrap .icon {
      position : absolute;
      right : 0px;
      top : 0%;
      -webkit-transform : translateY(50%) translateX(-15px);
      -ms-transform : translateY(50%) translateX(-15px);
      transform : translateY(50%) translateX(-15px);
      transition : .3s;
    }
    .form-control{
      
        font-size : .7rem !important;
        padding : .175rem !important;
    }
    input{
        font-size : .7rem !important;
    }
  </style>
   
    